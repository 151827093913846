@import '../../style/helpers/index';

.ht-filter {
  text-align: right;
  .due {
    display: inline-block;
    vertical-align: middle;
    li {
      display: inline-block;
      font-size:20px;
      color:#abb9c5;
      font-weight: 500;
      margin-right:16px;
      cursor: pointer;
      &.is-active {
        color:#333a53;
      }
    }
  }
}

.ht-filter-wrap {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  min-width: 288px;
  height:44px;
  text-align: left;
  background: #FFFFFF;
  border: 1px solid #D7E0E7;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  &:after {
    position: absolute;
    background:url('./filter.svg') no-repeat center;
    width:24px;
    height:24px;
    right:16px;
    top:0;
    bottom:0;
    margin:auto;
    content:'';
  }
  &.on {
    border-bottom:none;
    border-radius: 4px 4px 0px 0px;
    &:after {
      position: absolute;
      background:url('./filter-up.svg') no-repeat center;
      width:24px;
      height:24px;
      right:16px;
      top:0;
      bottom:0;
      margin:auto;
      content:'';
    }
    span {
      color:#abb9c5;
    }
    ul {
      display: block;
    }
  }
  span {
    display: inline-block;
    line-height: 44px;
    margin-left:16px;
    font-size:16px;
    color:#536476;
  }
  ul {
    display: none;
    background:white;
    border:1px solid #d7e0e7;
    border-radius: 0px 0px 4px 4px;
    width:calc(100% + 2px);
    margin:-1px -0 0px -1px;
    border-top: none;
    li {
      padding:10px 16px;
      color:#333a53;
      &:hover {
        color:#55a3ff;
        font-weight: 500;
      }
    }
  }
}