@import "../../../style/helpers/index";

$name_dot: 1.7vw / 2;

.queturepoint-layout {
  @include animation(1.2, 2, layout);
  overflow: hidden;
  .page-content-title {
    position: relative;
    background: rgba($red, 0.1);
    color: $red;
    margin: 0 auto;
    @include vw-convert(margin-top, 165px);
    &:after {
      right: 116px;
      bottom: 0;
      position: absolute;
      background: url("./깨처포인트깨다.png") no-repeat center;
      @include vw-convert(width, 221px);
      @include vw-convert(height, 137px);
      background-size: contain;
      content: "";
    }
    .speech-bubble {
      position: absolute;
      @include vw-convert(padding, 0px 16px);
      @include vw-convert(min-width, 124px);
      @include vw-convert(height, 42px);
      @include vw-convert(line-height, 42px);
      @include vw-convert(font-size, 20px);
      @include vw-convert(top, -93px);
      @include vw-convert(right, 146px);
      color: white;
      background: #ef6b69;
      border-radius: 10vw;
      font-weight: 400;
      box-sizing: border-box;
      span {
        // &:before, &:after {
        position: absolute;
        right: 0;
        left: 0;
        @include vw-convert(bottom, -20px);
        width: 0;
        height: 0;
        margin: auto;
        border-style: solid;
        @include vw-convert(border-width, 10px 6px);
        border-color: #ef6b69 transparent transparent transparent;
        // content:'';
        // }
      }
    }
  }
  .wrap {
    @include animation(0.5, 3, text);
  }
}

.quetureview-layout {
  @include animation(1.5, 0, dom);
  &.is-full {
    .playerWrap {
      width: 95vw;
    }
  }
  .playerWrap {
    overflow: hidden;
    // width: 100vw;
    &__wrap {
      width: 100%;
      height: 100%;
    }
    &:before {
      position: absolute;
      @include vw-convert(top, -16px);
      right: 0;
      left: 0;
      @include vw-convert(height, 33px);
      @include vw-convert(border-radius, 16px 16px 0px 0px);
      background: white;
      content: none;
    }
    &:after {
      content: "";
    }
    &.play {
      .Amediaplayer-wrap {
        &:before,
        &:after {
          content: none;
        }
      }
    }
  }
  .play-state-wrap {
    + .controls {
      // visibility: hidden;
    }
  }
}

.quetureranking-layout {
  .big-title {
    @include vw-convert(font-size, 64px);
    span {
      display: inline-block;
      vertical-align: middle;
      background: $red;
      color: white;
      @include vw-convert(border-radius, 50px);
      @include vw-convert(font-size, 32px);
      @include vw-convert(min-width, 140px);
      @include vw-convert(margin-right, 14px);
      @include vw-convert(line-height, 64px);
      font-weight: 400;
    }
  }
  .ranking {
    @include vw-convert(margin-top, 70px);
    &.is-team {
      .img-wrap {
        .img-cover-twoteam {
          display: inline-block;
          position: relative;
          &:first-child {
            z-index: 6;
            &:after {
              position: absolute;
              right: 0;
              @include vw-convert(width, 26px);
              @include vw-convert(height, 26px);
              background: url("./teamreader.svg") no-repeat center;
              background-size: contain;
              z-index: 1;
              content: "";
            }
          }
          &:nth-child(1) {
            z-index: 5;
          }
          &:nth-child(2) {
            z-index: 4;
          }
          &:nth-child(3) {
            z-index: 3;
          }
          &:nth-child(4) {
            z-index: 2;
          }
          &:nth-child(5) {
            z-index: 1;
          }
        }
        .img-cover {
          display: inline-block;
          position: relative;
          &:first-child {
            z-index: 6;
            // &:after {
            //   position: absolute;
            //   top: -10px;
            //   left: -24px;
            //   @include vw-convert(width, 26px);
            //   @include vw-convert(height, 26px);
            //   background: url("./teamreader.svg") no-repeat center;
            //   background-size: contain;
            //   z-index: 1;
            //   content: "";
            // }
          }
          &:nth-child(1) {
            z-index: 5;
          }
          &:nth-child(2) {
            z-index: 4;
          }
          &:nth-child(3) {
            z-index: 3;
          }
          &:nth-child(4) {
            z-index: 2;
          }
          &:nth-child(5) {
            z-index: 1;
          }
        }
      }
    }
  }
}
.rankinglist {
  width: 100%;
  .card-wrap {
    width: 100%;
    .result-title {
      display: none;
    }
    .img-wrap,
    .name-wrap {
      display: inline-block;
    }
  }
  .team {
  }
  .img-wrap-threeteam {
    flex-grow: 1;
  }
  .img-wrap {
    font-family: "HGSoftGGothicssi";
    text-align: start;
    .img-cover:not(:last-child) {
      span {
        &::after {
          position: absolute;
          top: $name_dot;
          right: 0px;

          width: 0.35vw;
          height: 0.35vw;
          border-radius: 50%;
          margin: 0px 16px;
          background-color: #7f92a3;
          content: "";
        }
      }
    }
  }
  .img-cover {
    span:first-child {
      padding-right: 38px;
    }
  }
}
