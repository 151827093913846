#bubbleAnimation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background: url(./img/attendance-bg.svg) no-repeat center;
  background-attachment: fixed;
  background-size: auto 100%;
  overflow: hidden;
  background-color: #000;

  .img-title {
    width: 850px;
    height: 164px;
    background: url(./img/attendance-title.svg) no-repeat center;
    background-size: 100%;
    position: absolute;
    top: 80px;
  }

  .character-anim-wrap {
    position: absolute;
    height: 600px;
    width: 1600px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    bottom: 184px;
    .character-anim {
      &.queda {
        width: 450px;
        height: 600px;
      }
      &.other {
        width: 680px;
        height: 400px;
      }
    }
  }
}