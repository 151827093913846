.c-notepad-new {
  position: fixed;
  top: 40px;
  right: 1024px;
  // left: 40px;
  background: rgba(85, 163, 255, 1);
  border-radius: 20px;
}

.c-notepad.is-open .c-notepad-canvas {
  position: absolute;
  background: transparent;
}

.c-notepad-canvas {
  display: none;

  &.is-erase {
    &:hover {
      cursor: url('./img/eraserPoint_icon@2x.ico') 0 20, auto;
    }
  }

  &.is-blackpen {
    &:hover {
      cursor: url('./img/penBlackPoint.svg') -300 50, auto;
    }
  }

  &.is-redpen {
    &:hover {
      cursor: url('./img/penRedPoint.svg') -300 50, auto;
    }
  }

  &.is-yellowpen {
    &:hover {
      cursor: url('./img/penYellowPoint.svg') -300 50, auto;
    }

    ~.c-notepad-new .yellow-color svg {
      display: block;
    }
  }

  &.is-bluepen {
    &:hover {
      cursor: url('./img/penBluePoint.svg') -300 50, auto;
    }

    ~.c-notepad-new .blue-color svg {
      display: block;
    }
  }
}

.c-notepad-new ul .palette-wrap {
  display: block;
}

.c-notepad-new ul .palette {
  &.yellow-color {
    border: 2px solid rgba(255, 255, 255, 0.5);
    background: #FFA820;
  }

  &.blue-color {
    border: 2px solid rgba(255, 255, 255, 0.5);
    background: #55A3FF;
  }
}
