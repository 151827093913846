@import "../../style/helpers/index";

.ht-main-layout-wrap {
  padding-top: 128px;
  padding-bottom: 45px;
  min-height:100vh;
}

.ht-main-layout {
  background: #f7f9fb;

  .class-panel {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding: 36px 50px 30px 50px;
    border-radius: 16px;
    color: white;
    background: #333a53 url("./img/class-panel-bg.svg") no-repeat 100% center;
    background-size: cover;

    &:after {
      position: absolute;
      right: 127px;
      bottom: 0;
      width: 227px;
      height: 156px;
      background: url("./img/class-panel-off.svg") no-repeat center;
      content: "";
    }

    &.is-complete {
      &:after {
        position: absolute;
        right: 127px;
        bottom: 0;
        width: 206px;
        height: 178px;
        background: url("./img/class-panel-on.svg") no-repeat center;
        content: "";
      }
    }

    &__task {
      @include font;
      font-size: 24px;

      span {
        display: inline-block;
        margin-left: 12px;
        vertical-align: middle;
        font-size: 20px;
        color: #ffd542;
      }
    }

    &__status {
      margin-top: 10px;

      strong {
        display: block;
        font-size: 32px;
        @include font;
        font-weight: 500;
      }

      span {
        display: inline-block;
        height: 50px;
        min-width: 248px;
        margin-top: 18px;
        padding: 0 0 0 24px;
        background: white;
        border-radius: 10vw;
        color: #333a53;
        font-size: 20px;
        line-height: 50px;
        vertical-align: middle;
        font-weight: 500;
        cursor: pointer;

        &:after {
          display: inline-block;
          width: 29px;
          height: 29px;
          margin: 0 0 4px 6px;
          background: url("./img/black-right-arrow.svg") no-repeat center;
          vertical-align: middle;
          content: "";
        }
      }
    }

    &__list {
      margin-top: 26px;
      margin-bottom: -10px;
      max-width: 600px;

      li {
        position: relative;
        display: inline-block;
        margin-right: 30px;
        margin-bottom: 10px;
        padding: 0 40px 4px 0;
        font-size: 20px;
        border-bottom: 1px solid white;
        font-weight: 300;
        @include font;

        &.is-complete {
          &:before {
            background: url("./img/do.svg") no-repeat center;
          }
        }

        &:before {
          position: absolute;
          right: -5px;
          bottom: 3px;
          background: url("./img/do-not.svg") no-repeat center;
          width: 34px;
          height: 34px;
          content: "";
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .class-info {
    width: 1200px;
    margin: 24px auto 0 auto;
    padding: 32px 40px 42px 32px;
    background: white;
    border-radius: 16px;

    &__profile {
      display: inline-block;
      width: 263px;
      margin-right: 11px;
      vertical-align: top;

      &-name {
        margin-top: 5px;
        padding-bottom: 30px;
        border-bottom: 1px solid #d7e0e7;
        font-size: 24px;
        font-weight: 500;

        span {
          display: block;
          margin-top: -8px;
        }
      }

      .imgwrap {
        text-align: center;
      }

      .img {
        position: relative;
        width: 202px;
        height: 202px;
        border: 2px solid #d7e0e7;
        border-radius: 4px;
        margin: 0 auto;
        overflow: hidden;

        img {
          @include absolute-full(img);
          max-width: initial;
          height: 100%;
        }
      }

      .mini-calendar {
        padding-top: 30px;
        text-align: center;

        .title {
          position: relative;
          font-size: 20px;
          color: #7f92a3;
          text-align: center;

          .date {
            font-weight: 500;
          }
        }

        .prev,
        .next {
          position: absolute;
          display: block;
          bottom: 0;
          top: 0;
          width: 32px;
          height: 32px;
          margin: auto;
          cursor: pointer;
        }

        .prev {
          left: 0;
          background: url("./img/mini-calendar-prev.svg") no-repeat center;
        }

        .next {
          right: 0;
          background: url("./img/mini-calendar-prev.svg") no-repeat center;
          transform: rotate(180deg);
        }

        table {
          width: 100%;
          margin-top: 5px;
          font-size: 16px;

          th,
          td {
            vertical-align: middle;
            font-weight: 500;

            &:first-child {
              color: red;
            }
          }

          tr {
            cursor: pointer;
          }

          th {
            height: 56px;
          }

          td {
            height: 34px;
            font-weight: 400;
          }

          .prev-date {
            &:first-child {
              span {
                color: #ef6b69;
              }
            }

            span {
              color: #abb9c5;
            }
          }

          .is-weekly {
            span {
              display: inline-block;
              width: 100%;
              background: #e7eaf0;
            }

            td {

              &:first-child {
                span {
                  border-radius: 40px 0 0 40px;
                }
              }

              &:last-child {
                span {
                  border-radius: 0 40px 40px 0;
                }
              }
            }
          }

          .is-today {
            color: #55a3ff;
          }
        }
      }
    }

    &__calendar {
      display: inline-block;
      vertical-align: top;
      width: 853px;

      .title {
        position: relative;
        min-height:60px;
        padding-bottom: 10px;
        border-bottom: 1px solid #d7e0e7;
        font-weight: 500;

        strong {
          display: inline-block;
          vertical-align: middle;
          font-size: 32px;
          color: #333a53;
          @include font;
        }

        span {
          display: inline-block;
          margin-left: 6px;
          vertical-align: middle;
          font-size: 20px;
          color: #7f92a3;
        }

        .calendar-control {
          position: absolute;
          right: 0;
          font-size: 0;
          bottom: 25px;

          li {
            display: inline-block;
            vertical-align: middle;
            margin-left: 8px;
            cursor: pointer;
          }

          .prev,
          .next {
            width: 24px;
            height: 24px;
          }

          .prev {
            background: url("./img/calendar-prev.svg") no-repeat center;
            background-size: cover;
          }

          .today {
            min-width: 58px;
            border: 1px solid #abb9c5;
            border-radius: 2px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            color: #abb9c5;
            line-height: 23px;
            height: 24px;
            box-sizing: border-box;
          }

          .next {
            background: url("./img/calendar-next.svg") no-repeat center;
            background-size: cover;
          }
        }
      }

      .calendar {
        position: relative;
        height: 560px;
      }

      .table-line {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 10px;

        li {
          display: inline-block;
          width: calc(100%/ 7);
          height: 100%;
          border-right: 1px solid #d7e0e7;

          &:last-child {
            border: none;
          }
        }
      }

      .table-calendar {
        position: relative;
        width: 100%;
        text-align: center;
        z-index: 1;

        p {
          font-size: 16px;
          font-weight: 300;

          span {
            font-weight: 500;
          }
        }

        .time {
          margin: -3px 0 5px;
          font-size: 10px;
        }

        th,
        td {
          width: calc(100% / 7);
        }

        th {
          height: 104px;
          color: #abb9c5;
          vertical-align: middle;

          &:first-child {
            color: #f49796;
          }

          strong {
            display: block;
          }

          &.is-today {
            color: $navy;
          }

          strong {
            font-size: 32px;
          }

          span {
            display: block;
            font-weight: 400;
            font-size: 16px;
          }
        }

        td {
          vertical-align: top;

          .wrap {
            position: relative;
            padding: 12px 0;
            border-radius: 4px;

            &:hover {
              .ht-tooltip {
                display: table;
              }
            }

            .ht-tooltip {
              display: none;
              position: absolute;
              width: 264px;
              // height: 301px;
              background: white;
              border-radius: 4px;
              border: 1px solid #d7e0e7;
              // overflow: hidden;
              z-index: 1;

              &:before {
                @include absolute-full;
                border-radius: 4px;
                box-sizing: border-box;
                z-index: -1;
                content: "";
              }

              &__title {
                margin-bottom: 8px;

                strong {
                  display: inline-block;
                  margin-right: 3px;
                  font-size: 16px;
                  vertical-align: middle;
                }

                font-size: 10px;
              }

              .ht-tooltip-wrap {
                // position: absolute;
                display: table-cell;
                width: 257px;
                min-height:300px;
                text-align: left;
                // height: 100%;
                padding: 12px 12px 12px 14px;
                background: white;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                z-index: 1;
                right: 0;
              }

              .group-title {
                font-size: 16px;
                color: #333a53;
                font-weight: 500;

                &.mission {
                  margin-bottom: 7px;
                  padding-top: 16px;
                  border-top: 1px solid #d7e0e7;
                }

                span {
                  display: block;
                  font-size: 14px;
                  color: #abb9c5;
                }
              }

              .quest {
                margin-bottom: 15px;
                color: #536476;

                li {
                  display: inline-block;
                  margin-right: 11px;

                  &:after {
                    display: inline-block;
                    vertical-align: bottom;
                    margin-left: 2px;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background: url("./img/do-not.svg") no-repeat center;
                    content: "";
                  }

                  &.is-complete {
                    &:after {
                      background: url("./img/do.svg") no-repeat center;
                    }
                  }
                }
              }
            }
          }

          &.is-orange,
          &.is-green,
          &.is-red {
            padding: 2px 5px 2px 2px;
            color: white;
            cursor: context-menu;
          }

          &.is-orange {
            .wrap {
              background: #ffa820;
            }

            .ht-tooltip {
              &__title {
                color: #ffa820;
              }

              &:before {
                background: #ffa820;
              }
            }
          }

          &.is-green {
            .wrap {
              background: #1cc4cc;
            }

            .ht-tooltip {
              &__title {
                color: #1cc4cc;
              }

              &:before {
                background: #1cc4cc;
              }
            }
          }

          &.is-red {
            .wrap {
              background: #ef6b69;
            }

            .ht-tooltip {
              &__title {
                color: #ef6b69;
              }

              &:before {
                background: #ef6b69;
              }
            }
          }
        }
      }

      .day-title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;

        li {
          display: inline-block;
          width: calc(100% / 7);
        }
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(10%, 1fr));
        grid-auto-rows: minmax(70px, auto);
        grid-auto-flow: dense;
        grid-gap: 4px 10px;
        padding-top: 104px;
        text-align: center;
        align-items: center;

        .grid-item {
          padding: 16px 0;

          &.is-yellow {
            background: #ffa820;
          }

          &.is-yellow {
            background: #1cc4cc;
          }

          &.is-red {
            background: #ef6b69;
          }

          @mixin For($num) {
            @for $i from 1 through 99 {
              @for $j from 1 through 99 {
                &.span-#{$i}\-#{$j} {
                  grid-column-end: span #{$i};
                  grid-row-end: span #{$j};

                  &:before {}
                }
              }
            }
          }

          @include For(99);

          strong {
            display: block;
          }
        }
      }
    }
  }
}