@import '~antd/dist/antd.css';

@import './style/core/index';
@import './style/fonts/fonts.css';
@import './style/components/index';

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 12px 10px;
    overflow-wrap: break-word;
}

.ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 10px 16px;
    border-right: 1px solid #e8e8e8;
}

.ant-list-item {
    padding: 5px 0;
}
*/

