.coloring-answer-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;
  .coloring-image-row {
    width: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  .coloring-result-text {
    margin-top: 24px;
    border-radius: 16px;
    font-size: 20px;
    line-height: 30px;
    padding: 8px 24px;
    font-weight: 500;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: -8px;
      right: -8px;
    }
    &.is-true {
      color: #1CC4CC;
      background-color: rgba(28, 196, 204, 0.2);;
      &::after {
        background-image: url("./icon-mark-o.svg");
      }
    }
    &.is-false {
      color: #EF6B69;
      background-color: rgba(239, 107, 105, 0.2);;
      &::after {
        background-image: url("./icon-mark-x.svg");
      }
    }
  }
}

.coloring-image {
  position: relative;
  cursor: pointer;
  user-select: none;
  &.is-hover,
  &:hover {
    opacity: 0.5;
  }
}
