.play-state-wrap {
  display: none;
}

@media (min-width: 768px) {

  .play-state-wrap {
    position: absolute;
    display: block;
    overflow: hidden;
    width:128px;
    height:128px;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    border-radius: 50%;
    color: #fff;
    visibility: hidden;

    &.show {
      visibility: visible;
    }
  }

  .play-btn {
    background: rgba(0, 0, 0, .5);
    border-radius: 26px;
    pointer-events: none;
    width: 128px;
    height: 128px;
    background: url('./images/ico-play.png') no-repeat center;
    background-size: 128px 128px;

    &.pause {
      background: url('./images/ico-pause.svg') no-repeat center;
      background-size: 128px 128px;
    }
  }


  .fade-out {
    animation: fade-out 0.5s ease-in;
    animation-fill-mode: forwards;
    opacity: 1;
  }

  @keyframes fade-out {
    100% {
      transform: scale(1.4);
      opacity: 0;
    }
  }
}