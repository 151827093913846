.user-layout {
  background:#f7f9fb url('./img/user-bg-pattern-5.svg') no-repeat;
  padding-bottom:0px;
  background-attachment: fixed;
  background-size:1497px 872px;
  background-position: center 90px;
  min-height:99.9vh;
}

.tchr {

}
.not-student {
  visibility: hidden;
}

.ant-calendar-picker {
  min-width:260px;
  input {
    pointer-events: none;
  }
}