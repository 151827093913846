@import "../../../style/helpers/index";
.playerWrap {
  // position: relative;
  // width: 100%;
  // max-width: 1040px;
  // height: auto;
  // padding: 0;
  // outline: none;

  // .end-ment {
  //   display: none;
  // }
  &.is-end {
    &:hover {
      .controls {
        display: block;
      }
    }
    .end {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      color: white;
      text-align: center;
      @include vw-convert-mo() {
        // width: 100%;
        // display: none;
      }
      &:after {
        position: absolute;
        right:-1000%;
        left:-1000%;
        top:-1000%;
        bottom:-1000%;
        background: rgba(0, 0,0,0.7);
        z-index: -1;
        content:'';
      }
      strong {
        @include vw-convert(font-size, 24px);
        font-weight: 400;
        @include vw-convert(font-size, 10px, mo);
      }
      p {
        @include vw-convert(font-size, 16px);
        @include vw-convert(margin-top, 4px);
        cursor: auto;
        @include vw-convert-mo() {
          font-weight: 300;
        }
        span {
          font-weight: 500;
        }
      }
      .img {
        display: block;
        @include vw-convert(width, 86px);
        @include vw-convert(height, 86px);
        margin: 0 auto;
        @include vw-convert(margin-top, 24px);
        @include vw-convert(margin-bottom, 8px);
        cursor: pointer;
        @include vw-convert-mo() {
          @include vw-convert(width, 30px, mo);
          @include vw-convert(height, 30px, mo);
          @include vw-convert(background-size, 30px 30px, mo);
          // background: none;
        }
      }
      .end-ment {
        .img {
          background: url(../images/replay_thumnail_icon.svg) no-repeat;
          @include vw-convert(background-size, 86px 86px);
          @include vw-convert(background-size, 30px 30px, mo);
        }
      }
      .full-view {
        @include vw-convert(margin-left, 40px);
        @include vw-convert(margin-left, 20px, mo);
        .img {
          background: url(../images/full_thumnail_icon.svg) no-repeat;
          @include vw-convert(background-size, 86px 86px);
          @include vw-convert(background-size, 30px 30px, mo);
        }
      }
      .repeat, .full {
        @include vw-convert(font-size, 20px);
        @include vw-convert(font-size, 7px, mo);
        @include vw-convert(margin-top, 12px, mo);
        white-space:nowrap;
        cursor: pointer;
        @include vw-convert-mo() {
          display: inline-block;
        }
      }
    }
    .player-dim {
      cursor: auto !important;
      &:after {
        z-index: -1 !important;
        background: none !important;
      }

      &.is-video {
        &:before {
          opacity: 0.2;
        }
        &:after {
          @include absolute-full();
          // position: absolute;
          // background: url("../../../style/components/imgs/play_thumnail.svg") no-repeat center;
          @include vw-convert(width, 98px);
          @include vw-convert(height, 98px);
          @include vw-convert(background-size, 98px 98px);
          @include vw-convert-mo() {
            @include vw-convert(width, 35px, mo);
            @include vw-convert(height, 35px, mo);
            // background-size:100% 100%;
            @include vw-convert(background-size, 35px 35px, mo);
            // background: url("../../../style/components/imgs/m-play_thumnail_icon.svg") no-repeat center;
          }
          cursor: pointer;
          z-index: 10;
          content: "";
        }
      }
    }
    .controls {
      .controls-bar-wrap {
        .btn-control {
          display: none;
        }
      }
    }
  }
  video {
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    vertical-align: bottom;
    position: relative;
  }

  .player-dim {
    cursor: pointer;
    &:before {
      background: #2d2d2d;
      position: absolute;
      // display: none;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
    }
    &.on {
      &:before {
        opacity: 0.2;
        display: block;
      }
      &:after{
        @include vw-convert-mo(){
          // display: none;
        }
        @include absolute-full();
        // background: url('../../../style/components/imgs/video-stop_icon.svg') no-repeat center;
        content:'';
      }
    }
    &.is-video {
      &:before {
        opacity: 0.7;
      }
      &:after {
        @include absolute-full();
        position: absolute;
        background: url("../images/play_thumnail_icon.svg") no-repeat center;
        @include vw-convert(width, 98px);
        @include vw-convert(height, 98px);
        @include vw-convert(background-size, 98px 98px);
        @include vw-convert-mo() {
          @include vw-convert(width, 35px, mo);
          @include vw-convert(height, 35px, mo);
          // background-size:100% 100%;
          @include vw-convert(background-size, 35px 35px, mo);
          // background: url("../../../style/components/imgs/m-play_thumnail_icon.svg") no-repeat center;
        }
        cursor: pointer;
        z-index: 10;
        content: "";
      }
    }
  }

  .controls {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    @include vw-convert(bottom, 4px, mo);
    &.on {
      display: block;
      @include vw-convert-mo(){
        // z-index fix
        z-index: 10;
      }
    }
    &:after{
      @include absolute-full();
      background:rgba(black, 0.2);
      z-index: -11;
      content:'';
    }
    // .play {
    //   display: inline-block;
    //   width: 26px;
    //   height: 26px;
    //   border: 2px solid #fff;
    //   border-radius: 50%;
    //   box-sizing: border-box;
    //   background: url(../images/btn_play.png) no-repeat;
    //   background-size: 100%;
    // }

    .pause {
      display: inline-block;
      width: 26px;
      height: 26px;
      border: 2px solid #fff;
      border-radius: 50%;
      box-sizing: border-box;
      background: url(../images/btn_pause.png) no-repeat;
      background-size: 100%;
    }

    .slider {
      display: inline-block;
      width: 60%;
      vertical-align: middle;

      .bar {
        width: 100%;
        background-color: #696969;
        border-radius: 30px;
        height: 10px;
        position: relative;
        z-index: 0;

        .progress {
          position: absolute;
          background-color: #ff6200;
          width: 0%;
          height: 100%;
          border-radius: 3px;
          z-index: 1;
        }

        .handle {
          left: 0%;
          width: 1em;
          height: 1em;
          border-radius: 50%;
          border: 1px solid #c5c5c5;
          background: #f6f6f6;
          color: #454545;
          position: absolute;
          z-index: 2;
          cursor: pointer;
        }
      }
    }
  }
}

.bmpui-ui-uicontainer {
  display: none;
}

// video::-webkit-media-controls-enclosure {
//   display: none !important;
// }
