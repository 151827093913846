@import '../../../style/helpers/index';

@keyframes rotate {
  0% { 
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mvp-layout {
  position: relative;
  display: table;
  width:100%;
  height:100vh;
  overflow: hidden;
  &:after {
    @include absolute-full;
    @include vw-convert(width, 1242px);
    @include vw-convert(height, 1243px);
    background:url('./img/mvp-bg.svg') no-repeat center;
    @include vw-convert(background-size, 1242px 1243px);
    z-index: 0;
    animation: rotate 15s infinite linear;
    content:'';
  }
  .attendancelist {
    background: #FFFFFF;
    box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.1);
    &:after {
      position: absolute;
      @include vw-convert(width, 42px);
      @include vw-convert(height, 50px);
      background:url('./img/mvp-mark.svg') no-repeat center;
      background-size: contain;
      top:0;
      @include vw-convert(right, 7px);
      content:'';
    }
    &.effect {
      @include animation(0.8, 0, cardscale) {
        animation-timing-function: ease-in-out;
      }
    }
    &__name {
      font-weight: 600;
      @include vw-convert(margin-top, 30px);
      color:$navy!important;
      @include vw-convert(font-size, 48px);
    }
    &__time {
      display: none;
    }
  }
}
.mvp-wrap {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  text-align: center;
  .title {
    @include vw-convert(width, 748px);
    @include vw-convert(height, 166px);
    margin:0 auto;
    font-weight: 500;
    text-align: center;
    @include vw-convert(font-size, 64px);
    color:white;
    background:url('./img/mvp-title.svg') no-repeat center;
    @include vw-convert(background-size, 748px 166px);
    @include font();
    span {
      display: inline-block;
      @include vw-convert(font-size, 70px);
      @include vw-convert(margin-top, 7px);
    }
  }
  .mvp-content {
    position: relative;
    z-index: 1;
  }
  .leaf {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: bottom;
    @include vw-convert(width, 150px);
    @include vw-convert(height, 180px);
    &.left-leaf {
      transform: translate(30%, 30%);
    }
    &.right-leaf {
      transform: scaleX(-1) translate(30%, 30%);
    }
  }
  .attendancelist-wrap {
    display: inline-block;
    width:auto;
    @include vw-convert(max-width, 890px);
    .list-leaf {
      display: inline-block;
      position: relative;
      vertical-align: bottom;
      @include vw-convert(width, 150px);
      @include vw-convert(height, 185px);
      &.left {
        .right-leaf {
          display: none;
        }
      }
      &.right {
        .left-leaf {
          display: none;
        }
      }
    }
  }
}