@import '../../../style/helpers/index';

.challengequiz-layout {
  @include animation(1.5, 0, dom);
  &.is-full {
    .playerWrap {
      width:95vw;
    }
  }
  .playerWrap {
    overflow: hidden;
    // width: 100vw;
    &__wrap {
      width:100%;
      height:100%;
    }
    &:before {
      position: absolute;
      @include vw-convert(top, -16px);
      right:0;
      left:0;
      @include vw-convert(height, 33px);
      @include vw-convert(border-radius, 16px 16px 0px 0px);
      background:white;
      content:none;
    }
    &:after {
      content:'';
    }
    &.play {
      .Amediaplayer-wrap {
        &:before, &:after {
          content:none;
        }
      }
    }
  }
  .play-state-wrap {
    + .controls {
      // visibility: hidden;
    }
  }

}

.challengequiz-wrap {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  text-align: center;

  .title {
    @include vw-convert(width, 748px);
    @include vw-convert(height, 166px);
    margin: 0 auto;
    font-weight: 500;
    text-align: center;
    @include vw-convert(font-size, 64px);
    color: white;
    @include vw-convert(background-size, 748px 166px);
    @include font();

    span {
      display: inline-block;
      @include vw-convert(font-size, 70px);
      @include vw-convert(margin-top, 7px);
    }
  }
}