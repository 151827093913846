@import '../../../../style/helpers/index';

$blue-color: #4599FF;
$right-blue-color: #55A3FF;
$red-color: #FF6262;
$orange-color: #FFA820;

.text-color-red { color: $red-color };
.text-color-blue { color: $blue-color };
.text-color-right-blue { color: $right-blue-color };
.text-color-orange { color: $orange-color };
.text-color-grey{ color: grey };

.no-page-break-within {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}


// ASSUME published report to be viewed in mobile only

.published-report {
  width: 420px;
  margin: 0 auto;
  padding: 20px 10px;
  color: #333a53;
}

.published-report-inner {
  width: 400px;
  margin: 0px 0px;
  padding: 0px 0px;
  color: #333a53;
  overflow: hidden;
}

.published-report-title {
  margin-top: 20px;
  text-align: center;
  .title {
    font-size: 32px;
    line-height: 32px;
    @include font;
    font-weight: 600;
  }
  .title_large {
    font-size: 40px;
    line-height: 32px;
    @include font;
    font-weight: 600;
  }
  .info {
    margin-top: 13px;
    font-size: 18px;
    color: $blue-color;
    font-weight: 500;
  }
}

.published-report-subtitle {
  margin-top: 20px;
  margin-bottom: 4px;
  //padding-bottom: 7px;
  //border-bottom: 2px solid #333A53;
  font-size: 18px;
  @include font;
  font-weight: 600;
  &.score {
    page-break-before: always;
  }
}

.published-report-edit-button {
  &.ant-btn {
    //padding: 2px 15px 0px 15px;
    height: 30px;
    margin: 0px 4px;
    vertical-align: bottom;
  }
}

.published-report-chac {
  margin: 0 auto;
}

.content-title {
  font-weight: 600;
  font-size: 14px;
  padding-right: -10px;
}

.published-report-sublayout {
  position: relative;
  background: white;
  border-radius: 8px;
  padding: 14px;
  box-sizing: border-box;
  .content-title {
    span {
      display: block;
      font-size: 12px;
      color: #536476;
      font-weight:400;
      letter-spacing: -0.02em;
    }
  }
}

.strong-text {
  position: absolute;
  right:16px;
  top:0;
  bottom:0;
  @include vertical-center;
  > span {
    display: inline-block;
    vertical-align: middle;
  }
  strong {
    font-size: 24px;
    letter-spacing: -0.03em;
  }
}

.published-report-layout {
  background: #F2F5FB;
  padding: 16px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  &.profile {
    //margin-top: 35px;
    //text-align: right;
    text-align: left;
    //padding: 12px 8px 12px 8px;
    padding: 10px 6px 10px 6px;
    .imgarea {
      position: absolute;
      width: 218px;
      text-align: center;
      .imgwrap {
        position: relative;
        width: 80px;
        height: 80px;
        margin:0 auto;
        background: #ABB9C5;
        border: 6px solid #FFFFFF;
        border-radius: 50%;
        overflow: hidden;
        box-sizing: content-box;
        img {
          @include absolute-full(img);
        }
      }
      .name {
        margin-top: 10px;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: -0.03em;
      }
    }

    .published-report-sublayout-wrap {
      //width: 528px;
      width: 800px;
      display: inline-block;

      .published-report_class, .published-report_queture, .published-report_absent, .published-report_mvp {
        display: inline-block;
        text-align: left;
        vertical-align: top;
        padding: 0px 0px 0px 12px;
        margin: 0px 6px;
        line-height: 36px;
        .strong-text {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .published-report_class {
        width: 165px;
      }
      .published-report_queture {
        width: 200px;
      }
      .published-report_absent {
        width: 165px;
        white-space: nowrap;
      }
      .published-report_mvp {
        width: 200px;
        .strong-text {
          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: -25px;
            margin: auto;
            width: 17px;
            height: 19px;
            background:url('./img/crown.png') no-repeat center;
            content: '';
          }
        }
      }
      .class {
        width: 200px;
        float:left;
        .strong-text {
          top: 14px;
          bottom: auto;
        }
        .date {
          margin-top: 12px;
          padding-bottom: 5px;
          font-size: 14px;
          color: #959aae;
          border-bottom: 1px solid #d9dfec;
        }
        .count {
          margin-top: 10px;
          padding-bottom: 0px;
        }
        ul {
          margin-top: 12px;
          li {
            position: relative;
            span {
              position: absolute;
              right:0;
            }
          }
        }
      }
      .queture {
        margin-top: 8px;
      }
    }
  }
  // NOT USED
  &.chac {
    display: inline-block;
    width: 129px;
    height: 116px;
    margin-right: 8px;
    padding: 16px 5px;
    text-align: center;
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;
    p {
      margin-top: 6px;
      font-weight: 600;
      letter-spacing: -0.03em;
    }
  }
  &.result-info {
    display: inline-block;
    //width: calc(100% - 137px);
    width: 100%;
    //height: 55px;
    padding: 10px 12px;
    box-sizing: border-box;
    vertical-align: middle;
    .published-report-sublayout {
      display: inline-block;
      height: 38px;
      margin-right: 12px;
      vertical-align: middle;
      padding: 0px 12px;
      @include vertical-center;

      // first line
      &:nth-child(1) {
        width: 182px;
        margin-right: 12px;
      }
      &:nth-child(2) {
        width: 182px;
        margin-right: 0px;
      }
      &:nth-child(3) {
        width: 376px;
        margin-top: 10px;
      }
    }
    .content-title {
      display: inline-block;
      vertical-align: middle;
    }
    .strong-text {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &.chart {
    //padding: 79px 32px 60px 60px;
    padding: 70px 30px 45px 55px;
  }
  &.textarea {
    padding: 10px 10px 20px 10px;
    textarea {
      //height: 168px;
      //height: auto;
      width:100%;
      background:none;
      border:none;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.03em;
      overflow-y: hidden;
      resize: none;
      &.is-edit {
        background: #fff;
      }
      &.is-consult {
        background: #fff2e8;
      }
      &.is-consult-edit {
        background: #fff;
      }
    }
  }
}

table.published_curri {
  width: 100%;
  margin-top: 15px;
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
  &.break-page {
  }
  th {
    background: #d9dfec;
    text-align: center;
    line-height: 36px;
  }
  .striped-rows-bright {
    background: #f2f5fb;
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }
  .striped-rows-dark {
    background: #e6e8f2;
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }

  td {
    //padding: 16px 8px;
    //height: 71px;
    padding: 8px 8px;
    height: 32px;
    border: 4px solid white;
    vertical-align: top;
    box-sizing: border-box;
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
    span {
      display:block;
      font-size: 12px;
      color: #959aae;
      font-weight: 400;
    }
  }
  .count {
    width: 105px;
    //text-align: center;
    text-align: left;
    vertical-align: middle;
    font-size: 15px;
    font-weight: 500;

    .count_mvp {
      display: block;
      text-align: center;
      vertical-align: middle;
      font-size: 15px;
      font-weight: 500;
      background:url('./img/crown.png') no-repeat left;
    }
    .count_normal {
      display: block;
      text-align: center; 
      vertical-align: middle;
      font-size: 15px;
      font-weight: 500;
    }
    .count_date {
      display: inline;
      font-size: 15px;
      font-weight: 400;
    }
    .count_absent {
      display: inline;
      font-size: 15px;
      font-weight: 500;
      color: #FF6262;
    }
    .count_late {
      display: inline;
      font-size: 15px;
      font-weight: 400;
      color: #FF6262;
    }
  }
  .queture {
    width: 250px;
    font-size: 14px;
    font-weight: 500;
  }
  .activity {
    width: 100%;
    font-size: 13px;
    font-weight: 300;
  }
  .queture_correct_rate {
    width: 35px;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 500;
  }
  .queture_homework_queture {
    width: 35px;
    color: #444444;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 500;
  }
}
