@import '../../../style/helpers/index';

.classlogin-layout-wrap {
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  width:528px;
  height:622px;
  background: #FFFFFF;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  // padding:50px 64px 64px 64px;
  // @media (min-width: 768+'px') and (max-width: (1500 - 1+'px')) {
  //   overflow-y: auto;
  // }
}

.classlogin-inner-wrap {
  padding:50px 64px 64px 64px;
  overflow-y: auto;
  height:100%;
  // @media (min-width: 768+'px') and (max-width: (1500 - 1+'px')) {
  //   height:150%;
  // }
}

.classlogin-layout {
  display: table-cell;
  width:100%;
  height:100%;
  vertical-align: middle;
  // @include vw-convert(min-height, 1080px);
  // @include vw-convert(height, 1080px);
  text-align: center;
  background:#f7f9fb url('./login-bg.svg') no-repeat center;
  overflow-y: scroll;
  &:before {
    display: inline-block;
    vertical-align: middle;
    height:100%;
    content:'';
  }
  .logo {
    img {
      display: block;
      @include margin-auto;
      width: 120px;
      height: 50px;
      margin-bottom: 42px;
    }
  }

  .tab {
    position: relative;
    width: 400px;
    margin: 0 auto;
    font-size: 24px;

    .tab-nav {
      display: inline-block;
      text-align: center;
      width: 50%;
      color: #7f92a3;
      border-bottom:3px solid #d7e0e7;
      font-weight: 500;
      cursor: pointer;
      span {
        display: block;
        margin-bottom:10px;
      }
      &.is-active {
        color: #333a53;
        border-color:#333a53;
      }
    }
  }

  .inputarea {
    margin-top: 35px;
    margin-bottom: 60px;
  }

  .inputwrap {
    width: 400px;
    &.pw {
      margin-top: 40px;
    }
    input {
      padding:18px 70px;
    }
  }

  .find-account {
    font-size: 20px;
    text-align: center;
    color: #7f92a3;

    li {
      display: inline-block;
      position: relative;

      &:first-child {
        margin-right: 80px;

        &:after {
          position: absolute;
          top: 0;
          right: -40px;
          bottom: 0;
          margin: auto;
          width: 2px;
          height: 22px;
          background: #7F92A3;
          content: ''
        }
      }
    }
  }

  .btnwrap {
    margin-top: 24px;
    position: absolute;
    right:0;
    left:0;
    bottom:64px;
    margin:auto;
  }

  .classmode-btn {
    width: 360px;
    height: 62px;
  }
}

.classlogin-layout {
  &.findaccount {
    .classlogin-layout-wrap {
      text-align: left;
      position: relative;
      padding-top:60px;
    }
    .inputarea{
      text-align: left;
      input {
        padding:10px 10px 10px 0px;
      }
    }
    .inputwrap {
      margin-top:22px;
      &:first-child{
        margin-top:0;
      }
    }
    .btnwrap {
      position: absolute;
      right:0;
      left:0;
      bottom:64px;
      margin:auto;
    }
    .classmode-btn {
      width:400px;
    }
  }
  .title {
    position: relative;
    display: inline-block;
    @include font();
    color:#55a3ff;
    font-size:34px;
    font-weight:600;
    text-align: left;
    &:after {
      position: absolute;
      right:-10px;
      top:0;
      width:8px;
      height:8px;
      border-radius: 50%;
      background:#55a3ff;
      content:'';
    }
  }
  .step {
    text-align: left;
    margin-top:40px;
    margin-left:-30px;
    li {
      display: inline-block;
      @include auto-count(3);
      color:#abb9c5;
      font-size: 24px;
      margin-left:30px;
      span {
        display: inline-block;
        vertical-align: middle;
      }
      &:before {
        margin-right:5px;
        width:24px;
        height:24px;
        // line-height:24px;
        @include line-height-center(24px);
        color:white;
        font-size: 16px;
        background:#abb9c5;
        border-radius: 50%;
      }
      &.is-step {
        color:#333a53;
        &:before {
          background: #333a53;
        }
      }
    }
  }
  .result {
    margin-top:40px;
    background:#e7eaf0;
    border-radius: 4px;
    padding:16px 24px;
    font-size:20px;
    color:#333a53;
    span {
      font-weight: 600;
    }
  }
  .find-pw {
    margin-top:12px;
    font-size:20px;
    a {
      float:right;
      color:#55a3ff;
      font-weight:600;
    }
  }
}