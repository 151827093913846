#storytellingAnimation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background: url(./img/bg-storytelling.svg) no-repeat center;
  background-attachment: fixed;
  background-size: auto 100%;
  overflow: hidden;
  background-color: #000;

  .board-wrap {
    width: 760px;
    .text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 56px;
      color: #fff;
      width: 500px;
      text-align: center;
      // font-weight: 700;
      max-width: 700px;
      font-family: 'Jua';
    }
  }
  .img-title {
    width: 800px;
    height: 164px;
    background: url(./img/title-story.svg) no-repeat center;
    background-size: 100%;
    position: absolute;
    top: 80px;
    &.is-queture {
      background-image: url(./img/title-quture.svg);
      width: 600px;
    }
  }
  .stt-charactor-anim-wrap {
    position: absolute;
    bottom: 80px;
    width: 1440px;
    height: 400px;
    .stt-speech-anim {
      width: 300px;
      position: absolute;
      bottom: 340px;
      left: -50px;
    }
  }

}