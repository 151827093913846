#gameAnimation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background: url(./img/game-bg.svg) no-repeat center;
  background-attachment: fixed;
  background-size: auto 100%;
  overflow: hidden;
  background-color: #000;

  .img-title {
    width: 600px;
    height: 164px;
    background: url(./img/game-title.svg) no-repeat center;
    background-size: 100%;
    position: absolute;
    top: 80px;
  }
  .charactor-anim-wrap {
    height: 100%;
    width: 1000px;
    height: 1080px;
    position: relative;
    .speech-anim {
      width: 430px;
      position: absolute;
      left: -200px;
      top: 200px;
    }
  }
  .character-anim {
    width: 100%;
    height: 100%;
  }
}
