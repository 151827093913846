#pirctureQuizAnimation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background: url(./img/bg-pictureQuiz.svg) no-repeat center;
  background-attachment: fixed;
  background-size: auto 100%;
  overflow: hidden;
  background-color: #000;

  .img-title {
    width: 700px;
    height: 164px;
    background: url(./img/title-pictureQuiz.svg) no-repeat center;
    background-size: 100%;
    position: absolute;
    top: 80px;
  }
  .charactor-anim-wrap {
    position: absolute;
    bottom: 54px;
    width: 1600px;
    height: 560px;
    .speech-anim {
      // width: 380px;
      // height: 280px;
      width: 300px;
      position: absolute;
      bottom: 480px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
