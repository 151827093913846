@import '../../../style/helpers/index';

.psend-layout, .classpause-layout {
  .page-content-ment {
    @include vw-convert(font-size, 32px);
    color:$navy;
    font-weight: 600;
  }
}

.classpause-layout{
  .big-title {
    animation: none;
  }
}
$yellow: #ffd300;
$blue: #17d3ff;
$pink: #ff4e91;

$duration: 3000;

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));

  @return $randomNum;
}

.confetti {
  @include absolute-full;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:100%;
  overflow: hidden;
}

.confetti-piece {
  position: absolute;
  width: 8px;
  height: 16px;
  background: $yellow;
  top: 0;
  opacity: 0;

  @for $i from 1 through 999 {
    &:nth-child(#{$i}) {
      left: $i * 7%;
      transform: rotate(#{randomNum(-80, 80)}deg);
      animation: makeItRain $duration * 1ms infinite ease-out;
      animation-delay: #{randomNum(0, $duration * .5)}ms;
      animation-duration: #{randomNum($duration * .7, $duration * 1.2)}ms
    }
  }

  &:nth-child(odd) {
    background: $blue;
  }

  &:nth-child(even) {
    z-index: 1;
  }

  &:nth-child(4n) {
    width: 5px;
    height: 12px;
    animation-duration: $duration * 2ms;
  }

  &:nth-child(3n) {
    width: 3px;
    height: 10px;
    animation-duration: $duration * 2.5ms;
    animation-delay: $duration * 1ms;
  }

  &:nth-child(4n-7) {
    background: $pink;
  }
}

@keyframes makeItRain {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    transform: translateY(800px);
  }
}

.queturediv-layout {
  .big-title {
    @include vw-convert(font-size, 80px);
    @include font;
  }
}

.classend-layout {
  position: relative;
  width:100%;
  height:100%;
  overflow: hidden;

  .classend-title {
    display: table;
    width:100%;
    height:100%;
  }
  .classend-title-wrap {
    display: table-cell;
    vertical-align: middle;
    .page-content-ment {
      @include margin-auto;
    }
  }
}