@import '../../../style/helpers/index';

.maketeam-layout {
  .team-type {
    @include vw-convert(margin-top, 120px);
    &:hover .card:not(:hover) {
      strong {
        color:$gray70;
      }
      p {
        color:$gray50;
      }
      &.solo {
        .img {
          background:url('./img/not-solo.svg') no-repeat center;
          @include vw-convert(background-size, 193px 222px);
        }
      }
      &.team {
        .img {
          background:url('./img/not-team.svg') no-repeat center;
          @include vw-convert(background-size, 396px 220px);
        }
      }
    }
    .card {
      position: relative;
      display: inline-block;
      @include vw-convert(min-width, 400px);
      @include vw-convert(height, 461px);
      background:white;
      box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.1);
      @include vw-convert(border-radius, 16px);
      border:8px solid #fff;
      box-sizing: border-box;
      @include vw-convert(margin-right, 44px);
      text-align: center;
      @include font;
      vertical-align: top;
      cursor: pointer;
      &:hover {
        border-color:$red;
        box-shadow: none;
        strong {
          color:$red;
        }
      }
      &.solo:hover{
        .img {
          @include vw-convert(width, 199px);
          @include vw-convert(height, 221px);
          background:url('./img/team-over1.svg') no-repeat center;
          @include vw-convert(background-size, 199px 221px);
          @include vw-convert(bottom, 41px);
        }
      }
      &.team:hover {
        .img {
          @include vw-convert(width, 396px);
          @include vw-convert(height, 221px);
          background:url('./img/team-over2.svg') no-repeat center;
          @include vw-convert(background-size, 396px 221px);
        }
      }
      &:last-child {
        margin-right:0px;
      }
      strong {
        display: inline-block;
        @include vw-convert(margin-top, 60px);
        @include vw-convert(font-size, 48px);
        color:$navy;
      }
      p {
        @include vw-convert(font-size, 24px);
        color:$gray70;
      }
      .img {
        position: absolute;
        right:0;
        left:0;
        @include vw-convert(bottom, 40px);
        margin:auto;
      }
    }
    .solo {
      .img {
        @include vw-convert(width, 193px);
        @include vw-convert(height, 221px);
        background:url('./img/team1.svg') no-repeat center;
        @include vw-convert(background-size, 193px 221px);
      }
    }
    .team {
      .img {
        @include vw-convert(width, 396px);
        @include vw-convert(height, 220px);
        background:url('./img/team2.svg') no-repeat center;
        @include vw-convert(background-size, 396px 220px);
        left:auto;
      }
    }
  }
}

.decisionpair-layout {
  &:before {
    @include absolute-full;
    @include vw-convert(width, 1242px);
    @include vw-convert(height, 1243px);
    // background: url('components/img/mvp-bg.svg') no-repeat center;
    background:url('../../../pages/classMode/mvp/img/mvp-bg.svg') no-repeat center;
    @include vw-convert(background-size, 1242px 1243px);
    animation: rotate 15s infinite linear;
    z-index: 1;
    content: '';
  }
  &:after {
    @include absolute-full;
    background:rgba(#222943, 0.5);
    z-index: 0;
    content:'';
  }
  .big-title {
    position: relative;
    color:white;
    z-index: 1;
  }
  .attendancelist-wrap {
    position: relative;
    z-index: 1;
  }
}