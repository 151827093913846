@import "../../style/helpers/index";
$false: red;
$true: green;

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.toast-notification {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 120;
}

.toast-notice-wrapper.notice-enter {
  opacity: 0.01;
  transform: scale(0);
}

.toast-notice-wrapper.notice-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms ease-out;
}

.toast-notice-wrapper.notice-exit {
  opacity: 1;
  transform: translateY(0);
}

.toast-notice-wrapper.notice-exit-active {
  opacity: 0.01;
  transform: translateY(-40%);
  transition: all 300ms ease-out;
}

.toast-notice {
  $popup: &;
  // display: flex;
  position: relative;
  margin: 0 auto;
  @include vw-convert(min-width, 360px);
  @include vw-convert(margin-top, 8px);
  @include vw-convert(margin-bottom, 8px);
  @include vw-convert(padding, 9px 60px 9px 7px);
  @include vw-convert(border-radius, 6px);
  @include vw-convert(border-radius, 5px, mo);
  @include vw-convert(font-size, 20px);
  @include vw-convert(font-size, 8px, mo);
  @include vw-convert(width, 150px, mo);
  @include vw-convert(padding, 7.5px 5px 7.5px 9px, mo);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, .1);
  box-sizing: border-box;
  color:#333a53;
  background: #FFFFFF;

  // align-items: center;
  &.admin-alert {
    background: #ffedef;
    color: $false;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    & #{$popup}__img {
      @include vw-convert(width, 28px);
      @include vw-convert(height, 28px);
      background: url('./img/admin-alert_icon.svg') no-repeat center;
      @include vw-convert(background-size, 28px 28px);
      @include vw-convert(background-size, 13px 13px, mo);
      @include vw-convert(margin-right, 9px, mo);
    }

    .close {
      background: url('./img/admin-close.svg') no-repeat center;
      @include vw-convert(width, 26px);
      @include vw-convert(height, 26px);
      @include vw-convert(background-size, 26px 26px);
    }
  }

  &.admin-true {
    background: #e7f9f2;
    color: #1cc787;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    & #{$popup}__img {
      @include vw-convert(width, 28px);
      @include vw-convert(height, 28px);
      background: url('./img/admin-true_icon.svg') no-repeat center;
      @include vw-convert(background-size, 28px 28px);
      @include vw-convert(background-size, 13px 13px, mo);
      @include vw-convert(margin-right, 9px, mo);
    }

    .close {
      background: url('./img/admin-close.svg') no-repeat center;
      @include vw-convert(width, 26px);
      @include vw-convert(height, 26px);
      @include vw-convert(background-size, 26px 26px);
    }
  }

  &.admin-info {
    background: #fff5e7;
    color: #ffa820;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    & #{$popup}__img {
      @include vw-convert(width, 28px);
      @include vw-convert(height, 28px);
      background: url('./img/admin-warning_icon@2x.png') no-repeat center;
      @include vw-convert(background-size, 28px 28px);
      @include vw-convert(background-size, 13px 13px, mo);
      @include vw-convert(margin-right, 9px, mo);
    }

    .close {
      background: url('./img/admin-close.svg') no-repeat center;
      @include vw-convert(width, 26px);
      @include vw-convert(height, 26px);
      @include vw-convert(background-size, 26px 26px);
    }
  }

  >svg {
    font-size: 18px;
  }

  &__img {
    display: inline-block;
    vertical-align: middle;
    @include vw-convert(width, 50px);
    @include vw-convert(height, 50px);
    @include vw-convert(margin-right, 16px);
  }

  &__content {
    display: inline-block;
    vertical-align: middle;
    @include vw-convert(font-size, 8px, mo);
    @include vw-convert(max-width, 112px, mo);
  }

  &.error {
    background: white;
    border:4px solid #ef6b69;
    & #{$popup}__img {
      background: url('./img/warning_icon.png') no-repeat center;
      @include vw-convert(background-size, 32px 32px);
    }
  }

  &.confirm {
    border:4px solid #1CC4CC;
    & #{$popup}__img {
      background: url('./img/toastcheck_icon.svg') no-repeat center;
      background-size: contain;
      // @include vw-convert(background-size, 26px 26px);
      // @include vw-convert(background-size, 13px 13px, mo);
      // @include vw-convert(margin-right, 9px, mo);
    }
  }

  &.info {
    border:4px solid #55A3FF;

    & #{$popup}__img {
      background: url('./img/toastinfo_icon.png') no-repeat center;
      background-size:contain;
      // @include vw-convert(background-size, 13px 13px, mo);
      // @include vw-convert(margin-right, 9px, mo);
    }
  }
  &.warning {
    border: 4px solid #FFA842;
    & #{$popup}__img {
      background: url('./img/toastwarning_icon.svg') no-repeat center;
      background-size:contain;
      // @include vw-convert(background-size, 13px 13px, mo);
      // @include vw-convert(margin-right, 9px, mo);
    }
  }
}

.close {
  position: absolute;
  top: 0;
  bottom: 0;
  @include vw-convert(right, 16px);
  @include vw-convert(width, 26px);
  margin: auto;
  @include vw-convert(height, 26px);
  background: url('./toastclose_icon.svg') no-repeat center;
  @include vw-convert(background-size, 26px 26px);
}

.toast-notice.info {
  >svg {
    color: #1890FF;
  }
}

.toast-notice.success {
  >svg {
    color: #52C41A;
  }
}

.toast-notice.warning {
  >svg {
    color: #FAAD14;
  }
}

.toast-notice.error {
  >svg {
    color: #F74A53;
  }

}

.toast-notice.loading {
  >svg {
    color: #1890FF;
    animation: rotating 100s linear infinite;
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
