.bubble-wrap {
  position: absolute;
  z-index: 999;
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .bubble-anim {
    position: absolute;
    left: 0;
    top: 0;
    width: 180px;
    height: 180px;
    z-index: 0;
  }
  .name {
    width: 180px;
    height: 180px;
    text-align: center;
    line-height: 180px;
    z-index: 1;
    font-family: "Jua";
  }
}
