@import '../../../style/helpers/_index';

.ht-header {
  // position: relative;
  position: fixed;
  height:88px;
  top:0;
  margin:0 auto;
  @include vertical-center;
  background: #F7F9FB;
  text-align: center;
  width:100%;
  min-width:1200px;
  z-index: 2;
  @include custom-media (769, 1200) {
    min-width: initial;
  }
  &.is-fixed-top {
    position: fixed;
    top:0;
    left:0;
    width:100%;
    box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.1);
  }
  .logo {
    display: block;
    width:95px;
    height:40px;
  }
  &-wrap {
    position: relative;
    display: inline-block;
    min-width:1200px;
    vertical-align: middle;
    text-align: center;
    @include clearfix;
    @include custom-media (769, 1200) {
      min-width: initial;
      // max-width: 1200px;
    }
    > a{
      float:left
    }
  }
  ul {
    display: inline-block;
    vertical-align: middle;
    @include custom-media (769, 1200) {
      white-space: pre;
    }
    li {
      display: inline-block;
    }
    &.nav {
      // margin-left:531px;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      li {
        position: relative;
        margin-left:64px;
        font-size:20px;
        @include font;
        color:#333a63;
        font-weight: 500;
        @include custom-media (769, 1200) {
          margin-left: 3%;
          font-size: 2vw;
          &:first-child {
            margin-left: 30px;
          }
        }
        a {
          &.is-active {
            color:#55a3ff;
            &:after {
              position: absolute;
              width:8px;
              height:8px;
              background:#55a3ff;
              border-radius: 50%;
              content: '';
            }
          }
        }
      }
    }
    &.login-info {
      // position: absolute;
      // right:0;
      display: inline-block;
      vertical-align: middle;
      margin-left:16px;
      background:#e7eaf0;
      @include font;
      border-radius: 10vw;
      padding:6px 24px;
      font-size: 16px;
      li {
        color:#536476;
        margin-right:24px;
        font-weight: 500;
        cursor: pointer;
        &:last-child {
          margin-right:0;
        }
      }
    }
  }
  .qp-wrap {
    display: inline-block;
    vertical-align: middle;
  }
  .qp {
    margin-left:70px;
    padding:4px 10px 4px 10px;
    height:34px;
    line-height:26px;
    color: #536476;
    border-radius: 10vw;
    background: rgba(#55a3ff, 0.2);
    font-size:16px;
    @include custom-media (769, 1200) {
      margin-left: 4vw;
    }
    cursor: pointer;
    + .qp {
      margin-left: 10px;
    }
    strong {
      display: inline-block;
      font-weight: 400;
      margin-right: 14px;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.quiz-header {
  position: fixed;
  top:0;
  width:100%;
  height:88px;
  line-height:88px;
  background:#f7f9fb;
  z-index: 2;
  @include font;
  &.is-fixed-top {
    box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.1);
  }
  .title {
    display: inline-block;
    color:#333a53;
    font-size:24px;
    vertical-align: middle;
    font-weight: 500;
    &:before {
      display: inline-block;
      vertical-align: middle;
      width:48px;
      height:48px;
      margin-right:16px;
      background:url('./game-header-title.svg') no-repeat center;
      content:'';
    }
    span {
      display: inline-block;
      margin-left:3px;
      font-size:20px;
    }
  }
  .back-btn {
    position: absolute;
    right:32px;
    top:0;
    bottom:0;
    margin: auto;;
    min-width:159px;
    background:#536476;
    border-radius: 60px;
    padding-left:24px;
    height:34px;
    line-height: 34px;
    font-size:16px;
    color:white;
    cursor: pointer;
    &:after {
      position: absolute;
      right:8px;
      top:0;
      bottom:0;
      margin:auto;
      width:32px;
      height:32px;
      background:url('./right-arrow.svg') no-repeat center;
      content:'';
    }
  }
}

.quiz-header-wrap {
  position: relative;
  max-width:1280px;
  margin:0 auto;
  padding:0px 32px 0px 40px;
}