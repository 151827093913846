@import "../../style/helpers/index";

$assetPath: "https://quebon-web-assets.s3.ap-northeast-2.amazonaws.com/style/images/";
$assetPath2: "https://quebon-web-assets.s3.ap-northeast-2.amazonaws.com/Product/";
$path:'../components/img/';

// donny start
.overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);

  >.popup {
    display: block;
    outline: none;
  }
}
// donny end

// .ReactModalPortal {
//   position: relative;
//   z-index: 200;
// }

.noDrag {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.popup {
  // display: none;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;

  &.ps-chk {
    position: absolute;
    width:auto;
    top:auto;
    @include vw-convert(left, 130px);
    // @include vw-convert(bottom, 343px);
    bottom:37%;
    bottom:auto;
    right:auto;
    background:white;
    .popup-content {
      @include vw-convert(padding, 25px 30px);
      text-align: left;
    }
    ul {
      li {
        @include vw-convert(margin-bottom, 14px);
        &:last-child {
          margin-bottom:0px;
        }
      }
      strong, span {
        display: inline-block;
        vertical-align: middle;
        @include vw-convert(margin-right, 20px);
      }
      strong {
        @include vw-convert(font-size, 20px);
        color:#333a53;
      }
      span {
        @include vw-convert(font-size, 16px);
        color:#999;
      }
      button {
        @include vw-convert(height, 34px);
        @include vw-convert(padding, 0px 10px);
        @include vw-convert(line-height, 34px);
        @include vw-convert(font-size, 16px);
        color:white;
        @include vw-convert(border-radius, 4px);
        &.ps-chk-btn {
          background:#ffa842;
        }
        &.ps-view-btn {
          background:#55a3ff;
        }
      }
    }
  }
  &-closebtn {
    position: absolute;
    @include vw-convert(width, 40px);
    @include vw-convert(height, 40px);
    border-radius: 50%;
    cursor: pointer;

    //&:after {
    //  @include absolute-full;
    //  @include vw-convert(width, 18px);
    //  @include vw-convert(height, 18px);
    //  background: url('../components/img/icon_close.png') no-repeat center;
    //  @include vw-convert(background-size, 18px 18px);
    //  content: '';
    //}
  }

  .big-title {
    animation: none;
  }

  .effect {
    animation: none;
  }

  .popup-wrap {
    text-align: center;
    height: 100%;
    overflow-y: auto;

    &:after {
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      content: '';
    }
  }

  &-content {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 110;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 8px solid $navy;
    @include vw-convert(border-width, 8px);
    border-radius: 16px;
  }
}

// 학생리스트
.popup-student-list {
  .popup-closebtn {
    @include vw-convert(top, 19px);
    @include vw-convert(right, 19px);
    background: url($assetPath + "popup-close-btn-type2.svg") no-repeat center rgba(white, 0.2);
  }

  .popup-content {
    background: white;
    @include vw-convert(width, 880px);
  }

  .title {
    @include vw-convert(padding, 17px 17px 25px 17px);
    background: $navy;
    @include vw-convert(font-size, 32px);
    @include font;
    text-align: left;
    color: white;
  }

  .list {
    position: relative;

    &:after {
      position: absolute;
      bottom: 0;
      @include vw-convert(right, 18px);
      left: 0;
      @include vw-convert(height, 82px);
      background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      content: none;
    }

    &__title {
      // display: flex;
      // justify-content: space-around;
      width:100%;
      @include vw-convert(padding, 0px 32px);
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);

      th {
        // width: calc(100% / 5);
        @include vw-convert(line-height, 54px);
        @include vw-convert(font-size, 20px);
        color: #999;
        font-weight: 500;
      }
      li {
        display: inline-block;
        @include vw-convert(line-height, 54px);
        @include vw-convert(font-size, 20px);
        color: #999;
        font-weight: 500;
      }
      .name {
        @include vw-convert(width, 200px);
      }
      .attendance, .mvp {
        @include vw-convert(width, 120px);
      }
    }

    &__content {
      @include vw-convert(height, 420px);
      @include vw-convert(padding, 33px 0px 0px 0px);
      overflow-y: auto;
      &-list {
        width:100%;
        @include vw-convert(margin-bottom, 10px);
      }
      tr {
        @include vw-convert(margin-bottom, 33px);
        &:last-child {
          @include vw-convert(margin-bottom, 80px);
        }
      }

      td {
        // display: inline-block;
        // width: calc(100% / 5);
        @include vw-convert(font-size, 24px);
        vertical-align: top;
        span {
          display: inline-block;
          @include vw-convert(width, 32px);
          @include vw-convert(height, 32px);
          @include vw-convert(border-radius, 4px);
        }
      }
      .name {
        @include vw-convert(padding-left, 25px);
        text-align: left;
        @include vw-convert(width, 200px);
        color:#333a53;
        font-weight: 500;
        text-align: center;
        span {
          @include vw-convert(font-size, 20px);
          font-weight:400;
          color:#999;
        }
      }
      .attendance, .mvp {
        @include vw-convert(width, 120px);
        text-align: center;
      }
      .attendance {
        &.is-kids {
          &:not(.is-yes) {
            span {
              display: none;
            }
          }
          button {
            display: block;
            width: 80px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            background-color: #333a53;
            color: #fff;
            border-radius: 12px;
            margin: 0 auto;
          }
        }
      }
      .attendance, .mission {
        span {
          background:url('../components/img/is-not.svg') no-repeat center;
          background-size:cover;
        }
        &.is-yes {
          span {
            background:url('../components/img/isyes.svg') no-repeat center;
            background-size:cover;
          }
        }
      }
      .home {
        span {
          background:#d7e0e7;
          color:#333a53;
        }
        &.is-not {
          span {
            background:url('../components/img/is-nothome.svg') no-repeat center;
            background-size:cover;
          }
        }
      }
      .mvp {
        .inputwrap {
          // display: inline-block;
          // @include vw-convert(width, 32px);
          // @include vw-convert(height, 32px);
          // @include vw-convert(border-radius, 4px);
          // border:2px solid #d7e0e7;
        }
        input {
          position: absolute;
          right:0;
          left:0;
          margin:auto;
          opacity: 0;
          &:checked + span {
            background:url('../components/img/mvp-star.svg') no-repeat center;
            background-size:cover;
            border:none;
          }
        }
        span {
          display: inline-block;
          @include vw-convert(width, 32px);
          @include vw-convert(height, 32px);
          @include vw-convert(border-radius, 4px);
          border:2px solid #d7e0e7;
        }
        label {
          position: absolute;
          opacity: 0;
          right:0;
          left:0;
          margin:auto;
          @include vw-convert(width, 32px);
          @include vw-convert(height, 32px);
          @include vw-convert(border-radius, 4px);
          border:2px solid #d7e0e7;
        }
      }
    }
  }

  .chk-btn {
    text-align: right;
    @include vw-convert(padding, 0px 16px 16px 0px);

    button {
      @include vw-convert(min-width, 124px);
      @include vw-convert(line-height, 42px);
      background: $navy;
      color: white;
      @include vw-convert(font-size, 24px);
      @include vw-convert(border-radius, 50px);
    }
  }
}

// 페이지 미리보기
.popup-page-preview {
  position: absolute;
  @include vw-convert(width, 496px);
  @include vw-convert(height, 326px);
  top: auto;
  @include vw-convert(bottom, 80px);
  left: auto;
  @include vw-convert(right, 400px);

  .popup-wrap {
    overflow: visible;
  }

  .popup-closebtn {
    @include vw-convert(top, 11.5px);
    @include vw-convert(right, 11.5px);
    background: rgba(#333a53, 0.7);
    border-radius: 50%;
  }

  .popup-content {
    box-sizing: border-box;
    background: url('../../components/Teacher/layout/img/basicbg.svg') no-repeat center top;
    background-size: contain;
  }

  .content {
    @include vw-convert(width, 480px);
    @include vw-convert(height, 270px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .attendance-layout {
      transform: scale(0.25);
    }
  }

  .page-number {
    background: $navy;
    color: white;
    @include vw-convert(font-size, 24px);
    @include vw-convert(padding, 12px 0px 0px 0px);

    span {
      display: inline-block;
      @include vw-convert(margin, 0px 4px);
      font-weight: 500;
    }
  }
  .queturepoint-layout, .quetureview-layout {
    transform: scale(0.3);
    animation: none;
  }
  .maketeam-layout {
    width:200%;
    transform: scale(0.28);
  }
  .gameexplain-layout {
    width:400%;
    transform:scale(0.25);
  }
  .mvp-layout {
    width:400%;
    transform: scale(0.3);
  }
  .msg-layout {
    width:400%;
    transform: scale(0.25);
    margin-bottom:0px;
  }
  .activity-layout {
    width:400%;
    transform: scale(0.3);
    .page-content-title, .page-content-ment{
      margin-right:auto;
      margin-left:auto;
    }
  }
  .queturepoint-layout .wrap {
    animation: none;
  }
}

// 강의 경로
.popup-lecture-path {
  position: absolute;
  @include vw-convert(width, 489px);
  @include vw-convert(height, 268px);
  top: auto;
  right: auto;
  @include vw-convert(left, 0px);
  @include vw-convert(bottom, 75px);
  background: white;
  @include vw-convert(border-radius, 16px);

  .popup-content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .lecture-route {
    text-align: left;
    @include vw-convert(padding, 20px 0px 20px 42px);

    li {
      position: relative;
      @include auto-count(30);
      @include vw-convert(margin-top, 38px);
      cursor: pointer;
      &:hover {
        p {
          color:#55a3ff;
        }
      }
      .queture-name {
        &:after {
          display: inline-block;
          width: 1px;
          @include vw-convert(height, 16px);
          @include vw-convert(margin, 0px 10px);
          background: rgba($navy, 0.5);
          vertical-align: baseline;
          content: '';
        }
      }

      .point {
        display: inline-block;

        &:before,
        &:after {
          display: inline-block;
          vertical-align: middle;
          @include vw-convert(width, 4px);
          @include vw-convert(height, 8px);
          @include vw-convert(margin, 0px 8px 4px 8px);
          background: url('#{$path}/lecture-route.svg') no-repeat center;
          @include vw-convert(background-size, 4px 8px);
          content: '';
        }
      }

      &.is-gone-view {
        &:before {
          background: $navy;
        }

        &:after {
          background: $navy;
        }
      }

      &.is-view {
        &:before {
          background: $blue;
        }

        .queture-name {
          font-weight: 600;
        }

        .point {
          color: $blue;
          font-weight: 600;

          &:before {
            background: url('#{$path}/lecture-route-select.svg') no-repeat center;
          }
        }
      }

      &:before {
        @include vw-convert(width, 24px);
        @include vw-convert(height, 24px);
        background: #999;
        border-radius: 50%;
        @include vw-convert(line-height, 22px);
        color: white;
        @include vw-convert(font-size, 20px);
        font-weight: 500;
        outline: 1px solid transparent;
      }

      &:after {
        position: absolute;
        top: 100%;
        @include vw-convert(left, 10px);
        @include vw-convert(width, 3px);
        @include vw-convert(height, 35px);
        background: #999;
        content: '';
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        &:after {
          content: none;
        }
      }

      p {
        display: inline-block;
        vertical-align: middle;
        @include vw-convert(margin-left, 15px);
        @include vw-convert(font-size, 20px);
        color: $navy;
        // span {
        //   display: inline-block;
        //   vertical-align: middle;
        // }
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes scaleup {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.popup-select-battletype {
  background: rgba(#222943, 0.5);

  .popup-wrap {
    &:before {
      @include absolute-full;
      @include vw-convert(width, 1242px);
      @include vw-convert(height, 1243px);
      background: url('../components/img/mvp-bg.svg') no-repeat center;
      @include vw-convert(background-size, 1242px 1243px);
      animation: rotate 15s infinite linear;
      content: '';
    }
  }

  .popup-content {
    @include vw-convert(width, 400px);
    border-color: #ef6b69;
    background: white;
    animation: scaleup 0.7s cubic-bezier(0, 0, 0.15, 1.01);
    ;
  }

  strong {
    display: inline-block;
    @include vw-convert(margin-top, 50px);
    @include vw-convert(font-size, 48px);
    color: $red;
    @include font;
  }

  p {
    @include font;
    @include vw-convert(font-size, 24px);
    color: $navy;
    font-weight: 500;
  }

  .select-solo {
    transform: scale(0.55);
    @include vw-convert(margin, -215px -50px -180px -50px);
  }

  .selected-battletype {
    &.team {
      .select-solo {
        display: none;
      }

      .select-team {
        display: block;
      }
    }

    .select-team {
      transform: scale(1.3);
    }
  }
}

.popup-team-info {
  .popup-content {
    @include vw-convert(max-width, 597px);
  }
  .team-content {
    text-align: left;
    @include vw-convert(padding, 10px 40px 24px 40px);
    @include vw-convert(height, 460px);
    overflow: auto;
    &:after {
      position: absolute;
      @include vw-convert(height, 82px);
      @include vw-convert(bottom, 0px);
      left:0;
      right:0;
      transform: rotate(-180deg);
      background:linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      content:'';
    }
  }
  .team-name {
    @include vw-convert(font-size, 24px);
    @include vw-convert(margin-top, 14px);
    color:#333a53;
    font-weight: 500;
  }
  .team-wrap {
    @include vw-convert(padding, 16px 34px 32px 34px);
    background:#f7f9fb;
    border-radius: 4px;
    font-size: 0;
    li {
      position: relative;
      display: inline-block;
      background:white;
      text-align: center;
      @include vw-convert(width, 92px);
      @include vw-convert(height, 42px);
      @include vw-convert(margin, 16px 16px 0px 0px);
      @include vw-convert(line-height, 42px);
      border-radius: 4px;
      font-weight: 500;
      @include vw-convert(font-size, 20px);
      color:#536476;
      &.is-leader {
        border:2px solid #ffd542;
        &:after{
          position: absolute;
          @include vw-convert(top, -9px);
          @include vw-convert(right, -9px);
          @include vw-convert(width, 18px);
          @include vw-convert(height, 18px);
          background:url('../components/img/leardermark.svg') no-repeat center;
          content:'';
        }
      }
      &:nth-child(4n){
        margin-right:0;
      }
    }
  }
  .chk-btn {
    display: none;
  }
}

.popup-guide {
  position: absolute;
  display: none;
  top:0;
  @include vw-convert(left, 250px);
  bottom:0;
  margin:auto;
  .popup-closebtn {
    position: absolute;
    @include vw-convert(left, 250px);
    top:0;
    bottom:0;
    margin:auto;
    &:after {
      @include vw-convert(width, 40px);
      @include vw-convert(height, 40px);
      background:url('../components/img/popupguide.svg') no-repeat center;
      background-size:contain;
    }
  }
  .popup-content {
    background:white;
    @include vw-convert(min-width, 400px);
    @include vw-convert(max-width, 600px);
    @include vw-convert(padding, 10px 70px 10px 20px);
    @include vw-convert(line-height, 29px);
    color:#333A53;
    border-color:#55a3ff;
  }
  p {
    @include vw-convert(font-size, 20px);
    text-align: left;
    b {
      color:#55A3FF;
    }
  }
}

.popup-type-confirm, .popup-type-default {
  .popup-title {
    background:#333a53;
    color:white;
    @include font;
    padding:12px 24px 20px 24px;
    font-size:32px;
  }
  .popup-closebtn {
    top:10px;
    right:10px;
    width:40px;
    height:40px;
    background:transparent;
    &:after {
      content: "";
      display: inline-b;
      width:100%;
      height:100%;
      border-radius: 50%;
      background:rgba(#fff,0.2) url('../components/img/popup-close2.svg') no-repeat center;
      background-size:40px 40px;
    }

  }
  .popup-content {
    max-width:562px;
    background:white;
    text-align: left;
  }
  .popup-content-wrap {
    font-size:16px;
  }
  p {
    padding:24px 24px 0;
  }
  .btn-wrap {
    text-align: right;
    padding:24px 24px 24px 0px;
    button{
      min-width:124px;
      line-height:42px;
      border-radius: 10vw;
      font-size:24px;

      &:last-child {
        color:white;
        background:#55a3ff;
      }
    }
  }
}

.popup-type-confirm {
  .popup-content-wrap, .btn-wrap {
    background:#333a53;
  }
  .popup-content-wrap {
    color:white;
  }
  .btn-wrap {
    button:first-child {
      color:white;
      background:none;
    }
  }
}

.newsletter {
  &.share {
    bottom: 0;

    .popup-wrap {
      padding: 0;
    }

    .popup-content {
      width: 360px;
      height: 505px;
      border-radius: 0;
      background-color: #FFFFFF;
    }

    .popup-closebtn {
      width: 40px;
      height: 40px;
      background: url($assetPath + "popup-close-btn-type2.svg") no-repeat center;
      background-size: 40px 40px;

      top :24px;
      right:20px
    }

    .title-bg {
      margin-top: 56px;
      margin-top: 113px;
    }

    .img {
      margin: 0 auto;
      width: 56px;
      height: 49px;
      background: url($assetPath + "newsletter-mail.svg") no-repeat center;
      background-size: 56px 49px;
    }

    .title-wrap {
      position: relative;
      margin-top: 32.63px;
      margin-top: 32.63px;

      &:after {
        position: absolute;
        width: 80px;
        right: 0;
        left: 0;
        margin: auto;
        bottom: -30;
        height: 1px;
        background: #FFFFFF;
        content: "";
      }

      .title {
        font-size: 24px;
        font-family: "HGSoftGGothicssi";
        font-weight: 500;
      }

      strong {
        display: inline-block;
        font-size: 16px;
        color: #7f7f7f;
        margin-top: 8px;
        font-weight: 400;
      }
    }

    .btnwrap {
      margin-top: 65px;
      text-align: center;

      button {
        position: relative;
        width: 192px;
        padding: 0;
        line-height: 39px;
        border: 1px solid #3289c9;
        border-radius: 4px;
        font-size: 16px;
        background: white;
        color: #3289c9;
        text-align: left;

        &:before {
          display: inline-block;
          vertical-align: sub;
        }

        > span {
          display: inline-block;
          margin-left: 8px;

          span {
            display: unset;
          }
        }

        &:last-child {
          margin-left: 4px;
        }

        &.kakao-share {
          &:before {
            margin-left: 20px;
            width: 22px;
            height: 20.31px;
            background: url($assetPath + "newsletter-kakao.svg") no-repeat center;
            background-size: 20px 20.31px;
            content: "";
          }
        }
      }
    }

    .linkwrap {
      margin-top: 13px;

      .linkcode {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 198px;
        line-height: 39px;
        height: 40px;
        background: #E5E5E5;
        color: #7f7f7f;
        font-size: 14px;
        text-align: left;
        overflow-x: auto;
        @include vertical-center();

        span {
          display: inline-block;
          vertical-align: middle;
          width: 1px;
          white-space: nowrap;
          margin-left: 28px;
        }

        &:after {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 8px;
          margin: auto;
          width: 14px;
          height: 14px;
          background: url($assetPath + "newsletter-link.svg") no-repeat center;
          background-size: 14px 14px;
          content: "";
        }
      }
      .linkbtn-wrap {
        display:inline-block;
        height:40px;
      }
      .linkbtn {
        display: inline-block;
        width:120px;
        margin-left: 2px;
        background: #3289c9;
        border-radius:2px ;
        color:white;
        vertical-align:top;
        fontsize:14px;
        height:100%;
        font-weight:300;
        @include vertical-center();
        span {
          display:inline-block;
          vertical-align:middle;
        }
      }
    }
    .take-btn {
      margin: 56px 0px 30px;
      min-width: 164px;
      line-height: 43px;
      background: #3289c9;
      color: white;
      font-size: 20px;
      border-radius: 8px;
      font-weight: 300;

      position: fixed;
      border-radius: 0;
      right: 0;
      left: 0;
      bottom: 0;
      opacity: 1;
      z-index: 20;
      width: 100%;
    }
  }
}
.popup-type-default {
  .btn-wrap {
    button:first-child{
      color:#536476;
      background:none;
    }
  }
}

.browserAlert {
  .popup-content {
    width: 480px;
    padding: 32px 0px 24px 0px;
    background:white;
    .popup-title {
      margin-top: 24px;
      font-size: 20px;
      color: #ff5a5a;
      font-weight:500;
    }
    .img {
      width: 80px;
      height: 92px;
      margin: 0 auto;
      background:url('../components/img/chrome_icon.png') no-repeat center;
      background-size: contain;
      &.is-safari {
        height: 93px;
        background:url('../components/img/safari_icon.png') no-repeat center;
        background-size: contain;
      }
    }
    p {
      margin-top: 14px;
      color: #474747;
    }
    a {
      display: inline-block;
      margin-top: 14px;
      font-size: 18px;
      color: #4599ff;
    }
    .btn-wrap {
      margin-top: 14px;
    }
  }
}

.confirm-answer-popup {
  .popup-content {
    width: 480px;
    padding: 32px 0px 24px 0px;
    background:white;
    .popup-closebtn {
      width: 64px;
      height: 64px;
      background: url('../components/img/icon-popup-close.svg') no-repeat center;
      background-size: 64px 64px;

      top: 0px;
      right: 0px
    }
    .popup-title {
      margin-top: 24px;
      font-size: 20px;
      color: #ff5a5a;
      font-weight:500;
    }
    .img {
      width: 68px;
      height: 80px;
      margin: 0 auto;
      background:url('../components/img/icon-warning.svg') no-repeat center;
      background-size: contain;
    }
    p {
      margin-top: 16px;
      font-size: 16px;
      color: #474747;
    }
    .btn-wrap {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      button {
        width: 124px;
        height: 42px;
        border-radius: 50px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        &.btn-cancel {
          background-color: #55A3FF;
        }
        &.btn-confirm {
          background-color: #333A53;
        }
      }
    }
  }
}

.ht-queture-popup {
  .popup-closebtn {
    @include vw-convert(width, 50px);
    @include vw-convert(height, 50px);
    @include vw-convert(right, 20px);
    @include vw-convert(top, 20px);
    background:url('../components/img/btn_close_normal.svg') no-repeat center;
    background-size: contain;
    z-index: 2;
    &:after {
      content:none;
    }
    &.is-end {
      display: none;
      // background:url('../components/img/btn_close_dim.svg') no-repeat center;
      // @include vw-convert(background-size, 60px 60px);
    }
  }
  .playerWrap {
    // @include vw-convert(height, 50px);
    &.is-end {
      overflow: hidden;
      &:hover {
        .controls {
          display: block;
          margin-bottom:-1px;
        }
      }
      .end {
        position: absolute;
        @include absolute-full(transform);
        &:after {
          position: absolute;
          right:-1000%;
          left:-1000%;
          top:-1000%;
          bottom:-1000%;
          background:rgba(0,0,0,0.7);
          z-index: -1;
          content:'';
        }
        strong {
          display: none;
        }
        .end-ment {
          @include vw-convert(margin, 0px 29px);
          .img {
            display: block;
            @include vw-convert(width, 90px);
            @include vw-convert(height, 90px);
            margin:0 auto;
            background:url('../components/img/replay_icon.svg') no-repeat center;
            @include vw-convert(background-size, 90px 90px);
          }
          $imgs :
          "&.is-false" "false-ps",
          "&.is-next" "next-queture",
          "&.is-repeat" "reply-ps",
          "&.close-btn" "close_big-icon",
          ;
          @each $classname, $imgname in $imgs {
            #{$classname} {
              .img {
                background:url('../components/img/#{$imgname}.svg') no-repeat center;
                @include vw-convert(background-size, 90px 90px);
              }
            }
          }
        }
        .repeat {
          display: block;
          color:white;
          @include vw-convert(font-size, 24px);
          @include vw-convert(margin-top, 14px);
        }
      }
      .end-ment {
        cursor: pointer;
      }
    }
    #queture-player {
      @include vw-convert(border-radius, 16px 16px 16px 16px);
    }
    .controls {
      margin-bottom:-1px;
    }
  }
}

.game-popup {
  .popup-content {
    box-shadow: none;
    border:none;
  }
  .game-title {
    display: none;
  }
  .control {
    display: none;
  }
  canvas {
    // width:100%!important;
    max-height:700px!important;
    margin:0!important;
  }
  &.ht {
    .popup-wrap {
      overflow-y: inherit;
    }
    .popup-content {
      border-radius: 20px;
      background: #55A3FF;
      @include vw-convert(min-width, 826px);
    }
    .game-content {
      position: relative;
      padding: 13px;
    }
    .game-title {
      display: block;
      @include vw-convert(margin-bottom, 10px);

      span {
        display: inline-block;
        @include vw-convert(width, 480px);
        @include vw-convert(border-radius, 24px);
        background: rgba(#000, 0.2);
        color: white;
        @include vw-convert(font-size, 32px);
        text-align: center;
        @include vw-convert(padding, 0px 60px);
        @include vw-convert(min-height, 48px);
        box-sizing: border-box;
        font-family: 'HGSoftGGothicssi';
        font-weight: 500;
      }
    }
    .control {
      display: block;
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      @include vw-convert(bottom, -30px, mo);
      @include vw-convert(top, -30px, mo);

      span {
        position: absolute;
        display: inline-block;
        @include vw-convert(width, 48px);
        @include vw-convert(height, 48px);
        cursor: pointer;
      }

      .home {
        background:url('../components/img/game-popup-home.svg') no-repeat center;
        @include vw-convert(background-size, 48px 48px);
        @include vw-convert(top, 14px);
        @include vw-convert(left, 14px);
      }

      .volume {
        background:url('../components/img/game-popup-volume.svg') no-repeat center;
        @include vw-convert(background-size, 48px 48px);
        @include vw-convert(right, 69px);
        @include vw-convert(top, 14px);

        &.is-off {
          background:url('../components/img/game-popup-volume-off.svg') no-repeat center;
          @include vw-convert(background-size, 48px 48px);
        }
      }

      .zoom {
        background:url('../components/img/game-popup-full.svg') no-repeat center;
        @include vw-convert(background-size, 48px 48px);
        @include vw-convert(right, 124px);
        @include vw-convert(top, 14px);
      }

      .close {
        background:url('../components/img/game-popup-close.svg') no-repeat center;
        @include vw-convert(background-size, 48px 48px);
        @include vw-convert(top, 14px);
        @include vw-convert(right, 14px);
        bottom: auto;
      }
    }
    .game-wrapper {
      margin-top: 10px;
      max-width: 100%;
      max-height: 100%;

      &.is-fullscreen {
        canvas {
          max-width: 100vw !important;
          max-height: 100vh !important;
        }
      }

      canvas {
        margin-left: 0 !important;
        margin-right: 0 !important;
        max-width: 100%;
        max-height: 70vh !important;
      }
    }
  }
}

.summary-view-popup {
  .title {
    font-size: 32px;
    padding-left:30px;
  }
  .popup-content {
    max-width:682px;
    background: #333A53;
  }
  .view-tab {
    border-radius: 16px 16px 0px 0px;
    background: #55A3FF;
    overflow: hidden;
    &__nav {
      display: inline-block;
      width:33.3%;
      color:white;
      font-size: 24px;
      line-height: 58px;
      height:58px;
      cursor: pointer;
      &.is-active {
        color: #55A3FF;
        background:white;
        font-weight: 500;
      }
    }
  }
  .view-wrap {
    padding: 28px 28px 22px 28px;
    background:white;
    .view-title {
      margin-bottom: 24px;
      padding-bottom:4px;
      font-size: 24px;
      text-align: left;
      font-weight:500;
      color: #333A53;
      border-bottom: 2px solid #333A53;
      &:before {
        display: inline-block;
        width:20px;
        height:20px;
        margin-right:5px;
        background:url('../components/img/pencil-black.svg') no-repeat center;
        background-size:cover;
        content:'';
      }
    }
    .tag {
      display: inline-block;
      width:196px;
      height:50px;
      line-height:50px;
      border: 1px solid #B2B2B2;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 20px;
      color:#333a53;
      margin-right:7px;
      &:last-child {
        margin-right:0;
      }
    }
    textarea {
      resize: none;
      padding: 16px;
      border-radius: 4px;
      font-size: 20px;
      width:100%;
      height:240px;
      color: #333A53;
      cursor: auto;
      &:focus {
        outline: none;
      }
    }
    .btnwrap {
      margin-top: 24px;
      text-align: right;
    }
  }
}