.counting-quiz {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;
  .counter-wrap {
    // inline-flex flex-col items-center justify-center p-2 text-5xl text-gray-700 font-semi bold bg-lightBlue-200 rounded-2xl
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    font-size: 48px;
    font-weight: 600;
    background-color: #E7EAF0;
    border-radius: 16px;
    color: #333A53;
    &.pointer-events-none {
      pointer-events: none;
    }
    &.is-false {
      color: #EF6B69;
      background-color: rgba(239, 107, 105, 0.2);;
    }
    &.is-true {
      color: #1CC4CC;
      background-color: rgba(28, 196, 204, 0.2);;
    }
    .btn-counting {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 40px;
      border-radius: 12px;
      background-color: #55A3FF;
      box-shadow: 0px 3px 0px #2D79D3;
      // animation
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      &:hover {
        background-color: #99dbff;
        box-shadow: 0 3px 0 #55c3ff;
      }
      &:active {
        transform: translateY(4px);
        box-shadow: unset;
      }
      &.is-solved {
        background-color: #D7E0E7;
        box-shadow: 0px 3px 0px #ABB9C5;
      }
      .rotate-180 {
        transform: rotate(180deg);
      }
    }
  }
}
