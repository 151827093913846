@import "../../../style/helpers/index";

@mixin vw-convert-none($value, $size, $type: "pc") {
  @if ($type== "pc") {
    #{$value}: $size;
  }

  @if ($type== "mo") {
    #{$value}: $size;
  }
}

.quizBox {
  padding: 0 10px 30px 10px;
  width: 940px;
  margin: 10px auto;
  box-sizing: border-box;
  border: 1px solid #dce4f2;
  background: #fff;
  text-align: left;
  position: relative;
}

.quizHeaderBox {
  margin: 30px 0;
  cursor: default;

  .text {
    color: #000b43;
    font-weight: bold;
    letter-spacing: -0.4px;
    line-height: 26px;
  }

  .quizType {
    color: #9199c0;
    text-decoration: none;
  }
}

.quizContent {
  border: 0;
}

.quizTopArea {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #d6deec;
  margin-bottom: 30px;
  padding-bottom: 30px;

  .textArea {
    width: 100%;
    vertical-align: top;
  }

  .text {
    color: #000b43;
    line-height: 1.6;
    vertical-align: middle;
  }
}

.imageContainer {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;

  // @include vw-convert-none(margin-bottom, 40px);
  img {
    width: auto;
    max-width: 100%;

    @include vw-convert-mo() {
      width: auto;
    }
  }

  .image {
    width: 100%;
    height: auto;
  }

  .textContainer {
    position: absolute;
    text-align: center;
    vertical-align: center;
  }
}

.answerArea {
  @include vw-convert-none(margin-top, 20px);
  text-align: center;
  padding: 16px 10px 24px 10px;
  background: #eef0f5;
  border-radius: 8px;
}

.quizBotMenu {
  padding: 10px;
  margin-top: 20px;
  text-align: right;
}

.droppable {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 3px;
  @include vw-convert-none(line-height, 16px);
  @include vw-convert-none(height, 35px);
  @include vw-convert-none(line-height, 35px);
  @include vw-convert-none(min-width, 84px);
  @include vw-convert-none(border-radius, 16px);
  background: #e7eaf0;
  box-shadow: inset 0px 6px 0px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 0 9px;
  // @include vw-convert-none(border-radius, 20px);

  @include vw-convert-mo() {
    @include vw-convert-none(line-height, 13px, mo);
    @include vw-convert-none(margin-bottom, 4px, mo);
    @include vw-convert-none(height, 13px, mo);
    @include vw-convert-none(min-width, 30px, mo);
    @include vw-convert-none(border-radius, 8px, mo);
  }

  &:before {
    position: absolute;
    @include vw-convert-none(width, 28px);
    @include vw-convert-none(height, 28px);
    @include vw-convert-none(top, -14px);
    @include vw-convert-none(left, -14px);
    content: "";

    @include vw-convert-mo() {
      content: none;
    }
  }

  &:after {
    @include absolute-full;
    display: block;
    // background: url('../../../style/components/imgs/box-fill_icon@2x.svg') no-repeat center;
    @include vw-convert-none(width, 10px);
    @include vw-convert-none(height, 16px);
    @include vw-convert-none(background-size, 10px 16px);
    // content: '';
  }

  &.able {
    border: 3px dashed $chk;

    &:before {
      background: red;
      // background: url('../../../style/components/imgs/bluebox-fill_icon.svg') no-repeat center;
      @include vw-convert-none(width, 32px);
      @include vw-convert-none(height, 32px);
      @include vw-convert-none(background-size, 32px 32px);
    }
  }

  &.fill {
    background: $chk;
    box-shadow: 0px 4px 0px #397dce;

    &:after {
      content: none;
    }

    .text {
      color: white;
      white-space: nowrap;
    }
  }

  // &.is-chk{}
  &.is-true {
    position: relative;
    background: $true;
    box-shadow: 0px 4px 0px #048aa8;

    &:before {
      background: url("../images/right_icon.svg") no-repeat center;
      @include vw-convert-none(background-size, 28px 28px);
      border: 1px solid white;
      border-radius: 50%;
    }

    .text {
      color: white;
    }
  }

  &.is-false {
    position: relative;
    background: $false;
    box-shadow: 0px 4px 0px #bf413f;

    &:before {
      background: url("../images/close_icon.svg") no-repeat center;
      border: 1px solid white;
      border-radius: 50%;
      @include vw-convert-none(background-size, 28px 28px);
    }

    .text {
      color: white;
    }
  }

  &.is-reallytrue {
    position: relative;
    background: $true;
    border-color: $true;
    box-shadow: 0px 4px 0px #048aa8;

    .text {
      @include vw-convert-none(line-height, 36px);
    }

    &:before {
      background: url("../images/right_icon.svg") no-repeat center;
      @include vw-convert-none(background-size, 28px 28px);
      border: 1px solid white;
      border-radius: 50%;
    }

    &:after {
      background: none;
      content: none;
    }

    .text {
      color: white;
    }
  }

  .text {
    color: #fff;
  }
}

.draggable {
  display: inline-block;
  text-align: center;
  @include vw-convert-none(min-width, 117px);
  @include vw-convert-none(height, 48px);
  @include vw-convert-none(line-height, 48px);
  @include vw-convert-none(font-size, 20px);
  box-sizing: border-box;
  color: white;
  background: #abb9c5;
  vertical-align: middle;
  border-radius: 10vw;
  @include vw-convert-none(margin-right, 32px);
  @include vw-convert-none(padding, 0px 10px);
  overflow: hidden;
  cursor: move;

  @include vw-convert-mo() {
    @include vw-convert-none(min-width, 45px, mo);
    @include vw-convert-none(height, 20px, mo);
    @include vw-convert-none(line-height, 20px, mo);
    @include vw-convert-none(font-size, 8px, mo);
    @include vw-convert-none(margin-right, 5.5px, mo);
    @include vw-convert-none(margin-bottom, 6px, mo);
    @include vw-convert-none(padding, 0px 6px, mo);
  }

  &:hover {
    color: white;
  }

  &:active {
    color: white;
    transform: none;
    // box-shadow: 0 6px 0 0 #7f7f7f !important;
  }

  &:before {
    display: inline-block;
    @include vw-convert-none(margin, 0px 13px 0px 0px);
    vertical-align: middle;
    // background: url('../../../style/components/imgs/drag_icon.svg') no-repeat center;
    @include vw-convert-none(width, 18px);
    @include vw-convert-none(height, 18px);
    @include vw-convert-none(background-size, 18px 18px);
    // content: '';

    @include vw-convert-mo() {
      @include vw-convert-none(width, 8px, mo);
      @include vw-convert-none(height, 7px, mo);
      @include vw-convert-none(margin, 0px 4px 0px 6px, mo);
      @include vw-convert-none(background-size, 7px 7px, mo);
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &.cover {
    box-shadow: 0 6px 0 0 #d3d3d3;

    &:after {
      @include absolute-full();
      background: rgba(255, 255, 255, 0.5);
      content: "";
    }
  }

  &.btn-unchk {
    box-shadow: 0px 6px 0px 0px #536476;
  }
}

.pick {
  background: $chk;
  @include btn-click(6px, #0053b9);
}

.multiChoiceAnswer {
  list-style: none;
  margin-top: 5px;
  cursor: pointer;

  &.inline {
    display: inline-block;
    margin-right: 8px;

    .item {
      width: unset;
      display: inline-block;
    }
  }

  .item {
    color: #000b43;
    letter-spacing: -0.4px;
    padding: 5px 20px;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid #b6c6e6;
    border-radius: 20px;
    word-break: break-all;
    text-decoration: none;
    cursor: pointer;

    .num {
      margin-right: 5px;
      font-weight: 600;
    }

    .answer {
    }

    &.on {
      color: #fff;
      background: #ff7f00;
    }

    &.over {
      border: 1px solid #ff7f00;
    }
  }
}

.trueFalseAnswer {
  display: inline-block;
  margin-right: 10px;
  border-radius: 50px;
  border: 1px solid #b6c6e6;
  text-align: center;
  vertical-align: middle;
  width: 200px;
  height: 50px;
  line-height: 50px;
  font-size: 100%;
  cursor: pointer;

  &.over {
    border: 1px solid #ff7f00;
  }

  &.on {
    background: #ff7f00;
    color: #fff;
    border: 1px solid #ff7f00;
  }

  .text {
    text-decoration: none;
    cursor: pointer;
  }
}

.blank {
  display: inline-block;
}

.orderable-container {
  display: inline-block;
  width: 300px;
  height: 250px;
  border: 1px solid;
  vertical-align: bottom;
}

.orderable {
  display: inline-block;
  border-radius: 5px;
  margin-right: 5px;
  width: 180px;
  cursor: move;
  vertical-align: bottom;
  text-align: center;
  background: #ffc046;

  &.s0 {
    height: 60px;
    line-height: 60px;
  }

  &.s1 {
    height: 80px;
    line-height: 80px;
  }

  &.s2 {
    height: 100px;
    line-height: 100px;
  }

  &.s3 {
    height: 120px;
    line-height: 120px;
  }

  .answer {
    vertical-align: middle;
    line-height: 20px;
    height: auto;
    display: inline-block;
  }
}

.pairingArea {
  vertical-align: top;
}

.pairingDataArea {
  vertical-align: top;
  display: inline-block;
  border: 1px solid;
  margin: 10px;
}

.pairingData {
  border: 1px solid;
  padding: 10px;
  margin: 10px;
  min-height: 100px;
  width: 200px;
  text-align: center;

  .image {
    text-align: center;
    width: 150px;
    display: block;
  }

  .answer {
    vertical-align: center;
  }
}

.pairingLinesArea {
  display: inline-block;
  position: relative;
  padding: 0px;
}

.selectableImageContainer {
  position: relative;
  width: 100%;
  height: auto;
}

.selectableImageBack {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.imageSelectArea {
  border: 2px dashed;
  border: none;
  position: absolute;
  cursor: pointer;
}

.selectableText {
  cursor: pointer;
  margin-bottom: 3px;

  &.on {
    background: #b6c6e6;
  }

  .text {
    text-decoration: none;

    &.over {
    }
  }
}

.statusArea {
  .status {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    float: left;

    &.o {
      background: #0000ff;
    }

    &.x {
      background: #ff0000;
    }
  }
}

.resultToast {
  width: 200px;
  height: 205px;
  position: fixed;
  z-index: 500;
  right: 0;
  left: 0;
  background-size: 200px 205px;

  &-wrap {
    width: 100%;
    height: 100%;
    padding-top: 26px;
    border-radius: 50%;
    border: 8px solid white;
    overflow: hidden;
  }

  @include vw-convert-mo() {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }

  &.show-from-top {
    visibility: visible;
    -webkit-animation: fadeintop 0.5s, fadeouttop 0.5s 2.5s;
    animation: fadeintop 0.5s, fadeouttop 0.5s 2.5s;
  }

  &.show-from-bottom {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
    background: white;
    border-radius: 50%;
    text-align: center;
    @include font;
    overflow: hidden;

    p {
      font-size: 32px;
      font-weight: 600;
    }

    span {
      font-size: 20px;
      color: #536476;
      font-weight: 500;
    }
  }

  &.correct {
    @include vw-convert-none(width, 300px);
    @include vw-convert-none(height, 300px);
    // bottom:calc(50% - 130px);
    bottom: 0;
    top: 0;
    margin: auto;
    // background: url(../images/img-toast-correct1.png) no-repeat center;
    // @include vw-convert-none(background-size, 274px 275px);
    border: 24px solid #1cc4cc;

    p {
      color: #1cc4cc;
    }

    &.en {
      background: url(../images/img-toast-correct-en.png) no-repeat center;
      @include vw-convert-none(background-size, 274px 275px);
    }

    @include vw-convert-mo() {
      @include vw-convert-none(width, 122px, mo);
      @include vw-convert-none(height, 122px, mo);
      @include vw-convert-none(background-size, 122px 122px, mo);
    }

    .correct {
      width: 155px;
      height: 219px;
      margin: 7px auto 0 auto;
    }
  }

  &.fail {
    @include vw-convert-none(width, 300px);
    @include vw-convert-none(height, 300px);
    bottom: 0;
    top: 0;
    margin: auto;
    // background: url(../images/img-toast-fail.png) no-repeat center;
    // @include vw-convert-none(background-size, 274px 275px);
    border: 24px solid #ef6b69;

    p {
      color: #ef6b69;
    }

    &.en {
      background: url(../images/img-toast-fail-en.png) no-repeat center;
      @include vw-convert-none(background-size, 274px 275px);
    }

    @include vw-convert-mo() {
      @include vw-convert-none(width, 122px, mo);
      @include vw-convert-none(height, 122px, mo);
      @include vw-convert-none(background-size, 122px 122px, mo);
    }

    .fail {
      width: 196px;
      height: 199px;
      margin: -10px auto 0;
    }
  }

  &.combo {
    @include vw-convert-none(width, 274px);
    @include vw-convert-none(height, 275px);
    bottom: 0;
    top: 0;
    margin: auto;
    background: url(../images/combo_img@2x.png) no-repeat center;
    @include vw-convert-none(background-size, 274px 275px);

    @include vw-convert-mo() {
      @include vw-convert-none(width, 122px, mo);
      @include vw-convert-none(height, 122px, mo);
      @include vw-convert-none(background-size, 122px 122px, mo);
    }
  }

  // @-webkit-keyframes fadeintop {
  //     from {top: 0; opacity: 0;}
  //     to {top: 0;bottom:0;
  //       opacity: 1;}
  // }
  @keyframes fadeintop {
    from {
      top: 0;
      opacity: 0;
    }

    // to {top: calc(50% - 100px); opacity: 1;}
    to {
      top: 0;
      bottom: 0;
      opacity: 1;
    }
  }

  // @-webkit-keyframes fadeouttop {
  //     from {top: calc(50% - 100px); opacity: 1;}
  //     to {top: 0; opacity: 0;}
  // }
  @keyframes fadeouttop {
    from {
      top: calc(50% - 100px);
      opacity: 1;
    }

    to {
      top: 0;
      opacity: 0;
    }
  }

  // @-webkit-keyframes fadein {
  //     from {bottom: 0; opacity: 0;}
  //     to {bottom: 50%; opacity: 1;}
  // }
  @keyframes fadein {
    from {
      bottom: -100%;
      top: auto;
      opacity: 0;
    }

    to {
      bottom: 0;
      top: 0;
      opacity: 1;
    }
  }

  // @-webkit-keyframes fadeout {
  //     from {bottom: 50%; opacity: 1;}
  //     to {bottom: 0; opacity: 0;}
  // }
  @keyframes fadeout {
    from {
      bottom: 0;
      top: 0;
      opacity: 1;
    }

    to {
      bottom: -100%;
      top: auto;
      opacity: 0;
    }
  }
}

.problem-frac-wrap {
  display: inline-block;
  min-height: 66px;
  min-width: 60px;
  text-align: center;
  // overflow: hidden;
  vertical-align: middle;

  .problem-frac-numerator {
    @include vw-convert-none(height, 38px);
    @include vw-convert-none(line-height, 30px);
    // width: 90%;
    border-bottom: 1px solid #7f7f7f;
    @include vw-convert-none(padding, 2px);
    // @include vw-convert-none(padding-bottom, 4px);
    @include vw-convert-none(padding-bottom, 10px);

    .blank {
      width: 90%;
    }
  }

  .problem-frac-denominator {
    @include vw-convert-none(height, 30px);
    @include vw-convert-none(line-height, 12px);
    width: 100%;
    @include vw-convert-none(padding, 2px);
    @include vw-convert-none(padding-top, 4px);

    .blank {
      width: 90%;
    }
  }
}

.fill-blank {
  .problem-frac-wrap {
    .problem-frac-numerator {
      width: 100%;
    }
  }
}

.quiz-content__title {
  .pstruct {
    height: 3.1em !important;
  }

  .underline .underline-line {
    border-bottom-width: 0.1em !important;
    transform: translateY(6px);
    border-bottom-color: #333a53;
  }

  .mord.hangul_fallback {
    font-weight: 600;
    @include font;
  }
}

.desmos-content {
  width: 100%;
  height: 400px;
}
