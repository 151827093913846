@import "../../../style/helpers/_index";

@mixin custom-auto-count($number, $start-number: 1) {
  .img {
    &:before {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
    }
  }

  @for $i from $start-number through $number {
    &:nth-child(#{$i}) {
      .img {
        &:before {
          content: "#{$i}";
        }
      }
    }
  }
}

.ranking {
  margin: 0 auto;
  width: 100%;

  &.column-list {
    column-count: 2;
    text-align: center;
    width: auto;
    // @include vw-convert(max-width, 1168px);
    @include vw-convert(max-width, 1800px);

    .rankinglist {
      display: inline-block;
      vertical-align: top;
      // @include vw-convert(width, 560px);
      @include vw-convert(min-width, 880px);
      width: auto;
      margin-right: 0;
      tbody {
        display: table;
        min-width: 100%;
      }
      &:nth-child(odd) {
        // @include vw-convert(margin-right, 20px);
      }
    }
    .card-wrap {
      // @include vw-convert(width, 560px);
      @include vw-convert(max-width, 900px);
      @include vw-convert(min-width, 810px);
    }
    .result-title {
      display: none;
    }

    .img-wrap,
    .name-wrap {
      display: inline-block;
    }

    .question-mark li {
      @include vw-convert(margin-left, 18px);
    }
  }
  &.full-list,
  &.short-full-list {
    .rankinglist-wrap {
      display: inline-block;
    }
  }
  &.full-list {
    // column-count: 4;
    text-align: center;
    width: auto;
    @include vw-convert(max-width, 1880px);
    @include vw-convert(margin-top, 30px, true);
    .rankinglist-wrap {
      &:nth-child(4n) {
        .rankinglist {
          margin-right: 0;
        }
      }
      &:last-child {
        .rankinglist {
          margin-right: 0;
        }
      }
    }
    .rankinglist {
      display: inline-block;
      vertical-align: top;
      // @include vw-convert(width, 560px);
      // @include vw-convert(min-width, 615px);
      // min-width:auto;
      width: auto;
      @include vw-convert(margin-right, 40px);
      tbody {
        display: table;
        min-width: 100%;
      }
      &:nth-child(odd) {
        // @include vw-convert(margin-right, 20px);
      }
      &.fill {
        .name-wrap {
          .name {
            @include vw-convert(margin-left, 25px);
          }
        }
      }
    }
    .card-wrap {
      // @include vw-convert(width, 560px);
      @include vw-convert(max-width, 900px);
      // @include vw-convert(min-width, 615px);
      min-width: auto;
    }
    .result-title {
      display: none;
    }

    .img-wrap {
      display: none;
    }
    .name-wrap {
      @include vw-convert(margin-left, 15px);
      display: inline-block;
      .name {
        @include vw-convert(margin-left, 25px);
      }
    }
    .question-mark {
      @include vw-convert(margin-left, 10px);
      @include vw-convert(margin-right, 20px);
      li {
        @include vw-convert(margin-left, 14px);
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .timer {
      display: none;
    }
  }
  &.short-full-list {
    // column-count: 3;
    text-align: center;
    width: auto;
    @include vw-convert(max-width, 1880px);
    @include vw-convert(margin-top, 30px, true);
    margin-top: 0;
    .rankinglist-wrap {
      // max-width: 50%;
      margin-right: 10px;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    .rankinglist {
      display: inline-block;
      vertical-align: top;
      // @include vw-convert(width, 560px);
      // @FIXME: 20.06.18 한 반에 학생수가 많지만 혼자 수업을 듣고, 문제가 10문항 일경우 내려가는 경우 때문에 min-width 추가
      // @include vw-convert(min-width, 565px);
      width: auto;
      margin-right: 0;
      &.fill {
        .card-wrap {
          &:before {
            @include vw-convert(line-height, 60px);
          }
        }
        .name {
          @include vw-convert(margin-left, 25px);
        }
      }
      &.one,
      &.two,
      &.three {
        .card-wrap {
          &:before {
            @include vw-convert(line-height, 60px);
          }
        }
      }
      tbody {
        display: table;
        min-width: 100%;
      }
    }
    .card-wrap {
      // @include vw-convert(width, 560px);
      @include vw-convert(max-width, 615px);
      // @include vw-convert(min-width, 475px);
      @include vw-convert(height, 60px);
      &:before {
        @include vw-convert(line-height, 60px, "pc", "true");
      }
    }
    .result-title {
      display: none;
    }

    .img-wrap {
      display: none;
    }
    .name-wrap {
      @include vw-convert(margin-left, 15px);
      display: inline-block;
      .name {
        @include vw-convert(margin-left, 15px);
        @include vw-convert(font-size, 24px);
      }
    }
    .question-mark {
      @include vw-convert(margin-left, 10px);
      @include vw-convert(padding-right, 10px);
      li {
        @include vw-convert(margin-left, 15px);
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .timer {
      display: none;
    }
  }
  &.is-team {
    // @include vw-convert(max-width, 850px);
    .card-wrap {
      width: 100%;
    }
    .fill {
      .dual {
        &:before {
          background: #333a53;
          transition: background 0.7s;
        }
      }
      .card-wrap {
        &:before {
          // color:white!important;
        }
      }
      &.one,
      &.two,
      &.three {
        .dual {
          &:before {
            background: white;
          }
        }
        .card-wrap {
          &:before {
            color: white !important;
          }
        }
      }
    }

    .result-title {
      display: none;
    }

    .rankinglist {
      @mixin cauto-count($number, $start-number: 1) {
        .card-wrap {
          &:before {
            position: absolute;
            z-index: 1;
            @include vw-convert(font-size, 32px);
            @include vw-convert(left, 34px);
            @include vw-convert(line-height, 90px);
            @include font;
            font-weight: 600;
          }
        }

        @for $i from $start-number through $number {
          &:nth-child(#{$i}) {
            .card-wrap {
              &:before {
                content: "#{$i}";
              }
            }
          }
        }
      }

      // @include cauto-count(10);

      // &:nth-child(1) {
      //   &:after {
      //     background: #FFA842;
      //   }

      //   .card-wrap:before {
      //     color: #FFA842;
      //   }
      // }

      // &:nth-child(2) {
      //   &:after {
      //     background: #1cc4cc;
      //   }

      //   .card-wrap:before {
      //     color: #1CC4CC;
      //   }
      // }

      // &:nth-child(3) {
      //   &:after {
      //     background: #ef6b69;
      //   }

      //   .card-wrap:before {
      //     color: #EF6B69;
      //   }
      // }
    }

    .img-wrap,
    .name-wrap {
      display: inline-block;
    }

    .img-wrap {
      @include vw-convert(margin-left, 60px);
    }

    .card-wrap {
      // @include vw-convert(width, 800px);
    }

    .img {
      @mixin revese-count($start-number, $end-number) {
        @for $i from $end-number through $start-number {
          $end-number: $end-number - #{$i};
          &:nth-child(#{$i}) {
            z-index: $end-number;
          }
        }
      }
      // @include revese-count(1, 99)
    }

    .img.solo {
      border: 4px solid white;
      box-sizing: content-box;
      z-index: 1;
      margin-left: 0;
    }

    .img.team {
      display: inline-block;
      @include vw-convert(margin-left, -10px);
      border: 3px solid white;
      @include vw-convert(border-width, 3px);

      + .name {
        @include vw-convert(margin-left, 40px);
      }
    }

    .name {
      @include vw-convert(margin-left, 40px);
    }

    .dual {
      display: inline-block;
      margin-left: 0;

      &:before {
        display: inline-block;
        vertical-align: middle;
        @include vw-convert(margin, 0px 14px);
        @include vw-convert(width, 6px);
        @include vw-convert(height, 6px);
        background: #7f92a3;
        border-radius: 50%;
        content: "";
      }
    }

    &.is-two-team {
      display: flex;
      @include vw-convert(max-width, 1180px);
      width: auto;
      .rankinglist-wrap {
        margin: 0px 8px;
      }
      .question-mark {
        // margin-left:0;
        @include vw-convert(margin, 10px 0px);
      }
      &.is-many {
        .rankinglist {
          @include vw-convert(width, 574px);
        }
      }

      .rankinglist {
        display: inline-block;
        @include vw-convert(width, 456px);
        width: auto;
        vertical-align: top;
        @include vw-convert(margin-right, 30px);
        margin-bottom: 0;
        border: 8px solid white;
        @include vw-convert(border-width, 8px);
        box-shadow: 8px 8px 10px #e5e5e5;

        &:last-child {
          margin-right: 0;
        }

        &:after {
          content: none;
        }
        tbody,
        tr,
        td {
          display: block;
          width: 100%;
        }
        &.one {
          border: 8px solid #ffa842;
          .result-title {
            color: #ffa842;
          }
        }
        .question-mark {
          max-width: 70%;
          font-size: 0;
          text-align: center;
          li {
            float: none;
            display: inline-block;
            @include vw-convert(margin, 5px 10px);
          }
        }
      }

      .card-wrap {
        width: 100%;
        @include vw-convert(height, 350px);
        box-shadow: none;

        &:before {
          content: none !important;
        }
      }

      .result-title {
        position: absolute;
        @include vw-convert(top, 10px);
        right: 0;
        left: 0;
        text-align: center;
        display: block;
        text-align: center;
        @include vw-convert(font-size, 48px);
        @include font;
        font-weight: 600;
        color: #abb9c5;

        &.is-visible {
          visibility: visible;
        }
      }

      .face {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include vw-convert(height, 355px);
        @include vw-convert(margin-top, 100px);
        &:before {
          content: none;
        }
      }

      .img-wrap {
        @include vw-convert(margin-top, 0px);
        margin-left: 0;

        .img {
          @include vw-convert(width, 93px);
          @include vw-convert(height, 93px);
          border: 4px solid white;
          @include vw-convert(border-width, 4px);
        }
      }

      .name-wrap {
        @include vw-convert(margin-top, 20px);
        // @include vw-convert(max-width, 370px);
        text-align: center;

        .name:first-child {
          margin-left: 0;
        }
      }

      .timer {
        top: auto;
        right: 0;
        left: 0;
        @include vw-convert(bottom, 25px);
        // margin:inherit;
      }
    }
  }
}

.rankinglist-wrap {
  // display: inline-block;
}

.rankinglist {
  position: relative;
  @include vw-convert(margin-right, 48px);
  @include vw-convert(border-radius, 0px 8px 8px 0px);
  @include custom-auto-count(12);
  @include vw-convert(margin-bottom, 24px);
  background: white;

  &.over {
    .card-wrap {
      box-shadow: 8px -8px 10px 0px #e5e5e5;
      transition: box-shadow 0.7s;
    }
  }

  &:after {
    position: absolute;
    @include vw-convert(width, 8px);
    top: 0;
    bottom: 0;
    left: 0;
    background: #abb9c5;
    @include vw-convert(border-radius, 8px 0px 0px 8px);
    content: "";
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-duration: none;
    @include vw-convert(border-radius, 8px 8px 8px 8px);
    z-index: 0;
    content: "";
  }

  .card-wrap {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: 0.5s;
    box-shadow: 8px 8px 10px #e5e5e5;
    @include vw-convert(border-radius, 0px 8px 8px 0px);
    @include vw-convert(width, 560px);
    @include vw-convert(height, 90px);
  }

  @keyframes flip {
    0% {
      transform: rotatex(0deg);
      // transform: rotatex(-180deg);
    }

    50% {
      transform: rotatex(-180deg);
    }

    100% {
      transform: rotatex(0deg);
    }
  }

  &.turn {
    // &:hover {
    .card-wrap {
      background: white;
    }

    &.over {
      &:after {
        animation: flip 0.7s;
        animation-fill-mode: both;
      }

      .card-wrap {
        animation: flip 0.7s;
        animation-fill-mode: both;
      }
    }

    &.reset {
      &:after {
        animation: ro1 0.7s;
        animation-fill-mode: both;
      }

      .card-wrap {
        animation: ro1 0.7s;
        animation-fill-mode: both;
      }
    }
  }
  &.one,
  &.two,
  &.three {
    .card-wrap {
      &:before {
        position: absolute;
        @include vw-convert(line-height, 90px);
        @include vw-convert(font-size, 32px);
        @include vw-convert(left, 13px);
        @include font;
        font-weight: 600;
      }
    }
    .timer {
      color: white;
    }
  }
  &.fill {
    .name-wrap {
      .name {
        @include vw-convert(margin-left, 45px);
      }
    }
    &.one,
    &.two,
    &.three {
      .question-mark li {
        &.is-right {
          span {
            background: url("./img/pright-fill.svg") no-repeat center;
            background-size: contain;
          }
        }
        &.is-false {
          span {
            background: url("./img/pfalse-fill.svg") no-repeat center;
            background-size: contain;
          }
        }
      }
    }
  }
  &.one {
    &:after {
      background: #ff9b25;
    }
    .card-wrap {
      &:before {
        color: #ff9b25;
        content: "1";
      }
    }
    .timer {
      background: #ff9b25;
    }
  }
  &.two {
    &:after {
      background: #1cc4cc;
    }
    .card-wrap {
      &:before {
        color: #1cc4cc;
        content: "2";
      }
    }
    .timer {
      background: #1cc4cc;
    }
  }
  &.three {
    &:after {
      background: #ef6b69;
    }
    .card-wrap {
      &:before {
        color: #ef6b69;
        content: "3";
      }
    }
    .timer {
      background: #ef6b69;
    }
  }

  &.fill {
    &.one,
    &.two,
    &.three {
      &:before {
        transform: scaleX(1);
      }
      &:after {
        content: none;
      }
      .card-wrap {
        &:before {
          color: white;
        }
        .name {
          color: white;
        }
      }
      .timer {
        transition: background 0.5s;
      }
    }
    &.one {
      // &.fill {
      &:before {
        background-color: #ff9b25;
      }
      .timer {
        background: #ffc27b;
      }
      // }
    }

    &.two {
      // &.fill {
      &:before {
        background-color: #1cc4cc;
      }

      .timer {
        background: #60d6db;
      }
      // }
    }

    &.three {
      // &.fill {
      &:before {
        background-color: #ef6b69;
      }

      .timer {
        background: #f49796;
      }
      // }
    }
  }

  &:nth-child(even) {
    margin-right: 0;
  }

  .face {
    @include vertical-center;
    // position: absolute;
    width: 100%;
    height: 100%;
    text-align: left;
    backface-visibility: hidden;
    z-index: 2;
    @include vw-convert(font-size, 32px);
    color: $navy;
    @include vw-convert(border-radius, 0px 8px 8px 0px);
  }

  .front {
    // position: absolute;
    z-index: 1;
  }
  .front-threeteam {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .name-wrap {
      margin-left: 20px;
    }
    .name-wrap-personal {
      width: 165px;
    }
  }

  .back {
    transform: rotatex(-180deg);
  }

  .num,
  .img,
  .name {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  .num {
    @include vw-convert(margin-left, 20px);
    @include vw-convert(font-size, 32px);
    color: #7f92a3;
    @include font;
    font-weight: 500;
    transition: color 0.5s;
  }

  .img {
    @include vw-convert(width, 58px);
    @include vw-convert(height, 58px);
    border-radius: 50%;
    @include vw-convert(margin-left, 58px);
    background: #ccc;
    overflow: hidden;

    &.team {
      display: none;

      &:before {
        content: none;
      }
    }

    img {
      display: block;
      // @include absolute-full(img);
      // max-width: 125%;
      // height:auto;
      // !object-fit TODO: obeject-fit
      object-fit: cover;
      max-width: 100%;
      height: 100%;
      margin: auto;
    }

    &.solo {
      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        @include vw-convert(left, -30px);
        margin: auto;
        @include vw-convert(font-size, 32px);
        @include vw-convert(line-height, 60px);
        color: #7f92a3;
        @include font;
        font-weight: 500;
      }
    }
  }

  .name {
    // @include vw-convert(margin-left, 30px);
    transition: color 0.5s;
    @include font;
    color: #333a53;
    @include vw-convert(font-size, 32px);
    font-weight: 500;
  }
  .game-name {
    margin-left: 10px;
  }

  .dual {
    display: none;
  }

  .timer {
    position: absolute;
    @include vw-convert(right, 32px);
    top: 0;
    bottom: 0;
    margin: auto;
    @include vw-convert(width, 172px);
    @include vw-convert(height, 42px);
    @include vw-convert(line-height, 42px);
    text-align: center;
    @include font;
    // color: #7f92a3;
    color: #536476;
    @include vw-convert(font-size, 28px);
    background: #d7e0e7;
    @include vw-convert(border-radius, 30px);
  }
  .question-mark-personal {
    flex-grow: 1;
    padding-right: 0px !important;
  }
  .question-mark {
    display: inline-block;
    vertical-align: middle;
    padding-right: 20px;

    @include vw-convert(margin-left, 40px);
    @include clearfix;
    li {
      float: left;
      @include vw-convert(width, 32px);
      @include vw-convert(height, 32px);
      @include vw-convert(margin-left, 32px);
      background: #d7e0e7;
      border-radius: 50%;
      overflow: hidden;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        @include vw-convert(margin-right, 220px);
      }
      span {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        height: 100%;
      }
      &.is-right {
        span {
          background: url("./img/pright.svg") no-repeat center;
          background-size: contain;
        }
      }
      &.is-false {
        span {
          background: url("./img/pfalse.svg") no-repeat center;
          background-size: contain;
        }
      }
    }
  }
}

.attendancelist-wrap {
  @include vw-convert(margin-top, 43px);
  // @include vw-convert(max-width, 1320px);
  max-width: initial;
  text-align: center;

  &.childmin {
    .attendancelist {
      @include vw-convert(width, 247px);
      @include vw-convert(height, 360px);
      &:nth-child(4n) {
        // margin-right:0;
      }
      .img {
        @include vw-convert(height, 200px);
      }

      &__name {
        @include vw-convert(font-size, 48px);
      }

      &__time {
        @include vw-convert(width, 186px);
        @include vw-convert(height, 45px);
        @include vw-convert(font-size, 32px);
        @include vw-convert(border-radius, 30px);

        span {
          @include vw-convert(line-height, 36px);
        }
      }
    }
  }

  &.view-3 {
    @include vw-convert(max-width, 670px);
  }

  &.view-4 {
    @include vw-convert(max-width, 900px);
  }

  &.view-5 {
    @include vw-convert(max-width, 1100px);
  }
  &.view-6 {
    @include vw-convert(max-width, 1310px);
  }

  .attendancelist {
    display: inline-block;
    position: relative;
    vertical-align: bottom;
    @include vw-convert(width, 192px);
    @include vw-convert(height, 280px);
    @include vw-convert(margin, 32px 24px 0px 0px);
    @include vw-convert(border-radius, 16px);

    text-align: center;

    // overflow: hidden;
    .attendance-wrap {
      &:after {
        position: absolute;
        @include vw-convert(width, 43px);
        @include vw-convert(height, 50px);
        @include vw-convert(right, 10px);
        top: 0;
        content: "";
      }
    }

    .attendancelist__name {
      // color:white;
    }

    &.not-attendance {
      .attendance-wrap {
        display: none;
      }
    }

    .attendance-wrap {
      @include absolute-full;
      @include vw-convert(border-radius, 16px);
      box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.1);
      background: white;
    }

    &.is-attend {
      .null-wrap {
        display: none;
      }
    }

    .null-wrap {
      height: 100%;
      width: 100%;
    }

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        .attendancelist__name {
          color: white;
        }
      }

      &.effect {
        &:nth-child(#{$i}) {
          animation-delay: ($i - 1) * 0.2s;
        }
      }
    }

    &.effect {
      .attendance-wrap {
        @include animation(0.8, 0, cardscale) {
          animation-timing-function: ease-in-out;
        }
      }

      &:before {
        @include absolute-full;
        filter: blur(50px);
        @include vw-convert(border-radius, 16px);
        content: "";
      }
    }

    &.not-attendance {
      border: 4px dashed $gray50;
      @include vw-convert(border-width, 4px);
      background: $gray20 !important;
      box-shadow: none;

      .img {
        background: url("./img/not-attendance.svg") no-repeat center;

        &:after {
          content: none !important;
        }

        img {
          display: none;
        }
      }

      .attendancelist__name {
        @include vw-convert(font-size, 32px);
        color: $gray50 !important;
      }

      .attendancelist__time {
        display: none;
      }
    }

    // @include img ($path, 'num', 'svg'){
    //   position: absolute;
    //   @include vw-convert(top, 0px);
    //   @include vw-convert(right, 10px);
    //   @include vw-convert(width, 43px);
    //   @include vw-convert(height, 50px);
    //   @include vw-convert(background-size, 43px 50px);
    // }

    &:nth-child(1) {
      .attendance-wrap {
        background: $deep-yellow;
      }

      .attendancelist__time {
        background: rgba(#fff, 0.3);
      }
    }

    &:nth-child(2) {
      .attendance-wrap {
        background: $green;
      }

      .attendancelist__time {
        background: rgba(#fff, 0.3);
      }
    }

    &:nth-child(3) {
      .attendance-wrap {
        background: $red;
      }

      .attendancelist__time {
        background: rgba(#fff, 0.3);
      }
    }

    &.is-attend {
      &:nth-child(1) {
        .attendance-wrap {
          &:after {
            background: url("./img/medal1.svg") no-repeat center;
            background-size: contain;
          }
        }
      }

      &:nth-child(2) {
        .attendance-wrap {
          &:after {
            background: url("./img/medal2.svg") no-repeat center;
            background-size: contain;
          }
        }
      }

      &:nth-child(3) {
        .attendance-wrap {
          &:after {
            background: url("./img/medal3.svg") no-repeat center;
            background-size: contain;
          }
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .img {
      position: relative;
      width: 100%;
      @include vw-convert(border-radius, 16px 16px 0px 32px);
      overflow: hidden;
      @include vw-convert(height, 156px);
      background: #abb9c5;

      img {
        position: absolute;
        top: -100%;
        right: -100%;
        left: -100%;
        bottom: -100%;
        margin: auto;
        max-width: 120%;
        min-width: 100%;
      }
    }

    &__name {
      color: $navy;
      @include vw-convert(margin-top, 20px);
      @include vw-convert(font-size, 32px);
      @include font;
      font-weight: 500;
    }

    &__time {
      background: $gray50;
      @include vertical-center();
      @include vw-convert(font-size, 24px);
      @include vw-convert(width, 144px);
      @include margin-auto;
      @include vw-convert(margin-top, 5px);
      @include vw-convert(border-radius, 16px);
      color: #fff;

      span {
        display: inline-block;
        vertical-align: text-top;
        @include vw-convert(line-height, 28px);
      }
    }
  }
}

.page-all-layout {
  width: 100%;
  height: 100%;
  border: none;
  background: #222943;

  &.is-close {
    width: auto;
    height: auto;
    background: none;

    .popup-closebtn {
      display: none;
    }

    .preview-wrap {
      max-width: initial;
      height: auto;
      margin-top: 0;
      overflow-y: visible;

      .preview {
        display: none;
        width: 100%;
        height: 100%;
        border: none;
        margin: 0;
        overflow: visible;
        cursor: auto;

        &:hover {
          border: none;
          border: 0;
        }

        &.is-this {
          display: block;
        }

        .content {
          position: static;

          .attendance-layout {
            transform: scale(1);
          }

          .queturepoint-layout,
          .gameexplain-layout,
          .maketeam-layout,
          .activity-layout,
          .mvp-layout,
          .challengequiz-layout,
          .gameranking-layout {
            transform: scale(1);
          }
        }

        .dim {
          display: none !important;
        }
      }
    }

    .popup-ment {
      display: none;
    }
  }

  .popup-closebtn {
    @include vw-convert(width, 80px);
    @include vw-convert(height, 80px);
    background: #333a53;
    @include vw-convert(background-size, 80px 80px);
    @include vw-convert(border-radius, 16px);
    @include vw-convert(top, 40px);
    @include vw-convert(right, 48px);
  }

  .preview-wrap {
    @include vw-convert(margin-top, 80px);
    @include margin-auto;
    @include vw-convert(max-width, 1570px);
    @include vw-convert(height, 770px);
    overflow-y: auto;
    text-align: left;

    .preview {
      display: inline-block;
      @include vw-convert(width, 354px);
      @include vw-convert(height, 200px);
      border: 1px solid rgba(255, 255, 255, 0.2);
      border: 1px solid #758391;
      box-sizing: content-box;
      @include vw-convert(border-width, 8px);
      @include vw-convert(border-radius, 16px);
      background: white;
      @include vw-convert(margin-bottom, 20px);
      @include vw-convert(margin-right, 21px);
      position: relative;
      overflow: hidden;
      // background: url('../../components/Teacher/layout/img/basicbg.svg') no-repeat center;
      background-size: cover;
      cursor: pointer;

      &:nth-child(4n) {
        margin-right: 0;
      }

      &.is-active {
        display: inline-block;
      }

      &.is-this {
        border-color: #55a3ff;

        &:hover {
          .dim span {
            display: block;
          }
        }

        .dim {
          display: block;
          background: linear-gradient(
            0deg,
            rgba(85, 163, 255, 0.3),
            rgba(85, 163, 255, 0.3)
          );

          span {
            display: none;
          }
        }

        &:hover {
          border-color: #575c70;

          .dim {
            background: linear-gradient(
              0deg,
              rgba(51, 58, 83, 0.7),
              rgba(51, 58, 83, 0.7)
            );
          }
        }
      }

      .dim {
        display: none;
        color: white;
        @include vw-convert(font-size, 24px);
        font-weight: 500;
        @include absolute-full;
        background: linear-gradient(
          0deg,
          rgba(51, 58, 83, 0.7),
          rgba(51, 58, 83, 0.7)
        );
        @include vw-convert(line-height, 200px);
        text-align: center;
      }

      &:hover {
        border-color: #575c70;

        .dim {
          display: block;
        }
      }

      // > div {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   flex-direction: column;
      //   transform: scale(0.4);
      // }
      .content {
        @include absolute-full;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url("../../Teacher/layout/img/basicbg.svg") no-repeat center;
        background-size: cover;

        .big-title {
          animation: none;
        }

        .Amediaplayer {
          transform: scale(0.18);
        }

        .gameexplain-layout {
          .page-content {
            @include vw-convert(width, 1405px);
          }
        }

        .maketeam-layout {
          .team-type {
            @include vw-convert(width, 844px);
          }
        }

        .queturepoint-layout,
        .gameexplain-layout,
        .maketeam-layout,
        .activity-layout,
        .mvp-layout,
        .challengequiz-layout,
        .gameranking-layout {
          transform: scale(0.225);
        }

        .attendance-layout {
          transform: scale(0.3);

          .attendancelist-wrap {
            width: 100vw;
          }
        }

        .msg-layout {
          margin-bottom: 0;
          transform: scale(0.2);

          .msg-content {
            @include vw-convert(width, 1496px);
          }
        }
      }
    }
  }

  .popup-ment {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    font-weight: 500;
    text-align: center;
    @include vw-convert(width, 1548px);
    @include vw-convert(line-height, 86px);
    @include vw-convert(bottom, 110px);
    @include vw-convert(font-size, 32px);
    color: white;
    @include font;
    background: rgba(#fff, 0.2);
    @include vw-convert(border-radius, 16px);
  }
}
