@import "../../style/helpers/index";

.ht-game-layout {
  width: 1240px;
  margin: 0px auto 0px auto;
  padding-top:128px;
  padding-bottom:140px;
  .ht-filter {
    .due {
      display: none;
    }
  }
  .game-content {
    margin-top:40px;
    text-align: center;
    &__card {
      display: inline-block;
      position: relative;
      vertical-align: top;
      width: 286px;
      height:494px;
      margin-right:32px;
      margin-bottom:40px;
      border-width: 1px;
      border-radius: 8px;
      background: white;
      box-sizing: border-box;
      overflow: hidden;
      &:nth-child(4), &:last-child {
        margin-right:0;
      }
      img {
        display: block;
        width: 100%;
        height:260px;
      }

      &.is-lock {
        .img-wrap-cover {
          display: block;
        }
      }

      &.is-remain-time {
        .img-wrap-timer {
          display: block;
        }
      }

      .img-wrap {
        position: relative;
        height:260px;

        &-cover {
          display: none;
          cursor: pointer;
          text-align: center;
          @include absolute-full();
          color: white;
          background: rgba(0, 0, 0, 0.8);
          font-size:16px;

          &:before {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            content: '';
          }
        }

        &-content {
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          padding:0px 36px;

          &.is-exp {
            >span {
              // background: url('../../style/components/imgs/glasses_icon.svg') no-repeat center;
              @include vw-convert(width, 60px);
              @include vw-convert(height, 30px);
              @include vw-convert(background-size, 60px 30px);
            }
          }

          >span {
            display: inline-block;
            width:40px;
            height:40px;
            // background: url('../../style/components/imgs/game-lock_icon.svg') no-repeat center;
            @include vw-convert(background-size, 40px 40px);
            margin-bottom: 16.2px;
          }

          .qpoint {
            display: block;
            color: #00d3ff;
          }
        }

        &-timer {
          display: none;
          position: absolute;
          right: 0;
          bottom: 0;
          height: 30px;
          line-height: 28px;
          padding:0px 10px 0px 8px;
          border-top-left-radius: 8px;
          background: rgba(0, 0, 0, 0.8);
          box-sizing: border-box;

          .remain-time {
            display: inline-block;
            color: white;
            font-size:14px;
          }

          .timer {
            display: inline-block;
            color: #00d3ff;
            font-size:16px;
            margin-left:7px;
            font-weight: 500;
          }
        }
      }
    }

    &__title {
      padding:0px 24px;
      margin-top:19px;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      .title {
        font-size:24px;
        color: #333;
        letter-spacing: -0.6px;
        font-weight: 600;
      }

      .type {
        margin-left:8px;
        color: #b2b2b2;
        font-size:16px;
      }
    }

    &__desc {
      margin-top: 16px;;
      padding:0 24px;
      font-size:16px;
      color: #7f7f7f;
      letter-spacing: -0.4px;
    }

    .gameplay-btn {
      position: absolute;
      right:24px;
      left:24px;
      bottom:24px;
      height:40px;
      line-height: 40px;
      font-size:16px;
      font-weight: 500;
      text-align: center;
      border-radius: 10vw;
      background: rgba(#1cc4cc, 0.1);
      color: #1cc4cc;
      cursor: pointer;
    }
  }
}