// theme ě t
// componentě c
@import '../../../style/helpers/_index';

@mixin vw-convert-none($value, $size, $type:'pc') {
  @if($type == 'pc') {
    #{$value}: $size;
  }
  @if($type == 'mo') {
    #{$value}: $size;
  }
}

@mixin auto-slider-count($number) {
  @for $i from 1 through $number {
    &:nth-child(#{$i}) {
      li {
        &:before {
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          content:'#{$i}'
        }
      }
    }
  }
}

// @mixin vw-convert-mo() {
//   @media screen and (max-width: 768+'px') {
//     @content;
//   }
// }
.t-main-content-box {
  box-shadow: 0 5px 15px 0 rgba(114, 115, 255, 0.15);
  background: #fff;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;

  @include vw-convert-mo() {
    box-shadow: none;
    background: inherit;
  }
}

.t-answer-list {
  margin: 0 auto;
  @include vw-convert-none(margin-top, 40px);

  @include vw-convert-mo() {
    @include vw-convert-none(margin-top, 18px, mo);
    @include vw-convert-none(margin-bottom, 16.5px, mo);
  }

  .katex {
    display: inline-block;
    @include vw-convert-none(margin, 0px 5px);
  }

  &.half {
    @include clearfix();
    @include vw-convert-none(margin-top, 16px);
    @include vw-convert-none(max-width, 850px);

    li {
      display: inline-block;
      width: 45%;
      vertical-align: top;
      @include vw-convert-none(margin-top, 24px);

      @include vw-convert-mo() {
        width: 100%;
      }

      &:first-child {
        @include vw-convert-none(margin-top, 24px);
      }

      &:nth-child(odd) {
        @include vw-convert-none(margin-right, 16px);
      }

      img {
        display: none;
      }

      >span {
        max-width: 85%;
      }
    }
  }
  &.inline {
    display: flex;
    align-items: center;
    gap: 24px;
    @include vw-convert-mo() {
      gap: 12px;
    }
    li {
      border-radius: 0;
      flex-grow: 1;
      margin: 0;
      padding-left: 12px;
      width: 25%;
    }
  }

  li {
    border-radius: 10vw;
    border: 2px solid #d7e0e7;
    margin: 0 auto;
    @include vw-convert-none(margin-top, 24px);
    @include vw-convert-none(padding, 10px);
    @include auto-count(20);
    cursor: pointer;

    @include vw-convert-mo() {
      @include vw-convert-none(margin-top, 9px, mo);
      border: none;
      box-shadow: none;

      &:before {
        @include vw-convert-none(width, 16px, mo);
        @include vw-convert-none(height, 16px, mo);
        @include vw-convert-none(font-size, 10px, mo);
        @include vw-convert-none(line-height, 18px, mo);
      }
    }

    &:first-child {
      margin-top: 0;
    }

    &:before {
      @include vw-convert-none(width, 30px);
      @include vw-convert-none(height, 30px);
      @include vw-convert-none(line-height, 30px);
      @include vw-convert-none(margin-right, 12px);
      @include vw-convert-none(font-size, 20px);
      display: inline-block;
      vertical-align: middle;
      background: #7f92a3;
      color: white;
      border-radius: 50%;
      content: '';

      @include vw-convert-mo() {
        vertical-align: top !important;
      }
    }
    &.img {
      &.is-chk {
        
      }
    }
    >span {
      display: inline-block;
      vertical-align: middle;
      max-width: 90%;
      color: #7f92a3;
      @include vw-convert-none(font-size, 20px);

      @include vw-convert-mo() {
        max-width:80%;
        @include vw-convert-none(margin-top, 3px, mo);
        @include vw-convert-none(font-size, 8px, mo);
        @include vw-convert-none(margin-left, 5px, mo);
      }
    }

    img {
      display: none;
    }

    &.is-chk {
      border-color: $chk;
      box-shadow: none;
      @include vw-convert-mo() {
        border: none;
      }

      &:before {
        background:$chk;
        color:white;
        @include vw-convert-mo() {
          @include vw-convert-none(width, 16px, mo);
          @include vw-convert-none(height, 16px, mo);
          @include vw-convert-none(background-size, 16px 16px, mo);
        }
      }

      >span {
        color:$chk;
      }
    }

    &.is-true {
      border-color: $true;
      box-shadow: none;

      @include vw-convert-mo() {
        border: none;
      }

      &:before {
        background: url('../images/right_icon.svg') no-repeat center;
        background:$true;

        @include vw-convert-mo() {
          @include vw-convert-none(width, 16px, mo);
          @include vw-convert-none(height, 16px, mo);
          @include vw-convert-none(background-size, 16px 16px, mo);
        }
      }

      >span {
        color: $true;
      }
    }

    &.is-reallytrue {
      border-color: $true;
      background: $true;
      box-shadow: none;

      @include vw-convert-mo() {
        background: inherit;
        border: none;

        span {
          color: $true;
          margin-top: 0;
        }
      }

      &:before {
        background:url('../images/right_icon.svg') no-repeat center;
        border: 2px solid white;
        box-sizing: border-box;
        // content: '';

        @include vw-convert-mo() {
          @include vw-convert-none(width, 16px, mo);
          @include vw-convert-none(height, 16px, mo);
          @include vw-convert-none(background-size, 16px 16px, mo);
        }
      }

      >span {
        color: white;

        @include vw-convert-mo() {
          color: $true;
        }
      }
    }

    &.is-false {
      border-color: $false;
      box-shadow: none;
      color: $false;

      @include vw-convert-mo() {
        border: none;

        span {
          color: $false;
          margin-top: 0;
        }
      }

      &:before {
        background:$false;

        @include vw-convert-mo() {
          @include vw-convert-none(width, 16px, mo);
          @include vw-convert-none(height, 16px, mo);
          @include vw-convert-none(background-size, 16px 16px, mo);
        }
      }

      span {
        @include vw-convert-none(color, inherit, mo);
        color: $false;
      }
    }

    &.img {
      border: none;
      box-shadow: none;

      @include vw-convert-mo() {
        text-align: center;
        @include vw-convert-none(width, 154px, mo);
        @include vw-convert-none(min-height ,79.5px, mo);
        @include vw-convert-none(padding, 10.5px 12px 10px 12px, mo);
        box-sizing: border-box;
        @include vw-convert-none(border-width, 1.5px, mo);
        border-style: solid;
        @include vw-convert-none(border-radius, 1.5px, mo);
        position: relative;
        box-shadow: none;
        border-color: #e5e5e5;
        @include vw-convert-none(margin-top, 9px, mo);
      }

      &:before {
        @include vw-convert-mo() {
          position: absolute;
          @include vw-convert-none(top, 7px, mo);
          @include vw-convert-none(left, 7px, mo);
          @include vw-convert-none(width, 16px, mo);
          @include vw-convert-none(height, 16px, mo);
          @include vw-convert-none(font-size, 10px, mo);
          vertical-align: top;
          @include vw-convert-none(margin-right, 5px, mo);
        }
      }

      >span {
        display: inline-block;
        margin: 0;
        max-width: 100%;
        border: none;
        box-shadow: none;
        position: relative;
        border-radius: 10vw;

        @include vw-convert-mo() {
          box-shadow: none;
          border: none;
          @include vw-convert-none(margin-top, 5px, mo);
        }
      }

      .imageContainer {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        // @include vw-convert-none(min-width, 115px);
        @include vw-convert-none(max-height, 135px);
        @include vw-convert-none(margin, 0px 16px);

        @include vw-convert-mo() {
          display: block;
        }

        img {
          display: inline-block;
          height: 100%;
          margin: 0 auto;
          max-height: 135px;

          @include vw-convert-mo() {
            display: block;
            margin: 0 auto;
          }
        }
      }

      &.is-chk {
        @include vw-convert-mo() {
          border-width:1.5px;
          border-style: solid;
          border-color: $chk;
        }

        >span {
          border-color: $chk;
          box-shadow: none;

          span {
            border: none;
            box-shadow: none;
          }
        }
      }

      &.is-true {
        @include vw-convert-mo() {
          border-color: $true;
          border-style: solid;
          border-width:1.5px;
        }

        >span {
          border-color: $true;
          box-shadow: none;
          color: $true;

          span {
            border: none;
            box-shadow: none;
          }
        }
      }

      &.is-reallytrue {
        background: none;

        @include vw-convert-mo() {
          border-color: $true;
          border-style: solid;
          border-width:1.5px;
        }

        &:before {
          border: none;
          @include vw-convert-none(background-size, 40px 40px);
        }

        .text {
          @include vw-convert-none(padding, 0px 10px);
          background: $true;
          border: none;
          box-shadow: none;
          color: white;

          @include vw-convert-mo() {
            color: $true;
            background: none;
          }

          span {
            border: none;
            box-shadow: none;
          }
        }
      }

      &.is-false {
        @include vw-convert-mo() {
          border-color: $false;
          border-style: solid;
          border-width:1.5px;
        }

        >span {
          border-color: $false;
          box-shadow: none;

          span {
            border: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}

.t-answer-tf {
  @include vw-convert-none(margin-top, 70px);
  @include vw-convert-none(margin-bottom, 10px);

  .btn-wrap {
    text-align: center;
    position: static;

    button {
      display: inline-block;
      color: white;
      background: #abb9c5;
      @include vw-convert-none(font-size, 20px);
      @include vw-convert-none(height, 48px);
      @include vw-convert-none(width, 148px);
      @include vw-convert-none(border-radius, 24px);
      @include btn-click(6px, #536476);

      @include vw-convert-mo() {
        @include remove-btn-click();
        background: none;
        color: #474747;
        border: 1px solid #ccc;
        @include vw-convert-none(width, 73px, mo);
        @include vw-convert-none(height, 25px, mo);
        @include vw-convert-none(font-size, 8px, mo);
        border-radius: 10vw;
        box-shadow: none;

      }

      &:first-child {
        @include vw-convert-none(margin-right, 32px);
        @include vw-convert-none(margin-right, 8px, mo);
      }

      &.is-chk {
        background: $chk;
        @include btn-click(6px, #397dce);

        @include vw-convert-mo() {
          @include remove-btn-click();
          background: white;
          color: $chk;
          border: 1px solid $chk;
        }
      }

      &.is-true {
        // color: $true;
        background: $true;
        // border: 1px solid $true;
        @include btn-click(6px, #048AA8);

        @include vw-convert-mo() {
          @include remove-btn-click();

        }
      }

      &.is-reallytrue {
        background: $true;
        @include btn-click(6px, #006a42);

        @include vw-convert-mo() {
          color: white;
          border-color: $true;
          @include remove-btn-click();
        }
      }

      &.is-false {
        // color: $false;
        background: $false;
        // border: 1px solid $false;
        @include btn-click(6px, #BF413F);

        @include vw-convert-mo() {
          @include remove-btn-click();
        }
      }
    }
  }
}

.t-calc {
  position: absolute;
  @include vw-convert-none(right, 30px);
  @include vw-convert-none(bottom, 76px);
  z-index: 1;

  &__wrap {
    @include vw-convert-none(width, 183px);
    @include vw-convert-none(height, 260px);
    @include vw-convert-none(border-radius, 8px);
    @include vw-convert-none(padding, 14px 17px 20px 17px);
    box-sizing: border-box;
    background: $purple;

  }

  &__num {
    text-align: center;
    @include clearfix();

    button {
      float: left;
      @include vw-convert-none(width, 32px);
      @include vw-convert-none(height, 32px);
      @include vw-convert-none(margin, 12px 14.7px 0px 0px);
      border-radius: 50%;
      @include vw-convert-none(font-size, 16px);
      color: $purple;
      background: #fff;
      @include btn-click(3px, #494ad4);
      font-weight: 500;

      &:nth-child(3n+1) {
        margin-left: 11px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  &__submit {
    @include clearfix();
    @include vw-convert-none(margin-top, 19px);

    button {
      float: left;
      @include vw-convert-none(width, 70px);
      @include vw-convert-none(height, 32px);
      @include vw-convert-none(border-radius, 20px);
      @include vw-convert-none(font-size, 16px);
      color: white;
    }

    &-del {
      background: #bebee2;
      @include btn-click(3px, #494ad4);
      @include vw-convert-none(margin-right, 8px);
    }

    &-ok {
      background: $chk;
      @include btn-click(3px, #0053b9);
    }
  }
}

.t-answer-bigsmall {
  &__img {
    position: relative;
    @include vw-convert-none(margin-top, 33px);
    @include vw-convert-none(margin-bottom, 33px);
    @include vw-convert-none(width, 410px);
    @include vw-convert-none(height, 280px);
    margin: auto;

    .water {
      &-right, &-left {
        position: absolute;
        @include vw-convert-none(top, 20px);
        @include vw-convert-none(bottom, 110px);
        @include vw-convert-none(width, 88.4px);
        @include vw-convert-none(height, 88.4px);
        @include vw-convert-none(font-size, 29px);
        @include vw-convert-none(line-height, 88.4px);
        display: inline-block;
        margin: auto;
        text-align: center;
        color: white;
      }

      &-right {
        @include vw-convert-none(right, 14px);
        background: url('../images/waterR_img.svg') no-repeat center;
        background-size:contain;
      }

      &-left {
        @include vw-convert-none(left, 14px);
        background: url('../images/waterR_img.svg') no-repeat center;
        background-size:contain;
      }
    }

    &.is-left-big {
      .scale-bridge {
        transform: rotate(-4deg);
        transition: all 0.3s;
      }

      .scale-plate {
        &-right {
          transform: translateY(-10px);
          transition: all 0.3s;
        }

        &-left {
          transform: translateY(10px);
          transition: all 0.3s;
        }
      }

      .water {
        &-right {
          transform:translateY(-10px);
          transition: all 0.3s;
        }

        &-left {
          transform:translateY(5px) scale(1.2);
          transition: all 0.3s;
        }
      }

      .scale-body {
        span {
          background: url('../images/bigsmall-center-right_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 55.1px 55.1px);
        }
      }
    }

    &.is-right-big {
      .scale-bridge {
        transform: rotate(4deg);
        transition: all 0.3s;
      }

      .scale-plate {
        &-right {
          transform: translateY(10px);

          @include vw-convert-mo() {
            transform: translateY(15px);
          }

          transition: all 0.3s;
        }

        &-left {
          transform: translateY(-10px);
          transition: all 0.3s;
        }
      }

      .water {
        &-right {
          transform: translateY(5px) scale(1.2);
          transition: all 0.3s;
        }

        &-left {
          transform: translateY(-10px);
          transition: all 0.3s;
        }
      }

      .scale-body {
        span {
          background: url('../images/bigsmall-center-left_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 55.1px 55.1px);
        }
      }
    }

    &.is-equl {
      .scale-body {
        span {
          background: url('../images/bigsmall-center-same_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 55.1px 55.1px);
        }
      }
    }

    &.is-unknown {
      .scale-bridge {
        transform: rotate(0deg);
        transition: all 0.3s;
      }

      .scale-plate {
        &-right {
          transform: translateY(0px);
          transition: all 0.3s;
        }

        &-left {
          transform: translateY(0px);
          transition: all 0.3s;
        }
      }

      .water {
        &-right {
          transform: translateY(-20px) scale(1);
          transition: all 0.3s;
        }

        &-left {
          transform: translateY(-20px);
          transition: all 0.3s;
        }
      }

      .scale-body {
        span {
          background: url('../images/bigsmall-center-unknown_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 55.1px 55.1px);
        }
      }
    }

    .scale {
      &-plate {

        &-right,
        &-left {
          position: absolute;
          margin: auto;
          @include vw-convert-none(bottom, 110px);
          @include vw-convert-none(width, 118.3px);
          @include vw-convert-none(height, 41.4px);
          z-index: 2;

          @include vw-convert-mo() {
            @include vw-convert-none(bottom, 47px, mo);
            @include vw-convert-none(width, 45.1px, mo);
            @include vw-convert-none(height, 17.1px, mo);
          }
        }

        &-right {
          right: 0;
          background: url('../images/scaleplateR_img.svg') no-repeat center;
          @include vw-convert-none(background-size, 118.3px 41.4px);
        }

        &-left {
          left: 0;
          background: url('../images/scaleplateL_img.svg') no-repeat center;
          @include vw-convert-none(background-size, 118.3px 41.4px);
        }

        .scale-body {
          span {
            &.same {
              background: url('../images/bigsmall-center-same_icon.svg') no-repeat center;
              @include vw-convert-none(background-size, 55.1px 55.1px);
            }
          }
        }
      }

      &-bridge {
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        @include vw-convert-none(bottom, 110px);
        @include vw-convert-none(width, 300px);
        @include vw-convert-none(height, 8px);
        background: url('../images/scalebar_img.svg') no-repeat center;
        @include vw-convert-none(background-size, 300px 8px);

        @include vw-convert-mo() {
          @include vw-convert-none(bottom, 48px, mo);
          @include vw-convert-none(width, 114px, mo);
          @include vw-convert-none(height, 3.1px, mo);
          background: url('../images/scalebar_img.svg') no-repeat center;
          background-size:contain;
        }
      }

      &-body {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        @include vw-convert-none(width, 177px);
        @include vw-convert-none(height, 129px);
        @include vw-convert-none(background-size, 177px 129px);
        background: url('../images/scalebody_img.svg') no-repeat center;

        @include vw-convert-mo() {
          @include vw-convert-none(width, 67.4px, mo);
          @include vw-convert-none(height, 57.4px, mo);
          @include vw-convert-none(background-size, 67.4px 57.4px, mo);
        }

        span {
          position: absolute;
          right: 0;
          left: 0;
          @include vw-convert-none(top, 17px);
          bottom: 0;
          margin: auto;
          @include vw-convert-none(width, 55.1px);
          @include vw-convert-none(height, 55.1px);
        }
      }
    }
  }

  &__text {
    margin-top:25px;
    padding:15px;
    @include vw-convert-none(font-size, 20px);
    text-align: center;
    background:#eef0f5;
    border-radius: 8px;

    >span {
      display: inline-block;
      @include vw-convert-none(margin-right, 3px);
      color: #55A3FF;
      @include vw-convert-none(font-size, 24px);
    }
  }

  &__select {
    @include vw-convert-none(margin-top, 28px);
    text-align: center;
    letter-spacing: -10px;

    li {
      display: inline-block;
      color: $unimportant-color;
      letter-spacing: 0px;
      border-style: solid;
      border-color: #ABB9C5;
      box-sizing: border-box;
      @include vw-convert-none(margin-right, 40px);
      @include vw-convert-none(width, 88px);
      @include vw-convert-none(height, 88px);
      @include vw-convert-none(border-width, 3px);
      @include vw-convert-none(border-radius, 10px);

      @include vw-convert-mo() {
        @include vw-convert-none(margin-right, 11.3px, mo);
        @include vw-convert-none(width, 30px, mo);
        @include vw-convert-none(height, 30px, mo);
        box-sizing: border-box;
        border-width: 2px;
        @include vw-convert-none(border-radius, 3px, mo);
        border-color: $unchk;
      }

      &.left {
        background: url('../images/bigsmall-default-left_icon.svg') no-repeat center;

        &.is-chk {
          background: url('../images/bigsmall-chk-left_icon.svg') no-repeat center;
          border-color: $chk;
          box-shadow: none;
        }

        &.is-true {
          background: url('../images/bigsmall-true-left_icon.svg') no-repeat center;
          border-color: $true;
          box-shadow: none;
        }

        &.is-reallytrue {
          background: url('../images/bigsmall-reallytrue-left_icon.svg') no-repeat center;
          border-color: $true;
          box-shadow: none;
        }

        &.is-false {
          background: url('../images/bigsmall-false-left_icon.svg') no-repeat center;
          border-color: $false;
          box-shadow: none;
        }
      }

      &.same {
        background: url('../images/bigsmall-default-same_icon.svg') no-repeat center;

        &.is-chk {
          background: url('../images/bigsmall-chk-same_icon.svg') no-repeat center;
          border-color: $chk;
          box-shadow: none;
        }

        &.is-true {
          background: url('../images/bigsmall-true-same_icon.svg') no-repeat center;
          border-color: $true;
          box-shadow: none;
        }

        &.is-reallytrue {
          background: url('../images/bigsmall-reallytrue-same_icon.svg') no-repeat center;
          border-color: $true;
          box-shadow: none;
        }

        &.is-false {
          background: url('../images/bigsmall-false-same_icon.svg') no-repeat center;
          border-color: $false;
          box-shadow: none;
        }
      }

      &.unknown {
        background: url('../images/bigsmall-default-unknown_icon.svg') no-repeat center;

        &.is-chk {
          background: url('../images/bigsmall-chk-unknown_icon.svg') no-repeat center;
          border-color: $chk;
          box-shadow: none;
        }

        &.is-true {
          background: url('../images/bigsmall-true-unknown_icon.svg') no-repeat center;
          border-color: $true;
          box-shadow: none;
        }

        &.is-reallytrue {
          background: url('../images/bigsmall-reallytrue-unknown_icon.svg') no-repeat center;
          border-color: $true;
          box-shadow: none;
        }

        &.is-false {
          background: url('../images/bigsmall-false-unknown_icon.svg') no-repeat center;
          border-color: $false;
          box-shadow: none;
        }
      }

      &.right {
        background: url('../images/bigsmall-default-right_icon.svg') no-repeat center;

        &.is-chk {
          background: url('../images/bigsmall-chk-right_icon.svg') no-repeat center;
          border-color: $chk;
          box-shadow: none;
        }

        &.is-true {
          background: url('../images/bigsmall-true-right_icon.svg') no-repeat center;
          border-color: $true;
          box-shadow: none;
        }

        &.is-reallytrue {
          background: url('../images/bigsmall-reallytrue-right_icon.svg') no-repeat center;
          border-color: $true;
          box-shadow: none;
        }

        &.is-false {
          background: url('../images/bigsmall-false-right_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 17.6px 17.6px, mo);
          border-color: $false;
          box-shadow: none;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.t-answer-imgpick {
  .imageContainer {
    max-width: 890px;
  }

  .selectableImageContainer {
    >.imageContainer {
      display: none;
    }
  }

  @include vw-convert-mo() {
    .quiz-content__answer-subtitle-num {
      display: inline-block;
      @include vw-convert-none(margin-right, 3px, mo);
    }
  }

  text-align: center;

  &__wrap {
    @include vw-convert-none(margin-top, 48px);
    @include vw-convert-none(margin-top, 14px, mo);

    .m-layer {
      display: block !important;
    }

    span {
      position: relative;

      &:before {
        position: absolute;
        border: 2px solid white;
        border-radius: 50%;
        top: 0;
        bottom: 0;
        margin: auto;
        @include vw-convert-none(left, -20px);
      }
    }

    >.imageContainer img {

      @include vw-convert-mo() {
        display: none;
        width: 100%;
      }
    }

    .is-chk {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
      outline: 1px dashed #333;
      box-sizing: border-box;
      @include vw-convert-none(outline-width, 4px);

      &:before {
        position: absolute;
        top: 0;
        @include vw-convert-none(left, -25px);
        bottom: 0;
        @include vw-convert-none(width, 32px);
        @include vw-convert-none(height, 32px);
        margin: auto;
        background: url('../images/check_icon.svg') no-repeat center;
        @include vw-convert-none(background-size, 32px 32px);
        content: '';

        @include vw-convert-mo() {
          @include vw-convert-none(width, 20px, mo);
          @include vw-convert-none(height, 20px, mo);
          @include vw-convert-none(left, -10px, mo);
          @include vw-convert-none(background-size, 20px 20px, mo);
        }
      }
    }

    .is-true {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
      outline: 1px dashed #333;
      box-sizing: border-box;
      @include vw-convert-none(outline-width, 4px);

      &:before {
        top: 0;
        @include vw-convert-none(left, -25px);
        bottom: 0;
        @include vw-convert-none(width, 32px);
        @include vw-convert-none(height, 32px);
        margin: auto;
        background: url('../images/right_icon.svg') no-repeat center;
        @include vw-convert-none(background-size, 32px 32px);
        content: '';

        @include vw-convert-mo() {
          @include vw-convert-none(width, 20px, mo);
          @include vw-convert-none(height, 20px, mo);
          @include vw-convert-none(left, -10px, mo);
          @include vw-convert-none(background-size, 20px 20px, mo);
        }
      }
    }

    .is-reallytrue {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
      outline: 1px dashed #333;
      box-sizing: border-box;
      @include vw-convert-none(outline-width, 4px);

      &:before {
        display: block;
        position: absolute;
        top: 0;
        @include vw-convert-none(left, -25px);
        bottom: 0;
        @include vw-convert-none(width, 40px);
        @include vw-convert-none(height, 40px);
        margin: auto;
        background: url('../images/right_icon.svg') no-repeat center;
        @include vw-convert-none(background-size, 40px 40px);
        content: '';

        @include vw-convert-mo() {
          @include vw-convert-none(width, 20px, mo);
          @include vw-convert-none(height, 20px, mo);
          @include vw-convert-none(left, -10px, mo);
          @include vw-convert-none(background-size, 20px 20px, mo);
        }
      }
    }

    .is-false {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
      outline: 1px dashed #333;
      box-sizing: border-box;
      @include vw-convert-none(outline-width, 4px);

      &:before {
        top: 0;
        @include vw-convert-none(left, -20px);
        bottom: 0;
        @include vw-convert-none(width, 32px);
        @include vw-convert-none(height, 32px);
        margin: auto;
        background: url('../images/close_icon.svg') no-repeat center;
        @include vw-convert-none(background-size, 32px 32px);
        content: '';

        @include vw-convert-mo() {
          @include vw-convert-none(width, 20px, mo);
          @include vw-convert-none(height, 20px, mo);
          @include vw-convert-none(left, -10px, mo);
          @include vw-convert-none(background-size, 20px 20px, mo);
        }
      }
    }
  }

  &.is-true {}

  &.is-reallytrue {}

  &.is-false {}

  .m-layer {
    @include vw-convert-mo() {
      @include absolute-full();
      position: relative;
      // display: none;
    }

    img {
      width: auto;
    }

    .m-layer-close {
      display: none;

      @include vw-convert-mo() {}
    }

    .m-layer-wrap-inner {
      position: relative;
      display: inline-block;

      @include vw-convert-mo() {
        // width:max-content;
      }
    }

    &.is-on {
      position: static;
      background: none;
      width: auto;
      overflow: auto;

      @include vw-convert-mo() {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        background: rgba(0, 0, 0, 0.7);
        z-index: 100;
        width: 100vw;
        overflow: scroll;
      }

      &:before {
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        content: '';
      }

      .m-layer-wrap {
        // width: 100%;
        display: inline-block;
        border: none;
        right: 0;
        top: 0;

        .imageContainer {
          margin-bottom: 0;
        }

        @include vw-convert-mo() {
          // position: relative;
          position: absolute;
          left: 0;
          bottom: 0;
          margin: auto;
          @include vw-convert-none(padding, 10px 0px, mo);
          width: 150%;
          height: max-content;
          border-right: 100px solid transparent;
          @include vw-convert-none(border-right-width, 20px, mo);
          border-left: 100px solid transparent;
          @include vw-convert-none(border-left-width, 20px, mo);
          background: white;
          z-index: -1;
          @include vw-convert-none(margin-left, -20px, mo);
        }

        .imageContainer {
          // width: 100%;
          display: inline-block;
          position: relative;
          margin-bottom: 0;
        }

        .m-layer-close {
          display: block;
          cursor: pointer;
          color: white;

          @include vw-convert-mo() {
            position: fixed;
            right: 0;
            @include vw-convert-none(font-size, 12px, mo);
            left: 0;
            bottom: 5vh;
            text-shadow: 2px 2px 6px black;
          }
        }
      }
    }

  }

  .m-image-select-btn {
    display: none;

    @include vw-convert-mo() {
      position: relative;
      display: block;
      width: 100%;
      color: white;
      background: $purple;
      border-radius: 10vw;
    }
  }
}

.t-answer-inlinemc {
  &__wrap {
    text-align: center;
    margin: 0 auto;
    @include vw-convert-none(margin-top, 48px);
    @include vw-convert-none(max-width, 900px);
    text-align: left;

    &:first-child {
      @include vw-convert-none(margin-top, 14px);
    }
  }

  &__content {
    letter-spacing: -10px;
    @include vw-convert-none(margin-top, 39px);
    text-align: center;
    cursor: pointer;

    li {
      position: relative;
      letter-spacing: 0px;
      display: inline-block;
      @include vw-convert-none(width, 110px);
      @include vw-convert-none(height, 110px);
      @include vw-convert-none(margin-top, 20px);
      @include vw-convert-none(border-radius, 10px);
      border: 3px solid #ccc;
      @include vw-convert-none(border-width, 3px);
      box-shadow: 0 5px 0 0 rgba(204, 204, 204, 0.5);
      @include vw-convert-none(margin-right, 43px);
      @include auto-count(10);

      @include vw-convert-mo() {
        @include vw-convert-none(width, 32px, mo);
        @include vw-convert-none(height, 39px, mo);
        @include vw-convert-none(margin-right, 8.5px, mo);
        box-sizing: border-box;
        border-width:1px;
        @include vw-convert-none(border-radius, 2.5px, mo);
      }

      &:last-child {
        margin-right: 0;
      }

      &:before {
        position: absolute;
        @include vw-convert-none(width, 40px);
        @include vw-convert-none(height, 40px);
        @include vw-convert-none(line-height, 40px);
        background: $unchk;
        border-radius: 50%;
        @include vw-convert-none(top, -20px);
        @include vw-convert-none(left, -20px);
        color: white;
        @include vw-convert-none(font-size, 24px);
        text-align: center;
        content: '';

        @include vw-convert-mo() {
          content: none !important;
        }
      }

      &:after {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        content: '';
      }

      >span {
        display: inline-block;
        vertical-align: middle;
        @include vw-convert-none(font-size, 24px);
        color: #ccc;
      }

      &.is-chk {
        border-color: $chk;
        box-shadow: none;

        &:before {
          @include vw-convert-none(width, 32px);
          @include vw-convert-none(height, 32px);
          background: url('../images/check_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 32px 32px);
          content: '';
        }

        span {
          color: $chk;
        }
      }

      &.is-true {
        border-color: $true;
        box-shadow: none;

        &:before {
          @include vw-convert-none(width, 32px);
          @include vw-convert-none(height, 32px);
          background: url('../images/right_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 32px 32px);
          content: '';
        }

        span {
          color: $true;
        }
      }

      &.is-reallytrue {
        background: $true;
        box-shadow: none;
        border-color: $true;

        &:before {
          @include vw-convert-none(width, 32px);
          @include vw-convert-none(height, 32px);
          background: url('../images/right_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 32px 32px);
          border: 1px solid white;
          content: '';
        }

        span {
          color: white;
        }
      }

      &.is-false {
        border-color: $false;
        box-shadow: none;

        &:before {
          background: url('../images/close_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 44px 44px);
          @include vw-convert-none(width, 40px);
          @include vw-convert-none(height, 40px);
          border: 1px solid white;
          content: '';
        }

        span {
          color: $false;
        }
      }
    }
  }
}

.t-answer-ordering {
  margin: 0 auto;

  &.type-equl {
    .t-answer-ordering-box {
      li {
        vertical-align: top;
        @include vw-convert-none(width, 215px);
        @include vw-convert-none(height, 197px);
        // background: url('components/imgs/box2_img.svg') no-repeat center;
        @include vw-convert-none(background-size, 215px 197px);
        @include vw-convert-none(background, none, mo);

        .choice-box {
          @include vw-convert-none(width, 215px);
          @include vw-convert-none(height, 197px);
          // background: url('components/imgs/box2_img.svg') no-repeat center;
          @include vw-convert-none(background-size, 215px 197px);
        }

        &.is-chk {
          .choice-box {
            color: white;
            @include vw-convert-none(width, 215px);
            @include vw-convert-none(height, 197px);
            // background: url('components/imgs/bluebox_img2.svg') no-repeat center;
            @include vw-convert-none(background-size, 215px 197px);
            @include vw-convert-none(background, none, mo);

            @include vw-convert-mo() {
              color: $chk;
              border-color: $chk;
            }
          }
        }
      }
    }
    .t-answer-ordering-box-print {
      li {
        vertical-align: top;
        @include vw-convert-none(width, 150px);
        @include vw-convert-none(height, 197px);
        // background: url('components/imgs/box2_img.svg') no-repeat center;
        @include vw-convert-none(background-size, 150px 197px);
        @include vw-convert-none(background, none, mo);

        .choice-box {
          @include vw-convert-none(width, 150px);
          @include vw-convert-none(height, 197px);
          // background: url('components/imgs/box2_img.svg') no-repeat center;
          @include vw-convert-none(background-size, 150px 197px);
        }

        &.is-chk {
          .choice-box {
            color: white;
            @include vw-convert-none(width, 150px);
            @include vw-convert-none(height, 197px);
            // background: url('components/imgs/bluebox_img2.svg') no-repeat center;
            @include vw-convert-none(background-size, 150px 197px);
            @include vw-convert-none(background, none, mo);

            @include vw-convert-mo() {
              color: $chk;
              border-color: $chk;
            }
          }
        }
      }
    }
  }

  &-box {
    display: table;
    width: 102%;

    li {
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      position: relative;
      display: inline-block;

      text-align: center;
      vertical-align: bottom;

      align-content: center;
      @include vw-convert-none(margin-right, 16px);

      cursor: pointer;

      @include vw-convert-mo() {
        display: block;
        text-align: left;
        // @include vw-convert-none(width, 160px, mo);
        width:100%;
        border-color: #ccc;
        @include vw-convert-none(border-width, 2.5px, mo);
        @include auto-count(10);
        @include vw-convert-none(margin-top, 9px, mo);

        &:before {
          @include vw-convert-none(width, 16px, mo);
          @include vw-convert-none(height, 16px, mo);
          @include vw-convert-none(line-height, 16px, mo);
          background: #b2b2b2;
          border-radius: 50%;
          color: white;
          @include vw-convert-none(margin, 4px 6px 0px 0px, mo);
          vertical-align: top !important;
        }

        &.is-reallytrue {
          &:before {
            background: $true;
            color: white;
          }

          span {
            border-color: $true;
            background: $true !important;
            color: white;
          }
        }
      }

      &.is-chk {
        .choice-box {
          position: absolute !important;
          left: 0 !important;
          right: auto !important;

          // position: relative;
          // left:0!important;
          // right:0!important;
          // margin:auto!important;
          @include vw-convert-mo() {
            position: static !important;
          }

          >span {
            width: 100%;
            @include vw-convert-none(padding, 0px 15px);
            box-sizing: border-box;
          }
        }

        @include vw-convert-mo() {
          .choice-box {
            border-color: $chk;
            color: $chk !important;
          }

          &:before {
            @include vw-convert-none(width, 18px, mo);
            @include vw-convert-none(height, 18px, mo);
            // background: url('components/imgs/check_icon.svg') no-repeat center;
            @include vw-convert-none(background-size, 18px 18px, mo);
            content: '';
            // position: absolute;
            @include vw-convert-none(margin-right, 4px, mo);
            top: 0;
            bottom: 0;
            @include vw-convert-none(left, -8px, mo);
          }
        }
      }

      &.is-true {
        @include vw-convert-mo() {
          .choice-box {
            border-color: $true;
            color: $true !important;
          }

          &:before {
            @include vw-convert-none(width, 16px, mo);
            @include vw-convert-none(height, 16px, mo);
            background: url('../images/right_icon.svg') no-repeat center;
            @include vw-convert-none(background-size, 16px 16px, mo);
            content: '';
          }
        }
      }

      &.is-false {
        @include vw-convert-mo() {
          .choice-box {
            border-color: $false;
            color: $false !important;
          }

          &:before {
            @include vw-convert-none(width, 16px, mo);
            @include vw-convert-none(height, 16px, mo);
            background: url('../images/close_icon.svg') no-repeat center;
            @include vw-convert-none(background-size, 16px 16px, mo);
            content: '';
          }
        }
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.order-print-1 {
        @include vw-convert-none(width, 177px);
        @include vw-convert-none(height, 136px);

        .choice-box {
          @include vw-convert-none(width, 177px);
          @include vw-convert-none(height, 136px);
          background: url('../images/box1_img.svg') no-repeat center;
          // @include vw-convert-none(background-size, 215px 136px);
          background-size:contain;

          >span {
            @include vw-convert-none(width, 150px);
            @include vw-convert-none(height, 134px);
            @include vw-convert-none(padding, 0px 5px);
          }
        }

        .border-box {
          @include vw-convert-none(width, 177px);
          @include vw-convert-none(height, 136px);
          bottom: 0;
        }

        &.is-chk {
          .choice-box {
            color: white;
            @include vw-convert-none(width, 177px);
            @include vw-convert-none(height, 136px);
            background: url('../images/bluebox_img1.svg') no-repeat center;
            background-size: contain;
            // @include vw-convert-none(background-size, 215px 136px);

            @include vw-convert-mo() {
              &:before {
                @include vw-convert-none(width, 16px, mo);
                @include vw-convert-none(height, 16px, mo);
                // background: url('components/imgs/equl-bluebox_img1.svg') no-repeat center;
                @include vw-convert-none(background-size, 16px 16px, mo);
              }
            }
          }
        }
        &.is-true {
          .choice-box {
            color:$true;
            background:url('../images/box1_img-true.svg') no-repeat center;
            background-size:contain;
          }
        }
        &.is-false {
          .choice-box {
            color:$false;
            background:url('../images/box1_img-false.svg') no-repeat center;
            background-size:contain;
          }
        }
      }

      &.order-print-2 {
        @include vw-convert-none(width, 177px);
        @include vw-convert-none(height, 197px);

        .choice-box {
          @include vw-convert-none(width, 177px);
          @include vw-convert-none(height, 197px);
          background: url('../images/box2_img.svg') no-repeat center;
          @include vw-convert-none(background-size, 177px 197px);

          >span {
            @include vw-convert-none(width, 150px);
            @include vw-convert-none(height, 196px);
            @include vw-convert-none(padding, 0px 5px);
          }
        }

        .border-box {
          @include vw-convert-none(width, 177px);
          @include vw-convert-none(height, 197px);
          bottom: 0;
        }

        &.is-chk {
          .choice-box {
            color: white;
            @include vw-convert-none(width, 177px);
            @include vw-convert-none(height, 197px);
            background: url('../images/bluebox_img2.svg') no-repeat center;
            background-size: contain;
            // background: url('components/imgs/bluebox_img2.svg') no-repeat center;
            @include vw-convert-none(background-size, 177px 197px);
            // background-size: auto;
          }
        }
        &.is-true {
          .choice-box {
            color:$true;
            background:url('../images/box2_img-true.svg') no-repeat center;
            background-size:contain;
          }
        }
        &.is-false {
          .choice-box {
            color:$false;
            background:url('../images/box2_img-false.svg') no-repeat center;
            background-size:contain;
          }
        }
      }

      &.order-print-3 {
        @include vw-convert-none(width, 177px);
        @include vw-convert-none(height, 233px);

        .choice-box {
          @include vw-convert-none(width, 177px);
          @include vw-convert-none(height, 233px);
          background: url('../images/box3_img.svg') no-repeat center;
          // background: url('components/imgs/box3_img.svg') no-repeat center;
          @include vw-convert-none(background-size, 177px 233px);

          >span {
            @include vw-convert-none(width, 150px);
            @include vw-convert-none(height, 232px);
            @include vw-convert-none(padding, 0px 5px);
          }
        }

        .border-box {
          @include vw-convert-none(width, 177px);
          @include vw-convert-none(height, 233px);
          bottom: 0;
        }

        &.is-chk {
          .choice-box {
            color: white;
            @include vw-convert-none(width, 177px);
            @include vw-convert-none(height, 233px);
            background: url('../images/bluebox_img3.svg') no-repeat center;
            background-size: contain;
            // background: url('components/imgs/bluebox_img3.svg') no-repeat center;
            @include vw-convert-none(background-size, 177px 233px);
            // background-size: auto;
          }
        }
        &.is-true {
          .choice-box {
            color:$true;
            background:url('../images/box3_img-true.svg') no-repeat center;
            background-size:contain;
          }
        }
        &.is-false {
          .choice-box {
            color:$false;
            background:url('../images/box3_img-false.svg') no-repeat center;
            background-size:contain;
          }
        }
      }

      &.order-print-4 {
        @include vw-convert-none(width, 177px);
        @include vw-convert-none(height, 269px);

        .choice-box {
          @include vw-convert-none(width, 177px);
          @include vw-convert-none(height, 269px);
          background: url('../images/box4_img.svg') no-repeat center;
          // background: url('components/imgs/box4_img.svg') no-repeat center;
          @include vw-convert-none(background-size, 177px 269px);

          >span {
            @include vw-convert-none(width, 150px);
            @include vw-convert-none(height, 268px);
            @include vw-convert-none(padding, 0px 5px);
          }
        }

        .border-box {
          @include vw-convert-none(width, 177px);
          @include vw-convert-none(height, 269px);
          bottom: 0;
        }

        &.is-chk {
          .choice-box {
            color: white;
            @include vw-convert-none(width, 177px);
            @include vw-convert-none(height, 269px);
            background: url('../images/bluebox_img4.svg') no-repeat center;
            background-size: contain;
            // background: url('components/imgs/bluebox_img4.svg') no-repeat center;
            @include vw-convert-none(background-size, 170px 269px);
          }
        }
        &.is-true {
          .choice-box {
            color:$true;
            background:url('../images/box4_img-true.svg') no-repeat center;
            background-size:contain;
          }
        }
        &.is-false {
          .choice-box {
            color:$false;
            background:url('../images/box4_img-false.svg') no-repeat center;
            background-size:contain;
          }
        }
      }

      &.order-1 {
        @include vw-convert-none(width, 215px);
        @include vw-convert-none(height, 136px);

        .choice-box {
          @include vw-convert-none(width, 215px);
          @include vw-convert-none(height, 136px);
          background: url('../images/box1_img.svg') no-repeat center;
          // @include vw-convert-none(background-size, 215px 136px);
          background-size:contain;

          >span {
            @include vw-convert-none(width, 196px);
            @include vw-convert-none(height, 134px);
            @include vw-convert-none(padding, 0px 5px);
          }
        }

        .border-box {
          @include vw-convert-none(width, 215px);
          @include vw-convert-none(height, 136px);
          bottom: 0;
        }

        &.is-chk {
          .choice-box {
            color: white;
            @include vw-convert-none(width, 215px);
            @include vw-convert-none(height, 136px);
            background: url('../images/bluebox_img1.svg') no-repeat center;
            background-size: contain;
            // @include vw-convert-none(background-size, 215px 136px);

            @include vw-convert-mo() {
              &:before {
                @include vw-convert-none(width, 16px, mo);
                @include vw-convert-none(height, 16px, mo);
                // background: url('components/imgs/equl-bluebox_img1.svg') no-repeat center;
                @include vw-convert-none(background-size, 16px 16px, mo);
              }
            }
          }
        }
        &.is-true {
          .choice-box {
            color:$true;
            background:url('../images/box1_img-true.svg') no-repeat center;
            background-size:contain;
          }
        }
        &.is-false {
          .choice-box {
            color:$false;
            background:url('../images/box1_img-false.svg') no-repeat center;
            background-size:contain;
          }
        }
      }

      &.order-2 {
        @include vw-convert-none(width, 215px);
        @include vw-convert-none(height, 197px);

        .choice-box {
          @include vw-convert-none(width, 215px);
          @include vw-convert-none(height, 197px);
          background: url('../images/box2_img.svg') no-repeat center;
          @include vw-convert-none(background-size, 215px 197px);

          >span {
            @include vw-convert-none(width, 196px);
            @include vw-convert-none(height, 196px);
            @include vw-convert-none(padding, 0px 5px);
          }
        }

        .border-box {
          @include vw-convert-none(width, 215px);
          @include vw-convert-none(height, 197px);
          bottom: 0;
        }

        &.is-chk {
          .choice-box {
            color: white;
            @include vw-convert-none(width, 215px);
            @include vw-convert-none(height, 197px);
            background: url('../images/bluebox_img2.svg') no-repeat center;
            background-size: contain;
            // background: url('components/imgs/bluebox_img2.svg') no-repeat center;
            @include vw-convert-none(background-size, 215px 197px);
            // background-size: auto;
          }
        }
        &.is-true {
          .choice-box {
            color:$true;
            background:url('../images/box2_img-true.svg') no-repeat center;
            background-size:contain;
          }
        }
        &.is-false {
          .choice-box {
            color:$false;
            background:url('../images/box2_img-false.svg') no-repeat center;
            background-size:contain;
          }
        }
      }

      &.order-3 {
        @include vw-convert-none(width, 215px);
        @include vw-convert-none(height, 233px);

        .choice-box {
          @include vw-convert-none(width, 215px);
          @include vw-convert-none(height, 233px);
          background: url('../images/box3_img.svg') no-repeat center;
          // background: url('components/imgs/box3_img.svg') no-repeat center;
          @include vw-convert-none(background-size, 215px 233px);

          >span {
            @include vw-convert-none(width, 196px);
            @include vw-convert-none(height, 232px);
            @include vw-convert-none(padding, 0px 5px);
          }
        }

        .border-box {
          @include vw-convert-none(width, 215px);
          @include vw-convert-none(height, 233px);
          bottom: 0;
        }

        &.is-chk {
          .choice-box {
            color: white;
            @include vw-convert-none(width, 215px);
            @include vw-convert-none(height, 233px);
            background: url('../images/bluebox_img3.svg') no-repeat center;
            background-size: contain;
            // background: url('components/imgs/bluebox_img3.svg') no-repeat center;
            @include vw-convert-none(background-size, 215px 233px);
            // background-size: auto;
          }
        }
        &.is-true {
          .choice-box {
            color:$true;
            background:url('../images/box3_img-true.svg') no-repeat center;
            background-size:contain;
          }
        }
        &.is-false {
          .choice-box {
            color:$false;
            background:url('../images/box3_img-false.svg') no-repeat center;
            background-size:contain;
          }
        }
      }

      &.order-4 {
        @include vw-convert-none(width, 215px);
        @include vw-convert-none(height, 269px);

        .choice-box {
          @include vw-convert-none(width, 215px);
          @include vw-convert-none(height, 269px);
          background: url('../images/box4_img.svg') no-repeat center;
          // background: url('components/imgs/box4_img.svg') no-repeat center;
          @include vw-convert-none(background-size, 215px 269px);

          >span {
            @include vw-convert-none(width, 196px);
            @include vw-convert-none(height, 268px);
            @include vw-convert-none(padding, 0px 5px);
          }
        }

        .border-box {
          @include vw-convert-none(width, 215px);
          @include vw-convert-none(height, 269px);
          bottom: 0;
        }

        &.is-chk {
          .choice-box {
            color: white;
            @include vw-convert-none(width, 215px);
            @include vw-convert-none(height, 269px);
            background: url('../images/bluebox_img4.svg') no-repeat center;
            background-size: contain;
            // background: url('components/imgs/bluebox_img4.svg') no-repeat center;
            @include vw-convert-none(background-size, 215px 269px);
          }
        }
        &.is-true {
          .choice-box {
            color:$true;
            background:url('../images/box4_img-true.svg') no-repeat center;
            background-size:contain;
          }
        }
        &.is-false {
          .choice-box {
            color:$false;
            background:url('../images/box4_img-false.svg') no-repeat center;
            background-size:contain;
          }
        }
      }

      .choice-box {
        position: absolute;
        top: auto;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        z-index: 2;
        display: inline-block;
        background: $unimportant-bg;
        @include vw-convert-none(font-size, 24px);
        color: $unimportant-color;

        &:before {
          display: inline-block;
          vertical-align: middle;
          height: 100%;
          content: '';
        }

        >span {
          position: absolute;
          display: table;
          right: 0;
          left: 0;
          top: 0;
          text-align: center;
          @include vertical-center;
          >span {
            display: table-cell;
            vertical-align: middle;
          }
          .imageContainer {
            display: inline-block;
            vertical-align: middle;
          }
        }

        @include vw-convert-mo() {
          position: static;
          @include vw-convert-none(width, 110px, mo);
          @include vw-convert-none(padding, 4px, mo);
          @include vw-convert-none(font-size, 10px, mo);
          @include vw-convert-none(border-radius, 2.5px, mo);
          @include vw-convert-none(border-width, 1.5px, mo);
          border-color: #ccc;
          border-style: solid;
          background: none !important;
          box-sizing: border-box;

          &:before {
            content: none;
          }

          >span {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0;
          }

          >img {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0 auto;
          }
        }
      }

      .border-box {
        position: absolute;
        top: auto;
        right: 0;
        left: 0;
        @include vw-convert-none(bottom, 26px);
        margin: auto;
        @include vw-convert-none(width, 200px);
        @include vw-convert-none(height, 200px);
        background: rgba(229, 229, 229, 0.5);
        border: 2px dashed #b2b2b2;
        box-sizing: border-box;
        font-size: 0;
      }
    }
  }

  &-order {
    display: table;
    width: 200px;
    @include vw-convert-none(margin-top, 32px);

    li {
      display: table-cell;
      text-align: center;
      min-width: 190px;

      span {
        display: inline-block;
        @include vw-convert-none(min-width, 70px);
        @include vw-convert-none(min-height, 32px);
        @include vw-convert-none(line-height, 32px);
        border: 1px solid transparent;
        border-radius: 10vw;
        background:#333a53;
        color: white;
        @include vw-convert-none(font-size, 16px);
      }

      &.is-chk {
        span {
          border-color: $chk;
          color: $chk;
        }
      }

      &.is-true {
        span {
          background: $true;
          // color: $true;
        }
      }

      &.is-reallytrue {
        span {
          border-color: $true;
          background: $true;
          color: white;
        }
      }

      &.is-false {
        span {
          background: $false;
          // color: $false;
        }
      }
    }
  }

  &-arrow {
    @include vw-convert-mo() {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;

      &-wrap {
        position: absolute;
        height: max-content;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }

      span {
        display: block;
        @include vw-convert-none(width, 16px, mo);
        @include vw-convert-none(height, 16px, mo);
      }

      .top-arrow {
        // background: url('components/imgs/ordering-control_top_btn.svg') no-repeat center;
        @include vw-convert-none(background-size, 16px 16px, mo);
      }

      .bottom-arrow {
        @include vw-convert-none(margin-top, 3px, mo);
        // background: url('components/imgs/ordering-control_bottom_btn.svg') no-repeat center;
        @include vw-convert-none(background-size, 16px 16px, mo);
      }
    }
  }
}

.t-answer-paring {
  width: 100%;
  @include vw-convert(max-width, 840px);
  margin: 0 auto;
  @include vw-convert(margin-top, 30px);

  &__pair {
    display: none;

    @include vw-convert-mo() {
      display: block;
      position: relative;
      @include vw-convert-none(border-radius, 2.5px, mo);
      box-sizing: border-box;
      @include vw-convert-none(min-width, 154px, mo);
      @include vw-convert-none(height, 137px, mo);
      @include vw-convert-none(margin-bottom, 10px, mo);

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:after {
      @include vw-convert-mo() {
        position: absolute;
        @include vw-convert-none(background-size, 16px 16px, mo);
        @include vw-convert-none(width, 16px, mo);
        @include vw-convert-none(height, 16px, mo);
        @include vw-convert-none(right, 7px, mo);
        @include vw-convert-none(top, 7px, mo);
      }
    }

    &.is-chk {
      border: 3px solid $chk;

      &:after {
        background: url('../images/check_icon.svg') no-repeat center;
        @include vw-convert-none(background-size, 32px 32px);
        border-radius: 50%;
        content: '';
      }
    }

    &.is-true {
      border: 3px solid $true;

      @include vw-convert-mo() {
        .box p>span {
          color: $true;
        }
      }

      &:after {
        background: url('../images/right_icon.svg') no-repeat center;
        @include vw-convert-none(background-size, 32px 32px);
        border-radius: 50%;
        content: '';
      }
    }

    &.is-false {
      border: 3px solid $false;

      &:after {
        background: url('../images/close_icon.svg') no-repeat center;
        @include vw-convert-none(background-size, 32px 32px);
        border-radius: 50%;
        content: '';
      }
    }

    &.is-reallytrue {
      @include vw-convert-mo() {
        border: 3px solid $true;

        p {
          color: white;
          display: inline-block !important;
          @include vw-convert-none(border-radius, 210px, mo);
          @include vw-convert-none(padding, 3px 5px, mo);
          background: $true;
        }
      }

      &:after {
        background: url('../images/right_icon.svg') no-repeat center;
        @include vw-convert-none(background-size, 32px 32px);
        border-radius: 50%;
        content: '';
      }

      // &:after {
      background: url('../images/close_icon.svg') no-repeat center;
      //   @include vw-convert-none(background-size, 40px 40px);
      //   border-radius: 50%;
      //   content: '';
      // }
    }

    .box {
      border: none !important;
      height: 50%;
      margin: 0;
      color: #474747;
      @include vw-convert-none(letter-spacing, -0.25px, mo);

      &:first-child {
        &:after {
          @include vw-convert-mo() {
            position: absolute;
            @include vw-convert-none(width, 130px, mo);
            @include vw-convert-none(height, 0.5px, mo);
            right: 0;
            left: 0;
            display: block;
            background: #d9d9d9;
            bottom: 0;
            margin: auto;
            content: '';
          }
        }
      }
    }
  }

  .box {
    @include vw-convert(padding, 10px 24px 10px 24px);
    word-break: keep-all;

    >p {
      @include vw-convert(font-size, 24px);
      @include vw-convert(line-height, 30px);
    }

    @include vw-convert-mo() {
      @include vw-convert-none(min-width, 154px, mo);
      @include vw-convert-none(height, 65px, mo);
      box-sizing: border-box;
      @include vw-convert-none(font-size, 10px, mo);
      @include vw-convert-none(border-radius, 2.5px, mo);
      @include vw-convert-none(margin-top, 7px, mo);
      @include vw-convert-none(padding, 4px 7.3px, mo);
      box-shadow: none !important;

      &:first-child {
        margin-top: 0;
      }

      img {
        @include vw-convert-none(max-width, 110px, mo);
        // height: vw-mo(55px);
      }
    }

  }

  &__left {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    @include vw-convert-none(width, 300px);
    @include vw-convert-mo(){
      display:block;
    }

    .box {
      box-sizing: border-box;

      &.is-true,
      &.is-false {
        &:after {
          content: none !important;

          @include vw-convert-mo() {
            content: '' !important;
          }
        }
      }
    }
  }
  .box-wrap {
    display: inline-block;
    // width:340px;
    @include vw-convert(width, 342px);
    vertical-align: top;
    @include vw-convert-mo {
      display: block;
    }
    > div {
      position: relative;
      @include vw-convert-mo {
        @include vw-convert(margin-bottom, 10px, mo);
      }
    }
    &.left {
      .dot-wrap {
        // margin:0px 10px 0px 20px;
        @include vw-convert(margin, 0px 10px 0px 16px);
      }
    }
    &.right {
      .dot-wrap {
        // margin:0px 20px 0px 10px;
        @include vw-convert(margin, 0px 16px 0px 10px);
      }
    }
  }
  .dot-wrap {
    display: inline-table;
    vertical-align: middle;
    .dot {
      @include vw-convert(width, 15px);
      @include vw-convert(height, 15px);
      background:black;
      border-radius: 50%;
    }
  }
  &__middle {
    width: 30%;
  }

  .pairingLinesArea {
    @include vw-convert(width, 156px);
    box-sizing: border-box;
  }

  &__right {
    @include vw-convert-none(width, 300px);
    display: inline-block;
    text-align: center;
    vertical-align: top;
    @include vw-convert-mo(){
      display:block;
    }

    .box {
      color: $unimportant-color;
      box-sizing: border-box;

      &:before {
        @include vw-convert-none(left, -42px);
      }

      &:first-child {
        margin-top: 0;
      }

      display: table;

      p {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  .box {
    display: inline-table;
    position: relative;
    @include vw-convert(width, 300px);
    @include vw-convert(min-height, 100px);
    @include vw-convert(max-height, 233px);
    @include vw-convert(height, 100px);
    @include vw-convert(margin-top, 16px);
    text-align: center;
    @include vw-convert(border-radius, 8px);
    border: 3px solid #ccc;
    box-shadow: 0 5px 0 0 rgba(204, 204, 204, 0.5);

    @include vw-convert-mo() {
      display: block;
    }

    &:before {
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      content: '';
    }

    >p {
      display: table-cell;
      vertical-align: middle;

      @include vw-convert-mo() {
        display: inline-block;
      }
    }

    >span {
      display: table-cell;
      vertical-align: middle;
    }

    img {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      @include vw-convert-none(max-height, 233px);

      @include vw-convert-mo() {
        height: 100%;
      }
    }

    &.is-chk {
      border-color: $chk;
      box-shadow: none;
      color: $chk;
      position: relative;

      &:after {
        position: absolute;
        @include vw-convert-none(left, -22px);
        top: 0;
        bottom: 0;
        margin: auto;
        // @include vw-convert-none(background-size, 40px 40px);
        // @include vw-convert-none(width, 40px);
        // @include vw-convert-none(height, 40px);
        border-radius: 50%;
        content: '';

        @include vw-convert-mo() {
          // background: url('components/imgs/check_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 16px 16px, mo);
          @include vw-convert-none(width, 16px, mo);
          @include vw-convert-none(height, 16px, mo);
          @include vw-convert-none(right, 7px, mo);
          @include vw-convert-none(top, 7px, mo);
          top: 0;
          bottom: 0;
          // bottom: auto;
        }
      }

      // &:before{
      //   background:$chk;
      //   @include vw-convert-none(width, 24px);
      //   @include vw-convert-none(height, 24px);
      // }
    }

    &.is-true {
      border-color: $true;
      box-shadow: none;
      color: $true;

      &:before {
        background: #006a42;
      }

      &:after {
        position: absolute;
        @include vw-convert-none(left, -20px);
        top: 0;
        right: auto;
        bottom: 0;
        margin: auto;
        @include vw-convert-none(width, 32px);
        @include vw-convert-none(height, 32px);
        background: url('../images/right_icon.svg') no-repeat center;

        @include vw-convert-none(background-size, 32px 32px);
        content: '';
      }
    }

    &.is-reallytrue {
      border-color: $true !important;
      background: $true;
      box-shadow: none;
      color: white !important;

      &:before {
        background: #006a42;
      }

      &:after {
        position: absolute;
        @include vw-convert-none(left, -22px);
        top: 0;
        right: auto;
        bottom: 0;
        margin: auto;
        @include vw-convert-none(width, 40px);
        @include vw-convert-none(height, 40px);
        background: url('../images/right_icon.svg') no-repeat center !important;
        @include vw-convert-none(background-size, 40px 40px);
        border: 2px solid white;
        border-radius: 50%;
        content: '';

        @include vw-convert-mo() {
          content: none;
        }
      }
    }

    &.is-false {
      border-color: $false;
      box-shadow: none;
      color: $false;

      &:before {
        background: #ac0202;
      }

      &:after {
        position: absolute;
        @include vw-convert-none(left, -22px);
        top: 0;
        right: auto;
        bottom: 0;
        margin: auto;
        @include vw-convert-none(width, 40px);
        @include vw-convert-none(height, 40px);
        background: url('../images/close_icon.svg') no-repeat center;
        @include vw-convert-none(background-size, 40px 40px);
        content: '';

        @include vw-convert-mo() {
          @include vw-convert-none(width, 16px, mo);
          @include vw-convert-none(height, 16px, mo);
          @include vw-convert-none(background-size, 16px 16px, mo);
        }
      }
    }

    &.is-can {
      border-color: $alert;
      box-shadow: none;

      color: $alert;

      &:before {
        background: $alert;
      }

      @include vw-convert-mo() {
        border-color: #e5e5e5;

        &:after {
          position: absolute;
          @include vw-convert-none(width, 16px, mo);
          @include vw-convert-none(height, 16px, mo);
          @include vw-convert-none(right, 7px, mo);
          @include vw-convert-none(top, 7px, mo);
          // background: url('components/imgs/cancheck_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 16px 16px, mo);
          border-radius: 50%;
        }
      }

    }

    &.is-cannot {
      opacity: 0.4;
    }
  }

}

.t-answer-area {
  @include vw-convert-mo() {
    .quiz-content__answer-subtitle {
      &:first-child {
        margin-top: 0;
      }

      // @include vw-convert-none(margin-top, 5px, mo);

      &-num {
        // @include vw-convert-none(margin-right, 5px, mo);
      }

      &-title {
        margin: 0;
      }
    }
  }

  &__rangebar {
    @include vw-convert-none(width, 848px);
    margin:0 auto;
    @include vw-convert-none(margin-top, 95px);

    // @include vw-convert-mo() {
    //   @include vw-convert-none(width, 154px, mo);
    //   @include vw-convert-none(margin-top, 34px, mo);
    // }
    td {
      position: relative;
      vertical-align: middle;

      &.rowbar {
        &.is-chk {
          button {
            position: relative;
            background: #6aaeff;
            box-shadow: 0 3px 0 0 #0053b9;
            overflow: hidden;

            &:after {
              position: absolute;
              bottom: 0;
              right: 0;
              left: 0;
              height: 50%;
              background: $chk;
              content: '';
            }
          }
        }

        &.is-true {
          button {
            position: relative;
            background: #36ce95;
            box-shadow: 0 3px 0 0 #006a42;
            overflow: hidden;
            &:after {
              position: absolute;
              bottom: 0;
              right: 0;
              left: 0;
              height: 50%;
              background: #04c17a;
              content: '';
            }
          }
        }

        &.is-false {
          button {
            position: relative;
            background: #ff7b7b;
            box-shadow: 0 3px 0 0 #ac0202;

            &:after {
              position: absolute;
              bottom: 0;
              right: 0;
              left: 0;
              height: 50%;
              background: $false;
              content: '';
            }
          }
        }
      }

      &.circlebar {
        &.is-chk {
          button {
            // background: transparent url('components/imgs/btn-check_icon.svg') no-repeat center;
            @include vw-convert-none(width, 48px);
            @include vw-convert-none(height, 48px);
            // background:$chk;
            @include vw-convert-none(background-size, 48px 44px);
            // @include vw-convert-none(background-size, 14px 14px, mo);
            // box-shadow: none;
            box-shadow: 0 5px 0 0 #0053b9 inset;
            background: #4599ff;

            // border:none;
            &:before {
              content: '';
              box-shadow: 0px 3px 0px 0px #0053b9;
            }
          }
        }

        &.is-true {
          button {
            // background: transparent url('components/imgs/btn-circle_icon.svg') no-repeat center;
            @include vw-convert-none(width, 48px);
            @include vw-convert-none(height, 48px);
            background: $true;
            @include vw-convert-none(background-size, 48px 44px);
            // @include vw-convert-none(background-size, 14px 14px, mo);
            box-shadow: none;

            &:before {
              content: none;
            }
          }
        }

        &.is-false {
          button {
            // background: transparent url('components/imgs/btn-close_icon.svg') no-repeat center;
            @include vw-convert-none(width, 48px);
            @include vw-convert-none(height, 48px);
            background: $false;
            @include vw-convert-none(background-size, 48px 44px);
            // @include vw-convert-none(background-size, 14px 14px, mo);
            box-shadow: none;

            &:before {
              content: none;
            }
          }
        }
      }
    }

    span {
      position: absolute;
      @include vw-convert-none(top, -48px);
      // @include vw-convert-none(top, -20px, mo);
      right: 0;
      left: 0;
      @include vw-convert-none(font-size, 32px);
      // @include vw-convert-none(font-size, 10px, mo);
      text-align: center;
      font-weight: 500;
      color: $unimportant-color;
      transform: scale(0.9);
    }

    .circlebar {
      @include vw-convert-none(width, 48.7px);
      @include vw-convert-none(height, 48.7px);
      // @include vw-convert-none(width, 14px, mo);
      // @include vw-convert-none(height, 14px, mo);
      transform: scale(1.1);
      z-index: 2;

      button {
        @include vw-convert-none(width, 48px);
        @include vw-convert-none(height, 48px);
        // @include vw-convert-none(width, 14px, mo);
        // @include vw-convert-none(height, 14px, mo);
        background: #E7EAF0;
        border: 3px solid white;
        border-radius: 50%;
        box-shadow: 0 5px 0 0 #cccccc inset;
        @include vw-convert-none(margin-bottom, 6px);

        &:before {
          position: absolute;
          right: 0;
          left: 0;
          @include vw-convert-none(top, -5px);
          bottom: 0;
          margin: auto;
          background: #fff;
          @include vw-convert-none(width, 18.5px);
          @include vw-convert-none(height, 18.5px);
          box-shadow: 0 3px 0 0 #cccccc;
          border-radius: 50%;
          content: '';
        }
      }
    }

    .rowbar {
      &:first-child {
        button {
          border-radius: 10vw 0 0 10vw;
        }
      }

      &:last-child {
        button {
          border-radius: 0 10vw 10vw 0;
        }
      }

      button {
        width: 100%;
        @include vw-convert-none(height, 28px);
        // @include vw-convert-none(height, 8px, mo);
        vertical-align: middle;
        background: #E7EAF0;
        // @include box-shadow(1px, #ccc, inset, mo);
        @include box-shadow(4px, #ccc, inset);
        transform: scale(1.3);
        z-index: 0;
      }
    }
  }
}

.t-answer-ts {
  @include vw-convert-none(font-size, 20px);
  color: $unimportant-color;
  @include vw-convert-none(line-height, 40px);
  $ts: &;
  // @media (min-width: 768+'px') and (max-width: (1500 - 1+'px')) {
  //   padding-bottom:200px;
  //   overflow-y: auto;
  // }

  &.textselect {
    @include vw-convert-none(padding-bottom, 120px);
  }

  .selected-text {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    border-radius: 8px;
    text-align: center;
    @include vw-convert-none(min-width, 36px);
    @include vw-convert-none(min-height, 32px);
    @include vw-convert-none(line-height, 30px);
    @include vw-convert-none(padding, 0px 22px);
    // background: url('components/imgs/text-select_pen_icon.svg') no-repeat center;
    // background: url('components/imgs/select_icon.svg') no-repeat center;
    @include vw-convert-none(background-size, 15.2px 24px);
    border: 2px solid #ccc;
    box-sizing: border-box;
    color: $unimportant-color;

    .katex {
      display: inline-block;
      @include vw-convert-none(padding, 4px 0px);
    }

    @include vw-convert-mo() {
      @include vw-convert-none(min-width, 20px, mo);
      height: 100%;
      @include vw-convert-none(padding, 0px 3px, mo);
    }
  }

  &.fill-blank {

    // hide up/down arrow
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    input {
      display: inline-block;
      width: 100%;
      border-radius: 8px;
      text-align: center;
      max-width: unset;
      @include vw-convert-none(margin, 0px 3px 0px 3px);
      @include vw-convert-none(min-width, 36px);
      // @include vw-convert-none(max-width, 128px);
      @include vw-convert-none(height, 32px);
      @include vw-convert-none(padding, 0px 5px 0px 5px);
      @include vw-convert-none(background-size, 22px 22px);
      box-sizing: border-box;
      color: $unimportant-color;
      border: 2px solid #ccc;

      &:valid {
        border: 2px solid $chk;

        @include vw-convert-mo() {
          background: white;
        }
      }

      @include vw-convert-mo() {
        @include vw-convert-none(min-width, 20px, mo);
        // max-width: vw-mo(37px);
        @include vw-convert-none(height, 16px, mo);
        margin: 0;
        background: #f6f6f6;
        border-color: #e5e5e5;
      }

      &::placeholder {
        color: #b2b2b2;
      }

      &.blank {
        &:hover {
          background: none;
        }

        &:focus {
          &::placeholder {
            background: none;
          }
        }

        &::placeholder {
          // color:red;
          // background:red;
          // background: url('components/imgs/text-select_pen_icon.svg') no-repeat top;
          background-position: center top;
          @include vw-convert-none(width, 12px, mo);
          @include vw-convert-none(padding-top, 1px, mo);
          @include vw-convert-none(height, 15px, mo);
          @include vw-convert-none(background-size, 12px 9px, mo);
          @include vw-convert-mo(){

          }
        }
      }
    }

    #{$ts}-input {
      #{$ts}-select {
        display: none;
      }

      @include vw-convert-mo() {
        &-wrap {
          &:after {
            content: none;
          }
        }
      }
    }

    .t-calc {
      @include vw-convert-mo() {
        display: block;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 21;

        &__wrap {
          @include vw-convert-none(height, 61px, mo);
          @include vw-convert-none(padding, 11.6px 5px 12.5px 5px, mo);
          box-sizing: border-box;
          @include clearfix();
        }

        &__num {
          float: left;
          @include vw-convert-none(width, 129px, mo);

          button {
            @include vw-convert-none(width, 16px, mo);
            @include vw-convert-none(height, 16px, mo);
            @include vw-convert-none(margin-right, 5.4px, mo);
            text-align: center;

            &:nth-child(3n+1) {
              margin-left: 0;
            }

            &:nth-child(6n) {
              margin-right: 0;
            }

            &:nth-child(n+7):nth-child(n) {
              @include vw-convert-none(margin-top, 5.4px, mo);
            }
          }
        }

        &__submit {
          float: left;
          @include vw-convert-none(width, 36px, mo);

          button {
            width: 100%;
            @include vw-convert-none(border-radius, 10px, mo);
            @include vw-convert-none(border-radius, 10px, mo);
            @include vw-convert-none(height, 16px, mo);
            @include vw-convert-none(font-size, 8px, mo);
          }

          &-ok {
            @include vw-convert-none(margin-top, 4.4px, mo);
          }
        }
      }
    }

    .problem-frac {
      &-wrap {
        @include vw-convert-mo() {
          @include vw-convert-none(margin, 2px 0px, mo);
        }
      }

      &-numerator {
        span {
          display: inline-block;
          // line-height: vw-mo(5px);
          // line-height: 24px;
          vertical-align: middle;
        }

        .t-answer-ts-input {
          height: 100%;
          @include vw-convert-mo(){
            margin:0;
          }

          &-wrap,
          input {
            height: 100%;
            margin: 0;
          }
        }
      }

      &-denominator {
        span {
          display: inline-block;
          @include vw-convert-none(line-height, 3px, mo);
          // line-height: 20px;
          vertical-align: middle;
        }

        .t-answer-ts-input {
          height: 100%;

          &-wrap,
          input {
            height: 100%;
            margin: 0;
          }
        }
      }
    }
  }

  &-input {
    display: inline-block;
    position: relative;
    @include vw-convert-none(margin-right, 5px);
    @include vw-convert-none(margin-left, 5px);
    cursor: pointer;
    @include vw-convert-none(margin, 0px 2.5px 3px 2.5px, mo);

    &-wrap {
      position: relative;
      @include vw-convert-none(height, 32px);
      @include vw-convert-none(line-height, 34px);
      border-color: #ccc;
      @include vw-convert-none(border-width, 2px);
      border-style: solid;
      @include vw-convert-none(border-radius, 4px);
      vertical-align: middle;
      border: none;

      @include vw-convert-mo() {
        @include vw-convert-none(height, 16px, mo);
        @include vw-convert-none(line-height, 16px, mo);
      }

      // &:hover{
      //   +.t-answer-ts-select{
      //     padding-top
      //   }
      // }
      &.short-input {
        @include vw-convert-none(min-width, 45px);

        .selected-text {
          @include vw-convert-none(padding, 0px 6px);
        }
      }

      &:after {
        position: absolute;
        @include vw-convert-none(right, 3px, mo);
        @include vw-convert-none(bottom, 1px, mo);
        top: 0;
        @include vw-convert-none(width, 5px, mo);
        @include vw-convert-none(height, 5px, mo);
        margin: auto;
        // background: url('components/imgs/input_arrow.svg') no-repeat center;
        @include vw-convert-none(background-size, 5px 5px, mo);
        content: '';

        @include vw-convert-mo() {
          background: none;
        }
      }
    }

    &:hover {
      input.blank {
        border-color: $chk;
        // background: url('components/imgs/text-select_bluepen_icon.svg') no-repeat center;
        @include vw-convert-none(background-size, 22px 22px);
      }

      .none-event {
        input {
          border-color: inherit;
        }
      }

      .is-false,
      .is-true {
        &+.t-answer-ts-select {
          // display:none;
        }
      }
    }

    &.block {
      #{$ts}-select {
        display: block;
        position: absolute;
        height: 100%;
        transition: all 0.2s;
        opacity: 1;
        z-index: 1;
        top: 90%;
        @include vw-convert-none(padding-top, 10px);

        @include vw-convert-mo() {
          position: fixed;
          -webkit-transition: unset;
          transition: unset;
          top: auto;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: 22;
          opacity: 1;
          height: auto;
          background: #f0efeff4;
        }
      }
    }

    &-wrap {
      &.is-chk {

        input,
        .selected-text {
          background: none;
          border-color: $chk;
          color: $chk;
        }

        input::placeholder {
          color: $chk;
        }

        &:after {
          content: none;
        }
      }

      &.is-true {
        &:before {
          position: absolute;
          @include vw-convert-none(top, -7px);
          @include vw-convert-none(left, -7px);
          @include vw-convert-none(width, 24px);
          @include vw-convert-none(height, 24px);
          background: url('../images/right_icon-q.svg') no-repeat center;
          @include vw-convert-none(background-size, 24px 24px);
          border: 1px solid transparent;
          border-radius: 50%;
          content: '';

          @include vw-convert-mo() {
            content: none;
          }
        }

        input,
        .selected-text {
          border-color: $true;
          background: none;
          color: $true;
        }

        input::placeholder {
          color: $true;
        }

        input:valid {
          border-color: $true;
        }

        &:hover {
          +.t-answer-ts-select {
            display: none;
          }
        }

        &:after {
          content: none;
        }
      }

      &.is-reallytrue {
        &:before {
          position: absolute;
          @include vw-convert-none(top, -7px);
          @include vw-convert-none(left, -7px);
          @include vw-convert-none(width, 24px);
          @include vw-convert-none(height, 24px);
          background: url('../images/right_icon-q.svg') no-repeat center;
          @include vw-convert-none(background-size, 24px 24px);
          border: 1px solid white;
          border-radius: 50%;
          content: '';
        }

        &:after {
          content: none;
        }

        &:hover {
          +.t-answer-ts-select {
            display: none;
          }
        }

        input,
        .selected-text {
          border-color: $green;
          background: white;
          color: $green;
        }

        input::placeholder {
          color: $true;
        }

        input:valid {
          border: 2px solid $green;
        }
      }

      &.is-false {
        &:before {
          position: absolute;
          @include vw-convert-none(top, -7px);
          @include vw-convert-none(left, -7px);
          @include vw-convert-none(width, 24px);
          @include vw-convert-none(height, 24px);
          background: url('../images/false-icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 24px 24px);
          content: '';
        }

        input,
        .selected-text {
          border-color: $false;
          background: none;
          color: $false;
        }

        input:valid {
          border-color: $false;
        }

        input::placeholder {
          color: $false;
        }

        &:hover {
          +.t-answer-ts-select {
            display: none;
          }
        }

        &:after {
          content: none;
        }
      }
    }

    input {
      display: inline-block;
      width: 100%;
      border-radius: 8px;
      text-align: center;
      @include vw-convert-none(margin, 0px 3px 0px 3px);
      @include vw-convert-none(min-width, 36px);
      @include vw-convert-none(max-width, 128px);
      @include vw-convert-none(height, 32px);
      @include vw-convert-none(padding, 0px 5px 0px 5px);
      @include vw-convert-none(background-size, 22px 22px);
      border: 2px solid #ccc;
      box-sizing: border-box;
      color: $unimportant-color;

      @include vw-convert-mo() {
        @include vw-convert-none(min-width, 20px, mo);
        @include vw-convert-none(max-width, 37px, mo);
        @include vw-convert-none(height, 16px, mo);
        margin: 0;
        background: #f6f6f6;
        border-color: #e5e5e5;
      }

      &::placeholder {
        color: #b2b2b2;
      }

      &.blank {
        &:hover {
          background: none;
        }

        //???, ???
        &::placeholder {
          // background: url('components/imgs/text-select_pen_icon.svg') no-repeat center;
        }
      }
    }
  }

  &-select {
    display: none;
    position: absolute;
    opacity: 0;
    z-index: -1;
    height: 0;
    text-align: center;
    transition: all 0.2s;
    bottom: 0%;

    &.block {
      height: 100%;
      opacity: 1;
      z-index: 1;
      top: 90%;
      @include vw-convert-none(padding-top, 10px);

      @include vw-convert-mo() {
        height: auto;
        top: auto;
        padding: 0;
        z-index: 22;
      }
    }

    @include vw-convert-mo() {
      display: none;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 22;
      opacity: 1;
      height: auto;
      background: #f0efeff4;
    }

    &-title {
      display: none;

      @include vw-convert-mo() {
        display: block;
        @include vw-convert-none(height, 22.5px, mo);
        @include vw-convert-none(padding, 5px 7px, mo);
        box-sizing: border-box;
        background: #f6f6fb;
        @include clearfix();

        .arrow {
          float: left;

          span {
            cursor: pointer;
          }

          span:first-child {
            display: inline-block;
            height: -webkit-fill-available;
            @include vw-convert-none(width, 13.4px, mo);
            // background: url('components/imgs/picker_top_arrow.svg') no-repeat center;
            @include vw-convert-none(background-size, 6.7px 13.4px, mo);
          }

          span:last-child {
            display: inline-block;
            height: -webkit-fill-available;
            // @include vw-convert-none(height, 6.7px, mo);
            @include vw-convert-none(width, 13.4px, mo);
            // background: url('components/imgs/picker_bottom_arrow.svg') no-repeat center;
            @include vw-convert-none(background-size, 6.7px 13.4px, mo);
          }
        }

        .submit {
          float: right;
          color: $chk;
          cursor: pointer;
        }
      }
    }

    .list {
      li {
        white-space: nowrap;
        @include vw-convert-none(padding, 0px 15px);

        &.is-selected {
          @include vw-convert-mo() {
            color: #333;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
          }
        }
      }

      @include vw-convert-mo() {
        max-height: 27vh;
        overflow-y: auto;
      }
    }

    li {
      background: rgba(black, 0.5);
      color: white;
      @include vw-convert-none(line-height, 36px);

      @include vw-convert-mo() {
        background: #efeff4;
        color: #4c4c4c;
        @include vw-convert-none(font-size, 10px, mo);
        font-weight: 100;
        @include vw-convert-none(padding, 2px 0px, mo);
        @include vw-convert-none(line-height, 20px, mo);
        border: 1px solid transparent;
      }

      &:hover {
        background: $chk;
        transform: scale(1.1);

        @include vw-convert-mo() {
          background: #efeff4;
          transform: unset;
          color: #333;
          box-sizing: border-box;
          font-weight: 400;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
}

.t-bottom-answer {
  display: none;

  @include vw-convert-mo() {
    display: block;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 21;
    background: #f0efeff4;
  }

  &-title {
    @include vw-convert-mo() {
      background: #f6f6fb;
    }

    span {
      @include vw-convert-mo() {
        @include vw-convert-none(font-size, 8px, mo);
        color: $true;
      }
    }
  }
}

.t-answer-list-chk {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  //???? ?? ??
  @include vw-convert-none(max-width, 970px);
  @include vw-convert-none(width, 970px);
  margin: auto;
  @include vw-convert-none(height, 32px);
  background: #fff;
  @include vw-convert-none(border-radius, 8px);

  @include vw-convert-mo() {
    border-top-style: solid;
    @include vw-convert-none(border-top-width, 0.5px, mo);
    border-top-color: #e5e5e5;
  }

  &.is-fixed {
    position: fixed;
  }

  &-wrap {
    .slick-slide {
      margin-right: 16px;
      @include auto-slider-count(20);
    }

    .slick-arrow {
      z-index: 9999;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      background: #bebee2;
      color: white;
      @include vw-convert-none(width, 32px);
      @include vw-convert-none(height, 32px);
      border-radius: 50%;
      cursor: pointer;

      &.slick-disabled {
        display: none !important;
      }

      &:hover {
        background: #bebee2;
      }

      &.slick-prev {
        @include vw-convert-none(left, -45px);
        @include vw-convert-none(text-indent, -3px);

        &:before {
          @include vw-convert-none(font-size, 38px);
        }

        @include vw-convert-mo() {
          display: none;
        }
      }

      &.slick-next {
        @include vw-convert-none(right, -40px);
        @include vw-convert-none(text-indent, -2px);

        &:before {
          @include vw-convert-none(font-size, 38px);
        }

        @include vw-convert-mo() {
          display: none;
        }
      }

    }

    @include vw-convert-none(height, 24px);
    @include vw-convert-none(margin-top, 8px);
    background: #e3e3ff;
    width: 100%;
    @include vw-convert-none(border-radius, 8px);
    @include vw-convert-none(border-bottom-left-radius, 0px);
    @include vw-convert-none(border-bottom-right-radius, 0px);
    position: relative;

    @include vw-convert-mo() {
      @include vw-convert-none(padding, 4.5px 13px 2.5px 13px, mo);
      width: 100%;
      @include vw-convert-none(height, 25px, mo);
      background: #fff;
      box-sizing: border-box;
      overflow-y: hidden;
      box-sizing: border-box;
    }

    li {
      @include auto-count(30);
      @include vw-convert-none(margin-right, 10px, mo);
    }
  }

  &__inner {
    //???? ?? ??
    @include vw-convert-none(width, 486px);
    @include vw-convert-none(max-width, 970px);
    // margin: auto;
    right: 0;

    position: absolute;
    bottom: 8px;
    left: 24px;

    .is-list-over {
      @include vw-convert-none(width, 530px);
      position: relative;
      // cursor: e-resize;
      // &:after{
      //   position: absolute;
      //   @include vw-convert-none(width, 16px);
      //   // height:32px;
      //   // border-radius: 0;
      //   top:-1px;
      //   bottom:0;
      //   right:0;
      //   background-image:linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
      //   z-index: 3;
      //   box-shadow: none;
      //   content:'';
      // }
    }
  }

  &__list {
    // @include vw-convert-none(width, 496px);
    white-space: nowrap;
    padding-top: 4px;
    // overflow-y: hidden;
    // overflow-x:auto;
    // overflow:hidden;
    // &::-webkit-scrollbar {
    //   display: none;
    // }

    // -webkit-overflow-scrolling: touch;
    // @include vw-convert-none(position, static, mo);
    // @include vw-convert-none(width, max-content, mo);
    @include vw-convert-none(overflow, hidden, mo);

    .slick-slide {
      li {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        @include vw-convert-none(width, 32px);
        @include vw-convert-none(height, 32px);
        @include vw-convert-none(font-size, 16px);
        @include vw-convert-none(margin-right, 16px);
        text-align: center;
        background: #bebee2;
        color: white;
        border-radius: 50%;
        cursor: pointer;
        @include vw-convert-none(margin-bottom, 4px);

        @include vw-convert-mo() {
          width: vw-mo(16px) !important;
          height: vw-mo(16px) !important;
          @media (max-width: ($breakpoints-mobile +'px')) and (orientation: landscape){
              width:32px!important;
              height:32px!important;
          }
          @include vw-convert-none(font-size, 8px, mo);
        }

        &:before {
          vertical-align: sub;
          width: 32px;
          @include vw-convert-none(margin-top, 5px);
          @include vw-convert-none(margin-top, 3px, mo);
        }

        &:after {
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
          bottom: -2px;
          margin: auto;
          width: 100%;
          height: 100%;
          box-shadow: 0px 0px 0px 3px white;
          border-radius: 50%;
          // transition: all 1s;
          content: '';
        }


        // background:blue;
        &:last-child {
          margin-right: 0;
        }

        &.is-true {
          &:before {
            // background: url('components/imgs/right_icon.svg') no-repeat center;
            background: url('../images/right_icon.svg') no-repeat center;
            @include vw-convert-none(background-size, 30px 30px);
            @include vw-convert-none(width, 32px);
            @include vw-convert-none(height, 32px);
            border-radius: 50%;
            margin-top: -1px;
            content: '';
            @include vw-convert-none(background-size, 15px 15px, mo);

            @include vw-convert-mo() {
              @include vw-convert-none(width, 17px, mo);
              @include vw-convert-none(height, 17px, mo);
            }
          }
        }

        &.is-chk {
          &:before {
            // background: url('components/imgs/check_icon.svg') no-repeat center;
            @include vw-convert-none(background-size, 40px 40px);
            @include vw-convert-none(width, 32px);
            @include vw-convert-none(height, 32px);
            border-radius: 50%;
            margin-top: -1px;
            content: '';
            @include vw-convert-none(background-size, 17px 17px, mo);

            @include vw-convert-mo() {
              @include vw-convert-none(width, 17px, mo);
              @include vw-convert-none(height, 17px, mo);
            }
          }
        }

        &.is-reallytrue {}

        &.is-alert {
          background: $alert;
        }

        &.is-false {
          background: $false;

          &:before {
            background: url('../images/close_icon.svg') no-repeat center;
            @include vw-convert-none(background-size, 30px 30px);
            @include vw-convert-none(width, 32px);
            @include vw-convert-none(height, 32px);
            border-radius: 50%;
            margin-top: -1px;
            content: '';
            @include vw-convert-none(background-size, 15px 15px, mo);

            @include vw-convert-mo() {
              @include vw-convert-none(width, 16px, mo);
              @include vw-convert-none(height, 16px, mo);
            }
          }
        }

        &.right-arrow {
          &:before {
            content: '>';
          }
        }

        span {
          display: inline-block;
          vertical-align: middle;
          width: 1px;
          @include vw-convert-none(height, 10px, mo);
          height: 10px;
        }
      }
    }


  }

  &__solveresult {
    position: absolute;
    @include vw-convert-none(right, 24px);
    overflow: hidden;
    @include vw-convert-none(position, static, mo);
    bottom: 0;
    @include clearfix();
    @include vw-convert-none(display, none, mo);
  }

  &-btn {
    @include vw-convert-none(width, 148px);
    @include vw-convert-none(height, 48px);
    @include vw-convert-none(margin-bottom, 6px);
    @include vw-convert-none(font-size, 20px);
    transition: all 0.3s;
    @include vw-convert-none(border-top-left-radius, 8px);
    @include vw-convert-none(border-top-right-radius, 8px);
    @include vw-convert-none(margin-right, 8px);

    &:last-of-type {
      margin-right: 0;
    }

    &.is-active {
      background: #4599ff;
      box-shadow: 0 6px 0 0 #0053b9;
    }

    &:active {
      margin-bottom: 0;
      // box-shadow: 0 2px 0 0 #7f7f7f;
    }
  }
}

.t-progressbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  @include vw-convert-none(font-size, 14px);
  @include vw-convert-none(margin-bottom, 24px);
  @include vw-convert-none(padding-top, 7px);
  @include vw-convert-none(width, 670px);
  position: relative;
  color: white;
  margin: 0 auto;

  @include vw-convert-mo() {
    @include vw-convert-none(width, 154px, mo);
    @include vw-convert-none(font-size, 8px, mo);
    @include vw-convert-none(margin-bottom, 12.5px, mo);
    @include vw-convert-none(padding-top, 3px, mo);
  }

  &__grade {
    display: table;
    table-layout: fixed;
    width: 100%;
    @include vw-convert-none(height, 37px, mo);

    display: flex;
    align-items: center;
    justify-content: space-between;

    // &.is-triple{
    //   .t-progressbar__grade-content {
    //     &:nth-child(2){
    //       padding:0;
    //     }
    //   }
    // }
    &-content {
      position: relative;
      // display: table-cell;
      text-align: center;
      vertical-align: middle;
      // width
      z-index: 100;
      cursor: pointer;

      &:nth-child(2) {
        // @include vw-convert-none(padding-right, 30px);
      }

      &:nth-child(3) {
        // @include vw-convert-none(padding-left, 30px);
      }

      &.is-point {
        @include vw-convert-none(padding-bottom, 10px);

        strong {
          opacity: 1;
          @include vw-convert-none(font-size, 16px);
        }

        .img {
          position: relative;
          // @include stat(position, absolute, mo);
          @include vw-convert-none(width, 32px);
          @include vw-convert-none(height, 32px);
          @include vw-convert-none(margin-top, 8px);
          background-size: contain !important;

          @include vw-convert-mo() {
            position: absolute;
            @include vw-convert-none(width, 19px, mo);
            @include vw-convert-none(height, 19px, mo);
            // width: vw-mo(19px);
            // height: vw-mo(19px);
            top: 0;
          }

          &:after {
            position: absolute;
            @include vw-convert-none(left, -6px);
            @include vw-convert-none(top, -6px);
            width: 100%;
            height: 100%;
            @include vw-convert-none(border-width, 6px);
            @include vw-convert-none(border-width, 3px, mo);
            @include vw-convert-none(left, -3px, mo);
            @include vw-convert-none(top, -3px, mo);
            border-color: $chk;
            border-style: solid;
            border-radius: 50%;
            opacity: 0.3;
            content: '';
          }
        }
      }

      &.is-empty {
        @include vw-convert-none(padding-bottom, 2px);
      }

      &:first-child {
        text-align: left;

        .img {
          // background: url('components/imgs/purpplestep_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 24px 24px);
          @include vw-convert-none(background-size, 11px 11px, mo);
        }
      }

      &:nth-child(2) {
        .img {
          // background: url('components/imgs/bluestep_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 24px 24px);
          @include vw-convert-none(background-size, 11px 11px, mo);
        }
      }

      &:nth-child(3) {
        .img {
          // background: url('components/imgs/bluestep_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 24px 24px);
          @include vw-convert-none(background-size, 11px 11px, mo);
        }
      }

      &:last-child {
        text-align: right;
      }

      &.small-star {
        .img {
          // background: url('components/imgs/purpplestep_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 24px 24px);
          @include vw-convert-none(background-size, 11px 11px, mo);
        }
      }

      &.is-empty {
        .img {
          // background: url('components/imgs/emptystep_icon.png') no-repeat center;
          @include vw-convert-none(background-size, 24px 24px);
          @include vw-convert-none(background-size, 11px 11px, mo);
        }
      }
    }

    &-inner {
      display: inline-block;
      @include vw-convert-none(margin-top, 24px, mo);

      strong {
        opacity: 0.7;
        font-weight: normal;
      }

      .img {
        display: block;
        position: relative;
        @include vw-convert-none(width, 24px);
        @include vw-convert-none(width, 11px, mo);
        @include vw-convert-none(height, 24px);
        @include vw-convert-none(height, 11px, mo);
        margin: 0 auto;
        @include vw-convert-none(margin-top, 4px);
        @include vw-convert-none(background-size, 24px 24px);
        z-index: 15;

        @include vw-convert-mo() {
          position: absolute;
          @include vw-convert-none(top, 5px, mo);
          right: 0;
          left: 0;
          margin: auto;
        }
      }
    }
  }

  &__status {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    @include vw-convert-none(bottom, -30px);
    @include vw-convert-none(width, 611px);
    @include vw-convert-none(height, 8px);
    background-color: rgba(255, 255, 255, 0.3);
    margin: auto;
    z-index: 10;

    @include vw-convert-mo() {
      @include vw-convert-none(width, 125px, mo);
      @include vw-convert-none(height, 4px, mo);
      @include vw-convert-none(top, -9px, mo);
    }

    &-stat {
      position: absolute;
      right: 0;
      left: 0;
      height: 100%;
      opacity: 0.8;
      background-image: linear-gradient(to right, #7273ff, #00d3ff);
    }
  }
}

.l-learning-progress {
  @include vw-convert-none(margin-top, 53px);
  @include vw-convert-none(width, 386px);

  strong {
    color: #4c4c4c;
    @include vw-convert-none(font-size, 10px, mo);
  }

  .progressbar {
    @include vw-convert-none(width, 386px);
    @include vw-convert-none(width, 154px, mo);
    @include vw-convert-none(height, 20px);
    @include vw-convert-none(height, 10px, mo);
    @include vw-convert-none(margin-top, 4px);
    @include vw-convert-none(margin-top, 4.5px, mo);
    border-radius: 10vw;
    background: #e5e5e5;
    box-shadow: 0 2px 0 0 #ccc inset;
    overflow: hidden;

    &-cover {
      width: 1%;
      // @include vw-convert-none(height, 20px);
      // @include vw-convert-none(height, 10px, mo);
      transition: all 2s;
      height: 100%;
      border-radius: 10vw;
      background-image: linear-gradient(269deg, #7273ff, #00d3ff);
    }
  }

  .percent {
    float: right;
    @include vw-convert-none(font-size, 20px);
    @include vw-convert-none(font-size, 10px, mo);
    color: $unchk;
  }
}

.l-learning-tag {
  @include vw-convert-none(margin-top, 66px);

  strong {
    display: block;
    @include vw-convert-none(margin-bottom, 10px);
    @include vw-convert-none(margin-bottom, 5px, mo);
    @include vw-convert-none(font-size, 10px, mo);

    &:before {
      display: inline-block;
      vertical-align: middle;
      @include vw-convert-none(margin-right, 8px);
      @include vw-convert-none(width, 17.4px);
      @include vw-convert-none(height, 17.4px);
      // background: url('components/imgs/pencil_icon.svg') no-repeat center;
      @include vw-convert-none(background-size, 17.4px 17.4px);
      content: '';
    }
  }

  >span {
    position: relative;
    display: inline-block;
    @include vw-convert-none(margin-right, 8px);
    @include vw-convert-none(font-size, 16px);
    @include vw-convert-none(font-size, 8px, mo);
    color: $unimportant-color;
    font-weight: 500;

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      @include vw-convert-none(height, 11px);
      height: 50%;
      background: #00d3ff;
      opacity: 0.2;
      content: '';
    }
  }
}

.l-full-layer {
  position: relative;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  @include vw-convert-none(max-width, 970px);
  @include vw-convert-none(margin-top, 24px);
  @include vw-convert-none(padding, 45px 25px 75px 25px);
  @include vw-convert-none(border-radius, 8px);
  @include vw-convert-none(font-size, 20px);
  min-height: 85vh;
  @include clearfix();

  @include vw-convert-mo() {
    @include vw-convert-none(padding, 14px 11.5px 22.5px 11.5px, mo);
    @include clearfix();
  }

  &__title {
    font-family: 'Roboto', 'HGSoftGGothicssi';
    float: left;
    @include vw-convert-none(font-size, 32px);
    @include vw-convert-none(margin-bottom, 34px);
    color: #333;
    letter-spacing: -0.8px;
    font-weight: 500;

    @include vw-convert-mo() {
      display: block;
      float: none;
      @include vw-convert-none(font-size, 12px, mo);
      @include vw-convert-none(margin-bottom, 10px, mo);
      @include vw-convert-none(padding-bottom, 6px, mo);
      border-bottom: 2px solid black;
      font-weight: 600;
      @include vw-convert-none(border-width, 1px, mo);
    }
  }

  &__input {
    float: right;

    &-search {
      position: relative;
      display: inline-block;
      @include vw-convert-none(width, 405px);
      @include vw-convert-none(height, 40px);
      @include vw-convert-none(margin-left, 14px);
      vertical-align: top;
      border: 1px solid #e0e0e0;
      @include vw-convert-none(border-radius, 30px);
      @include vw-convert-none(font-size, 16px);
      box-sizing: border-box;

      @include vw-convert-mo() {
        width: 100%;
        border-radius: 10vw;
        overflow: hidden;
        @include vw-convert-none(height, 20px, mo);
        @include vw-convert-none(margin-top, 4px, mo);
        @include vw-convert-none(font-size, 7px, mo);
        color: #777;
      }

      input {
        width: 100%;
        height: 100%;
        @include vw-convert-none(padding, 8px 135px 8px 20px);
        box-sizing: border-box;
        @include vw-convert-none(border-radius, 30px);
        border: none;
        color: #4c4c4c;

        @include vw-convert-mo() {
          @include vw-convert-none(padding, 5px 20px 5px 9px, mo);
        }

        &::placeholder {
          color: #7f7f7f;
        }

        &:focus {
          outline: none;
        }
      }

      button {
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: 0;
        text-align: center;
        @include vw-convert-none(width, 115px);
        @include vw-convert-none(height, 40px);
        @include vw-convert-none(font-size, 16px);
        background: $purple;
        color: white;
        @include vw-convert-none(border-radius, 30px);

        @include vw-convert-mo() {
          @include vw-convert-none(width, 22px, mo);
          @include vw-convert-none(height, 22px, mo);
          // background: url('components/imgs/m-search-icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 22px 22px, mo);
          font-size: 0;
        }
      }
    }
  }

  .l-table {
    border-top: 2px solid #4c4c4c;

    @include vw-convert-mo() {
      border-top: none;
    }
  }

  .l-filter.is-on {
    ul {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
}

.brand {
  .l-filter {
    width: 150px;
    height: 40px;
    vertical-align: middle;

    @include vw-convert-mo() {
      width: auto;
      @include vw-convert-none(height, 20px, mo);
      @include vw-convert-none(width, 57px, mo);
    }

    &.is-on {
      padding: 0px 15px 4px 15px;
      @include vw-convert-none(width, 57px, mo);
      border-color: #ccc;
      width: 150px;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;

      ul {
        padding: 0px 15px 15px 15px;
        border-color: #ccc;
        width: 150px;
        border-radius: 4px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        @include vw-convert-none(width, 57px, mo);

        li {
          list-style: none;
          font-size: 16px;
          margin-top: 12px;

          &:first-child {
            margin-top: 18px;
          }

          &:hover {
            color: $blue;
          }
        }
      }

      >span {
        margin-top: 8px;

        @include vw-convert-mo() {
          margin-top: 9px;
          padding: 0;
        }
      }
    }

    >span {
      border-radius: 4px;
      border: 1px solid #ccc;
      height: 100%;
      line-height: 40px;
      padding: 0px 15px 0px 15px;

      @include vw-convert-none(padding, 0px 0px 0px 7px, mo);
      @include vw-convert-none(line-height, 20px, mo);

      @include vw-convert-mo() {
        width: 100%;
      }

      &:after {
        @include vw-convert-mo() {
          @include vw-convert-none(right, 8px, mo);
        }
      }
    }
  }

  .l-brand-search {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 276px;
    height: 40px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 0px 8px 14px;
    box-sizing: border-box;

    @include vw-convert-mo() {
      @include vw-convert-none(width, 98px, mo);
      @include vw-convert-none(margin-left, 5px, mo);
      @include vw-convert-none(height, 20px, mo);
      @include vw-convert-none(padding, 4px 18px 4px 6px, mo);
    }

    input {
      width: 180px;
      border: none;

      @include vw-convert-mo() {
        width: 100%;
        font-size: 16px;
      }

      &::placeholder {
        color: #b2b2b2;
      }

      outline: none;
    }

    span {
      position: absolute;
      right: 14px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 20px;
      height: 20px;
      display: inline-block;
      // background: url('components/imgs/admin-search.svg') no-repeat center;
    }
  }

  .c-pagination {
    margin-top: 0;

    ul {
      li {
        position: relative;
        width: 30px;
        height: 30px;
        font-size: 20px;
        color: #4c4c4c;
        font-weight: 500;
        @include vw-convert-none(width, 15px, mo);
        @include vw-convert-none(height, 15px, mo);
        @include vw-convert-none(font-size, 8px, mo);

        &.first {
          // background: url('components/imgs/brand-first_btn.svg') no-repeat center;
          background-size: 30px 30px;
          @include vw-convert-none(background-size, 15px 15px, mo);
        }

        &.prev {
          // background: url('components/imgs/brand-prev_btn.svg') no-repeat center;
          background-size: 30px 30px;
          @include vw-convert-none(background-size, 15px 15px, mo);
        }

        &.next {
          // background: url('components/imgs/brand-next_btn.svg') no-repeat center;
          background-size: 30px 30px;
          @include vw-convert-none(background-size, 15px 15px, mo);
        }

        &.last {
          // background: url('components/imgs/brand-last_btn.svg') no-repeat center;
          background-size: 30px 30px;
          @include vw-convert-none(background-size, 15px 15px, mo);
        }

        &.is-select {
          position: relative;
          color: $blue;
          background: none;
          line-height: 30px;

          &:after {
            position: absolute;
            width: 25px;
            height: 2px;
            background: #3289c9;
            right: 0;
            left: 0;
            bottom: -3px;
            margin: auto;
            content: '';

            @include vw-convert-mo() {
              @include vw-convert-none(width, 12.5px, mo);
              @include vw-convert-none(height, 1px, mo);
              @include vw-convert-none(bottom, 1.5px, mo);
            }
          }
        }
      }
    }
  }

  .l-filter-category {
    @include vw-convert-mo() {
      @include vw-convert-none(margin-top, 16px, mo);
    }

    ul {
      border-bottom: none;
    }

    li {
      position: relative;
      width: auto;
      height: auto;
      padding: 0;
      font-size: 16px;
      margin-right: 12px;
      padding-right: 12px;
      @include vw-convert-none(font-size, 7px, mo);

      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 13px;
        width: 1px;
        background: #ccc;
        margin: auto;
        content: '';
      }

      &.is-select {
        color: $blue;
        font-weight: 600;

        &:after {
          content: none;
        }
      }

      &:last-child {
        &:before {
          content: none;
        }
      }
    }
  }

  .l-chk {
    input {
      position: absolute;
      opacity: 0;
      width: 24px !important;
      height: 24px;
      @include vw-convert-none(width, 12.5px, mo);
      @include vw-convert-none(height, 12.5px, mo);
      top: 0;
    }

    input+span {
      display: inline-block;
      vertical-align: middle;
      // background: url('components/imgs/brand-chk.svg') no-repeat center;
      @include vw-convert-none(background-size, 12px 12px, mo);
    }

    input:checked+span {
      // background: url('components/imgs/brand-chk-on.svg') no-repeat center;
      background-size: 24px 24px;
      @include vw-convert-none(background-size, 12px 12px, mo);
    }
  }

  .l-learning-tag {
    strong {
      font-size: 16px;
      color: #4c4c4c;

      &:before {
        width: 18px;
        height: 18px;
        background-size: 18px 18px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    >span {
      position: relative;
      margin-right: 8px;
      padding-right: 8px;
      font-size: 14px;
      color: #7f7f7f;
      font-weight: 500;

      &:after {
        position: absolute;
        right: 0;
        top: 0;
        left: auto;
        bottom: 0;
        margin: auto;
        width: 1px;
        height: 14px;
        background: #ccc;
        opacity: 1;
        content: '';
      }

      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }
}



.l-filter {
  position: relative;
  display: inline-block;
  @include vw-convert-none(font-size, 16px);
  cursor: pointer;
  @include vw-convert-none(width, 140px);
  @include vw-convert-none(height, 40px);

  @include vw-convert-mo() {
    position: static;
    @include vw-convert-none(font-size, 7px, mo);
    @include vw-convert-none(height, 20px, mo);
  }

  >span {
    display: inline-block;
    width: 100%;
    // @include vw-convert-none(width, 140px);
    @include vw-convert-none(height, 40px);
    @include vw-convert-none(padding, 0px 15px 0px 20px);
    @include vw-convert-none(line-height, 42px);
    border-radius: 10vw;
    border: 1px solid #e0e0e0;
    color: #4c4c4c;
    box-sizing: border-box;
    background: white;

    @include vw-convert-mo() {
      @include vw-convert-none(width, 60px, mo);
      @include vw-convert-none(height, 20px, mo);
      @include vw-convert-none(padding, 5px 8px 5px 9px, mo);
      box-sizing: border-box;
    }

    &:after {
      position: absolute;
      @include vw-convert-none(right, 10px);
      top: 0;
      bottom: 0;
      margin: auto;
      @include vw-convert-none(width, 20px);
      @include vw-convert-none(height, 20px);
      // background: url('components/imgs/select-down_icon.svg') no-repeat center;
      @include vw-convert-none(background-size, 20px 20px);

      @include vw-convert-mo() {
        @include vw-convert-none(right, 10px, mo);
        @include vw-convert-none(width, 10px, mo);
        @include vw-convert-none(height, 10px, mo);
        @include vw-convert-none(background-size, 10px 10px, mo);
      }

      content:'';
    }
  }

  ul {
    display: none;

    li {
      @include vw-convert-none(margin-top, 12px);
      @include vw-convert-none(margin-top, 6px, mo);
      color: #4c4c4c;
      cursor: pointer;

      &:first-child {
        @include vw-convert-none(margin-top, 18px);
        @include vw-convert-none(margin-top, 9.5px, mo);
      }
    }
  }

  &.is-on {
    position: relative;
    @include vw-convert-none(width, 140px);
    @include vw-convert-none(padding, 0px 15px 4px 20px);
    @include vw-convert-none(border-top-right-radius, 20px);
    @include vw-convert-none(border-top-left-radius, 20px);
    border: 1px solid #e5e5e5;
    background: white;
    box-sizing: border-box;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    @include vw-convert-mo() {
      @include vw-convert-none(width, 60px, mo);
      box-sizing: border-box;
      @include vw-convert-none(border-radius, 10px, mo);
      border: 1px solid #e0e0e0;
      @include vw-convert-none(border-top-right-radius, 8px, mo);
      @include vw-convert-none(border-top-left-radius, 8px, mo);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      padding: 0;
    }

    >span {
      border: none;
      height: auto;
      line-height: inherit;
      width: 100%;
      @include vw-convert-none(margin-top, 10px);
      padding: 0;

      @include vw-convert-mo() {
        position: static;
        @include vw-convert-none(padding, 5px 0px 0px 9px, mo);
      }

      &:after {
        @include vw-convert-none(top, 10px);
        bottom: auto;
        // background: url('components/imgs/select-up_icon.svg') no-repeat center;
        @include vw-convert-none(background-size, 20px 20px);

        @include vw-convert-mo() {
          @include vw-convert-none(right, 10px, mo);
          @include vw-convert-none(top, 5px, mo);
          @include vw-convert-none(width, 10px, mo);
          @include vw-convert-none(height, 10px, mo);
          @include vw-convert-none(background-size, 10px 10px, mo);
        }

        content:'';
      }
    }

    ul {
      display: block;
      position: absolute;
      @include vw-convert-none(width, 140px);
      @include vw-convert-none(padding, 0px 15px 20px 20px);
      @include vw-convert-none(border-radius, 20px);
      left: -1px;
      box-sizing: border-box;
      border: 1px solid #e5e5e5;
      background: white;
      border-top: none;
      @include vw-convert-none(border-radius, 10px, mo);

      @include vw-convert-mo() {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }

      z-index: 2;
      @include vw-convert-none(width, 60px, mo);
      @include vw-convert-none(padding, 0px 9px 10px 9px, mo);

      li {
        &:hover {
          color: $purple;
        }
      }
    }
  }

  &.short {
    &.is-on {
      position: relative;
      @include vw-convert-none(width, 140px);
      @include vw-convert-none(padding, 0px 15px 4px 20px);
      @include vw-convert-none(border-top-right-radius, 20px);
      @include vw-convert-none(border-top-left-radius, 20px);
      border: 1px solid #e5e5e5;
      background: white;
      box-sizing: border-box;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      @include vw-convert-mo() {
        @include vw-convert-none(width, 60px, mo);
        box-sizing: border-box;
        @include vw-convert-none(border-radius, 10px, mo);
        border: 1px solid #e0e0e0;
        @include vw-convert-none(border-top-right-radius, 8px, mo);
        @include vw-convert-none(border-top-left-radius, 8px, mo);
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        padding: 0;
      }

      >span {
        border: none;
        height: auto;
        line-height: inherit;

        @include vw-convert-none(margin-top, 10px);
        padding: 0;

        @include vw-convert-mo() {
          width: 100%;
          position: static;
          @include vw-convert-none(padding, 5px 0px 0px 9px, mo);
        }

        &:after {
          @include vw-convert-none(top, 10px);
          bottom: auto;
          // background: url('components/imgs/select-up_icon.svg') no-repeat center;
          @include vw-convert-none(background-size, 20px 20px);

          @include vw-convert-mo() {
            @include vw-convert-none(right, 10px, mo);
            @include vw-convert-none(top, 5px, mo);
            @include vw-convert-none(width, 10px, mo);
            @include vw-convert-none(height, 10px, mo);
            @include vw-convert-none(background-size, 10px 10px, mo);
          }

          content:'';
        }
      }

      ul {
        display: block;
        position: absolute;
        @include vw-convert-none(width, 140px);
        @include vw-convert-none(padding, 0px 15px 20px 20px);
        @include vw-convert-none(border-radius, 20px);
        left: -1px;
        box-sizing: border-box;
        border: 1px solid #e5e5e5;
        background: white;
        border-top: none;
        border-top-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        z-index: 1;
        @include vw-convert-none(width, 60px, mo);
        @include vw-convert-none(padding, 0px 9px 10px 9px, mo);
        @include vw-convert-none(border-radius, 10px, mo);

        li {
          &:hover {
            color: $purple;
          }
        }
      }
    }
  }
}

.l-filter-category {
  ul {
    // border-bottom: 2px solid #ccc;
    @include vw-convert-none(border-width, 2px);
  }

  li {
    position: relative;
    display: inline-block;
    @include vw-convert-none(padding, 8px 16px 6px 16px);
    color: #7f7f7f;
    cursor: pointer;

    .type {
      display: inline-block;
      vertical-align: middle;
      @include vw-convert-none(font-size, 20px);
    }

    .count {
      display: inline-block;
      vertical-align: middle;
      @include vw-convert-none(margin-left, 4px);
      @include vw-convert-none(font-size, 16px);

      @include vw-convert-mo() {
        display: none;
      }
    }

    &.is-select {
      color: $purple;

      &:after {
        position: absolute;
        right: 0;
        left: 0;
        width: 100%;
        @include vw-convert-none(height, 2px);
        @include vw-convert-none(bottom, -2px);
        background: $purple;
        content: '';
      }
    }
  }
}


.shop-item-list {
  letter-spacing: -10px;
  @include vw-convert-none(margin-top, 20px);

  @include vw-convert-mo() {
    @include vw-convert-none(margin-top, 13px, mo);
  }

  &.planet {
    li {
      position: relative;
      @include vw-convert-none(padding, 16px 25px 16px 26px);
      @include vw-convert-none(margin-right, 16px);

      .colorchip {
        // display:none;
        @include vw-convert-none(top, 188px);
      }
    }

    .imgwrap {
      @include vw-convert-none(width, 164px);
      @include vw-convert-none(height, 164px);
      @include vw-convert-none(border-radius, 16px);
      background-image: linear-gradient(to bottom, #1e1b6d, #1e1643);

      @include vw-convert-mo() {
        float: left;
        @include vw-convert-none(margin-right, 12px, mo);
        @include vw-convert-none(width, 50px, mo);
        @include vw-convert-none(height, 50px, mo);
        @include vw-convert-none(border-radius, 6px, mo);
      }

      img {
        @include vw-convert-none(max-width, 78px);
        @include vw-convert-none(width, 20px, mo);
      }
    }

    p {
      @include vw-convert-none(font-size, 20px);
      @include vw-convert-none(min-height, 50px);
      @include vw-convert-none(margin-top, 30px);

      @include vw-convert-mo() {
        float: left;
        @include vw-convert-none(width, 94px, mo);
        @include vw-convert-none(margin-top, 10px, mo);
        @include vw-convert-none(font-size, 8px, mo);
        @include vw-convert-none(max-height, 23px, mo);
        word-break: break-all;
        white-space: normal;
        font-weight: 500;
      }
    }

    .use-btn {
      @include vw-convert-mo() {
        display: inline-block;
        @include vw-convert-none(font-size, 8px, mo);
        color: $purple;
        @include vw-convert-none(margin-top, 4px, mo);
        font-weight: 500;
      }
    }

    .qpprice {
      @include vw-convert-mo() {
        float: left;
        @include vw-convert-none(width, 94px, mo);
        @include vw-convert-none(font-size, 10px, mo);
        @include vw-convert-none(margin-top, 4px, mo);
      }

      margin-top:0;
    }
  }

  li {
    letter-spacing: 0;
    display: inline-block;
    @include vw-convert-none(width, 215px);
    // @include vw-convert-none(height, 303px);
    @include vw-convert-none(padding, 15px 20px 0px 20px);
    vertical-align: top;
    box-sizing: border-box;

    &:first-child {
      .imgwrap {
        &:before {
          background: $purple;
        }
      }
    }

    &:nth-child(4n-1) {
      margin-right: 0;
    }

    &.hot-item {
      .imgwrap {
        &:after {
          background: $false;
          content: 'HOT';
        }
      }
    }

    &.sale-item {
      .imgwrap {
        &:after {
          background: $true;
          content: 'SALE';
        }
      }
    }

    &.new-item {
      .imgwrap {
        &:after {
          background: $alert;
          content: 'NEW';
        }
      }
    }
  }

  .imgwrap {
    position: relative;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;

    &:before {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      content: '';
    }

    &:after {
      position: absolute;
      @include vw-convert-none(top, 10px);
      @include vw-convert-none(left, 10px);
      @include vw-convert-none(width, 45px);
      @include vw-convert-none(height, 20px);
      @include vw-convert-none(line-height, 22px);
      @include vw-convert-none(font-size, 14px);
      border-radius: 10vw;
      color: white;
      content: none;

      @include vw-convert-mo() {
        @include vw-convert-none(width, 22px, mo);
        @include vw-convert-none(height, 9px, mo);
        @include vw-convert-none(top, 3.5px, mo);
        @include vw-convert-none(left, 3.5px, mo);
        content: '';
      }
    }

    // &:after{
    //   position: absolute;
    //   @include vw-convert-none(top, -10px);
    //   @include vw-convert-none(left, -10px);
    //   @include vw-convert-none(width, 40px);
    //   @include vw-convert-none(height, 40px);
    //   @include vw-convert-none(line-height, 40px);
    //   border-radius: 50%;
    //   background:#b2b2b2;
    //   color:white;
    //   @include vw-convert-none(font-size, 19px);
    // }
    img {
      display: inline-block;
      vertical-align: middle;
      height: auto;
    }
  }

  p {
    @include vw-convert-none(margin-top, 38px);
    @include vw-convert-none(font-size, 20px);
    color: #4c4c4c;
  }

  .qpprice {
    display: block;
    @include vw-convert-none(margin-top, 23px);
    color: $purple;
    @include vw-convert-none(font-size, 24px);
    font-weight: 500;
  }
}

.layer-subtitle {
  @include vw-convert-none(font-size, 24px);
  color: #333;
  font-weight: 500;

  @include vw-convert-mo() {
    @include vw-convert-none(font-size, 10px, mo);
  }

  >span {
    display: inline-block;
    @include vw-convert-none(margin-left, 8px);
    @include vw-convert-none(font-size, 16px);
    color: #b2b2b2;
    vertical-align: middle;

    @include vw-convert-mo() {
      @include vw-convert-none(margin-left, 3px, mo);
      @include vw-convert-none(font-size, 7px, mo);
    }
  }
}

.unfold-view {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  border-top: 1px solid #ccc;

  @include vw-convert-mo() {
    position: static;
    @include vw-convert-none(margin-top, 15px, mo);
    @include vw-convert-none(font-size, 7px, mo);
    border: none;
    @include vw-convert-none(height, 16px, mo);
  }

  &.brand {
    position: static;
    margin-top: 32px;
    text-align: center;
    border-top: none;

    @include vw-convert-mo() {
      @include vw-convert-none(margin-top, 16px, mo);
      height: auto;
    }

    span {
      position: static;
      display: inline-block;
      min-width: 216px;
      height: auto;
      line-height: initial;
      border: 1px solid #ccc;
      background: #fff;
      border-radius: 4px;
      padding: 13px 0;
      font-size: 16px;
      color: #7f7f7f;

      @include vw-convert-mo() {
        @include vw-convert-none(font-size, 8px, mo);
        @include vw-convert-none(width, 160px, mo);
        @include vw-convert-none(border-radius, 2px, mo);
        @include vw-convert-none(padding, 6.5px, mo);
      }

      &:before,
      &:after {
        content: none;
      }
    }
  }

  >span {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    background: white;
    @include vw-convert-none(top, -16px);
    @include vw-convert-none(width, 106px);
    @include vw-convert-none(height, 32px);
    @include vw-convert-none(font-size, 16px);
    @include vw-convert-none(line-height, 32px);
    border: 1px solid #b2b2b2;
    box-sizing: border-box;
    border-radius: 10vw;
    color: #b2b2b2;
    cursor: pointer;

    @include vw-convert-mo() {
      display: inline-block;
      position: static;
      @include vw-convert-none(padding, 3px 8px 3px 10px, mo);
      @include vw-convert-none(height, 16px, mo);
    }

    span {
      display: inline-block;
      vertical-align: middle;
      @include vw-convert-none(width, 20px);
      @include vw-convert-none(height, 20px);
      @include vw-convert-none(margin-left, 4px);
      // background: url('components/imgs/select-down_icon.svg') no-repeat center;
      @include vw-convert-none(background-size, 20px 20px);

      @include vw-convert-mo() {
        @include vw-convert-none(width, 10px, mo);
        @include vw-convert-none(height, 10px, mo);
        @include vw-convert-none(background-size, 10px 10px, mo);
      }
    }

    &:before,
    &:after {
      position: absolute;
      @include vw-convert-none(width, 23px);
      height: 100%;
      background: white;
      content: '';
    }

    &:before {
      @include vw-convert-none(left, -24px);
    }

    &:after {
      @include vw-convert-none(right, -24px);
    }
  }
}

.c-pagination {
  @include vw-convert-none(margin-top, 40px);
  @include vw-convert-none(margin-top, 16px, mo);
  text-align: center;

  ul {
    letter-spacing: -10px;

    li {
      display: inline-block;
      letter-spacing: 0;
      vertical-align: middle;
      @include vw-convert-none(width, 40px);
      @include vw-convert-none(height, 40px);
      cursor: pointer;

      &.list {
        @include vw-convert-none(line-height, 30px);
        @include vw-convert-none(width, 16px, mo);
        @include vw-convert-none(height, 16px, mo);
        @include vw-convert-none(line-height, 16px, mo);
        @include vw-convert-none(margin, 0px 5px 0px 5px);
        @include vw-convert-none(font-size, 20px);
        @include vw-convert-none(font-size, 8px, mo);
        color: #7f7f7f;
        @include vw-convert-none(line-height, 16px, mo);
        @include vw-convert-none(margin, 0px 1px 0px 1px, mo);
        border-radius: 50%;
      }

      &.first {
        @include vw-convert-none(margin-right, 12px);
        // background: url('components/imgs/pagination-first_btn.svg') no-repeat center;
        @include vw-convert-none(background-size, 40px 40px);
      }

      &.prev {
        @include vw-convert-none(margin-right, 24px);
        // background: url('components/imgs/pagination-prev_btn.svg') no-repeat center;
        @include vw-convert-none(background-size, 40px 40px);
        @include vw-convert-none(width, 16px, mo);
        @include vw-convert-none(height, 16px, mo);
        @include vw-convert-none(margin-right, 10.5px, mo);
        @include vw-convert-none(background-size, 16px 16px, mo);
      }

      &.next {
        @include vw-convert-none(margin-left, 24px);
        // background: url('components/imgs/pagination-next_btn.svg') no-repeat center;
        @include vw-convert-none(background-size, 40px 40px);
        @include vw-convert-none(width, 16px, mo);
        @include vw-convert-none(height, 16px, mo);
        @include vw-convert-none(margin-left, 10.5px, mo);
        @include vw-convert-none(background-size, 16px 16px, mo);
      }

      &.last {
        @include vw-convert-none(margin-left, 12px);
        // background: url('components/imgs/pagination-last_btn.svg') no-repeat center;
        @include vw-convert-none(background-size, 40px 40px);
      }

      &.is-select {
        @include vw-convert-none(line-height, 40px);
        border-radius: 50%;
        background: $purple;
        color: white;
      }
    }
  }
}

.c-select-list {
  border-color: #ccc;
  border: 1px solid #ccc;
  @include vw-convert-none(border-radius, 30px);
  @include vw-convert-none(height, 40px);
  @include vw-convert-none(font-size, 16px);
  color: #4c4c4c;
  @include vw-convert-none(padding, 0px 25px 0px 20px);

  &:before {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    content: '';
  }

  >span {
    display: inline-block;
    vertical-align: text-top;
  }
}

.l-stamp {
  strong {
    display: block;
    color: #4c4c4c;
    @include vw-convert-none(font-size, 20px);
    @include vw-convert-none(font-size, 10px, mo);
  }

  span {
    display: inline-block;
    background: #e5e5e5;
    border-radius: 50%;
    box-shadow: 0 4px 0 0 rgba(204, 204, 204, 0.5) inset;
    @include vw-convert-none(width, 48px);
    @include vw-convert-none(height, 48px);
    @include vw-convert-none(margin-right, 8px);
    @include vw-convert-none(margin-top, 7px);

    &.is-on {
      // background: url('components/imgs/restudy_icon.svg') no-repeat center;
      @include vw-convert-none(background-size, 48px 48px);
      box-shadow: none;
    }

    &.is-bigon {
      // background: url('components/imgs/big-restudy_icon.svg') no-repeat center;
      @include vw-convert-none(background-size, 48px 48px);
      box-shadow: none;
    }
  }
}

.l-scroll-y {
  @include vw-convert-mo() {
    overflow-y: hidden;
    width: 100%;

    ul {
      overflow: hidden;
      width: max-content;
      white-space: nowrap;
    }
  }
}

.hot-item-list.shop-item-list {
  @include vw-convert-mo() {
    margin-top: 0;
    width: auto;

    .hot-item {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
      @include vw-convert-none(margin-right, 18px, mo);
    }

    .imgwrap,
    .name,
    .qpprice {
      float: none;
    }

    .imgwrap {
      margin-left: 0;
      margin-right: 0;
    }

    .name,
    .qpprice {
      width: auto;
      @include vw-convert-none(width, 55px, mo);
      white-space: normal;
      word-break: break-all;
    }

    .name {
      @include vw-convert-none(margin-top, 19px, mo);
      @include vw-convert-none(min-height, 20px, mo);
      @include vw-convert-none(font-size, 8px, mo);
    }

    .qpprice {
      @include vw-convert-none(font-size, 10px, mo);
    }

    .colorchip {
      @include vw-convert-none(top, 57px, mo);
    }

    .color-chk {
      @include vw-convert-none(width, 22.5px, mo);
    }

    .color-list {
      display: inline-block;

      span {
        @include vw-convert-none(width, 6px, mo);
        @include vw-convert-none(height, 6px, mo);
        @include vw-convert-none(margin-left, 2px, mo);

        &:first-child {
          @include vw-convert-none(margin-left, 5.5px, mo);
        }
      }
    }
  }
}

.all-planet-item-list,
.planet-having,
.canuse-planet-item {
  @include vw-convert-mo() {
    .colorchip {
      @include vw-convert-none(left, 62px, mo);
    }

    .color-list {
      width: max-content;
      @include vw-convert-none(left, 22.5px, mo);
    }
  }
}

.canuse-planet-item {
  @include vw-convert-mo() {
    p.name {
      @include vw-convert-none(margin-top, 10px, mo);
    }
  }
}

// TODO: ????????? ??? ???
.userItemContainer .colorchip {
  display: none;
}

.en {
  .t-progressbar__grade-inner.first-grade {
    .img {
      @include vw-convert-none(margin-left, 28px);
    }
  }
}
