@import '../../../style/helpers/_index';

.left-leaf, .right-leaf {
  display: inline-block;
}
.right-leaf {
  transform: scaleX(-1);
}

.clap, .bye {
  position: absolute;
  right:0;
  left:0;
  // bottom:10px;
  // width:291px;
  // height:278px;
  margin:auto;
  @include vw-convert(bottom, 10px);
  @include vw-convert(width, 291px);
  @include vw-convert(height, 278px);
}

.bye {
  transform:scale(2);
  // bottom:-122px;
  @include vw-convert(bottom, -122px);
}

.teamselect {
  display: inline-block;
  .character {
    // @include vw-convert(width, 199px);
    // @include vw-convert(height, 221px);
  }
}