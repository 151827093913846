@import '../style/helpers/_index';

.basicLayout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url('./img/basicbg.svg') no-repeat center;
  background-attachment: fixed;
  height:100vh;
  min-height: 100vh;
  background-size: cover;
  // max-height:150vh;
  // @include vw-convert(height, 1080px);
  // overflow: hidden;
  // mvp 배경 돌아가는거땜에 화면이 자꾸 위아애로 요동침
}


