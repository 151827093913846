@import '../../../style/helpers/_index';

.radio,  .chkbox{
  position: relative;
  input {
    position: absolute;
    @include vw-convert(width, 24px);
    @include vw-convert(height, 24px);
    top:0;
    bottom:0;
    left:0;
    margin:auto;
    opacity: 0;
    cursor: pointer;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    @include vw-convert(width, 24px);
    @include vw-convert(height, 24px);
    @include vw-convert(margin-right, 15px);
  }
  label {
    display: inline-block;
    vertical-align: middle;
    @include vw-convert(font-size, 24px);
    color:#536476;
    font-weight: 500;
  }
}
.radio {
  span {
    background:url('./img/radio-default.svg');
    @include vw-convert(background-size, 24px 24px);
  }
  input:checked {
    +span {
      background:url('./img/radio-active.svg');
      @include vw-convert(background-size, 24px 24px);
    }
  }
  &.disable {
    span {
      background:url('./img/radio-disable.svg');
      @include vw-convert(background-size, 24px 24px);
    }
  }
}
.chkbox {
  span {
    background:url('./img/chk-default.svg');
    @include vw-convert(background-size, 24px 24px);
  }
  input:checked {
    +span {
      background:url('./img/chk-active.svg');
      @include vw-convert(background-size, 24px 24px);
    }
  }
  &.mvp {
    input:checked {
      +span {
        background:url('./img/chk-mvp.svg');
        @include vw-convert(background-size, 24px 24px);
      }
    }
  }
  &.disable {
    span {
      background:url('./img/chk-disable.svg');
      @include vw-convert(background-size, 24px 24px);
    }
  }
}