@import '../../../style/helpers/index';

.msg-layout {
  @include vw-convert(margin-bottom, 70px);
}
.msg-content {
  @include vw-convert(margin-top, 60px);
  section {
    display: inline-block;
    vertical-align: top;
    box-shadow: 10px 10px 20px rgba(51, 58, 83, 0.1);
    @include vw-convert(border-radius, 16px);
    @include vw-convert(padding, 32px);
    background:white;
    .section-title {
      @include vw-convert(font-size, 24px);
      @include font;
      color:$navy;
      font-weight: 600;
    }
  }
  .msg-target {
    @include vw-convert(width, 736px);
    @include vw-convert(height, 776px);
  }
  .msg-target-wrap {
    @include vw-convert(margin-top, 25px);
    @include vw-convert(border-radius, 8px);
    border:2px solid $gray30;
    overflow: hidden;
    @include vw-convert(border-width, 2px);
    label {
      display: none;
    }
    .title {
      background:$gray10;
      @include vw-convert(padding, 16px 32px 16px 75px);
      li {
        display: inline-block;
        @include vw-convert(width, 140px);
        @include vw-convert(font-size, 20px);
        color:$gray50;
        text-align: center;
      }
    }
    .content {
      @include vw-convert(height, 600px);
      overflow-y: auto;
      @include vw-convert(padding, 24px 30px 32px 32px);
    }
    .info {
      @include vw-convert(margin-bottom, 35px);
      width:100%;
      &:last-child {
        margin-bottom:0;
      }
      tr {
        display: block;
        @include vw-convert(margin-bottom, 32px);
        &:last-child {
          margin-bottom:0;
        }
        &.not-attend {
          .name {
            
            opacity: 0.5;
          }
          .attend {
            @include vw-convert(width, 420px);
            opacity: 0.5;
          }
        }
      }
      td {
        @include vw-convert(font-size, 24px);
        vertical-align: middle;
        .chkbox {
          input {
            @include vw-convert(width, 32px);
            @include vw-convert(height, 32px);
          }
          span {
            display: block;
            @include vw-convert(margin-right, 32px);
            @include vw-convert(width, 32px);
            @include vw-convert(height, 32px);
            @include vw-convert(background-size, 32px 32px);
          }
        }
        &.attend, &.ps, &.game {
          @include vw-convert(width, 140px);
          text-align: center;
          color:$gray70;
        }
        &.name {
          width:130px;
          color:$navy;
          @include vw-convert(margin-right, 10px);
          font-weight: 550;
          span {
            display: inline-block;
            margin-left:3px;
            @include vw-convert(font-size, 20px);
            color:$gray70;
            font-weight: 500;
          }
        }
      }
    }
  }
  .msg-write-wrap {
    .section-title {
      @include vw-convert(margin, 30px 0px 10px 0px);
    }
  }
  .msg-write {
    @include vw-convert(margin-left, 40px);
    @include vw-convert(width, 720px);
    @include vw-convert(height, 776px);
    .chkbox-wrap {
      @include vw-convert(margin-top, 28px);
      .chkbox {
        display: inline-block;
        @include vw-convert(margin-right, 16px);
        &:last-child {
          margin-right:0;
        }
      }
    }
    textarea {
      resize:none;
      width:100%;
      @include vw-convert(height, 338px);
      @include vw-convert(margin-top, 25px);
      @include vw-convert(padding, 16px);
      border:2px solid $gray30;
      @include vw-convert(border-radius, 8px);
      @include vw-convert(font-size, 20px);
      color:$navy;
      font-weight: 500;
      line-height: 150%;
    }
    .recieve-num {
      @include vw-convert(border-radius, 8px);
      @include vw-convert(height, 92px);
      background:$gray20;
      @include vw-convert(font-size, 20px);
      color:$gray90;
      overflow-y: auto;
      ul {
        @include vw-convert(padding, 10px);
      }
      span {
        display: inline-block;
        vertical-align: middle;
      }
      .name {
        display: inline-block;
        @include vw-convert(min-width, 80px);
        font-weight: 600;
      }
    }
    .classmode-btn {
      @include vw-convert(margin-top, 32px);
      width: 100%;
      // @include vw-convert(line-height, 62px);
      @include vw-convert(height, 62px);
    }
  }
}