.calendar {
  :global(.ant-fullcalendar-month .ant-fullcalendar-value) {
    text-align: center;
  }
  :global(.ant-fullcalendar-month-panel-cell) {
  }
}
.month {
  padding: 24px;
  display: flex;
  gap: 16px;
}
.day {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}