@import '../../style/helpers/index';

.homework-layout {
  max-width: 1200px;
  margin: 0px auto 0px auto;
  padding-top: 128px;
  padding-bottom: 80px;

  +.homework-layout {
    padding-top: 20px;
  }

  .date-tag {
    background: rgba(#ef6b69, 0.1);
    color: #ef6b69;

    &:before {
      background: url('./img/icon_warning.svg') no-repeat center;
    }
  }

  .classmode-btn.white {
    display: none;
  }
}
