@import '../style/helpers/index';

$yellow: #ffd300;
$blue: #17d3ff;
$pink: #ff4e91;

$duration: 3000;

@mixin random-card {
  @for $i from 1 through 99 {
    $randomresult1 : #{randomNum(0, 85)};
    $randomresult2 : #{randomNum(0, 85)};
    $randomresult3 : #{randomNum(0, 85)};
    $randomresult4 : #{randomNum(0, 85)};
    $bg: #{randomNum(000, 255)};
    @keyframes random {
      0% {
        transform: rotate(0deg);
        right:0;
        top:0;
      }
      100% {
        transform: rotate(#{randomNum(0, 360)}deg);
        right : $randomresult1+unquote('%');
        top : $randomresult3+unquote('%');
      }
    }
    &:nth-child(#{$i}) {
      transform: rotate(#{randomNum(0, 360)}deg);
      right : $randomresult1+unquote('%');
      top : $randomresult3+unquote('%');
      // 랜덤으로 키프레임 변수 넣어야한다
      // animation: random 2s forwards;
    }
  }
}

.icon {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 32px;
  position: relative;
}

// .confetti {
//   display: flex;
//   align-items: center;
//   width: 100%;
//   height: 800px;
//   overflow: hidden;
// }

@keyframes makeItRain {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    transform: translateY(800px);
  }
}

.confetti-piece {
  position: absolute;
  width: 8px;
  height: 16px;
  background: $yellow;
  top: 0;
  opacity: 0;

  @for $i from 1 through 99 {
    &:nth-child(#{$i}) {
      left: $i * 7%;
      transform: rotate(#{randomNum(-80, 80)}deg);
      animation: makeItRain $duration * 1ms infinite ease-out;
      animation-delay: #{randomNum(0, $duration * .5)}ms;
      animation-duration: #{randomNum($duration * .7, $duration * 1.2)}ms
    }
  }

  &:nth-child(odd) {
    background: $blue;
  }

  &:nth-child(even) {
    z-index: 1;
  }

  &:nth-child(4n) {
    width: 5px;
    height: 12px;
    animation-duration: $duration * 2ms;
  }

  &:nth-child(3n) {
    width: 3px;
    height: 10px;
    animation-duration: $duration * 2.5ms;
    animation-delay: $duration * 1ms;
  }

  &:nth-child(4n-7) {
    background: $pink;
  }
}

.cardpan {
  border:1px solid green;
  width:800px;
  height:800px;
  position: absolute;
  &:hover {
    .card {
      @include random-card();
    }
  }
  .card {
    @include absolute-full;
    // position: absolute;
    // right:0;
    // top:0;
    width:92px;
    height:180px;
    background:gray;
    transition: 2s;
    cursor: pointer;
    &:hover {
      background:#eee;
      transition: 2s;
    }
    // 로테이트 랜덤. 포지션 랜덤. z-index 랜덤. 
  }
}

