.logo {
  min-width: 90px;
  // height: 31px;
  margin-right: 12px;
  float: left;
  font-size: 20px;
  color: #fff;
}

.subLogo {
  font-size: 14px;
  margin-right: 100px;
  color: #999
}

.menu {
  margin-right: 8px;
  min-width: 160px;
}

.right {
  float: right;
  height: 100%;
  overflow: hidden
}

.search {
  width: 0;
}

.action {
  display: inline-block;
  height: 100%;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;

  >i {
    // color: @text-color;
    vertical-align: middle;
  }

  &:hover {
    // background: @pro-header-hover-bg;
  }
}

.account {
  color: #fff;

  .avatar {
    margin: 20px 0;
    margin-right: 8px;
    vertical-align: top;
    background: rgba(255, 255, 255, 0.85);
  }
}
