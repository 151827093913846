@import '../../../style/helpers/index';

.activity-layout {
  .page-content-title {
    @include vw-convert(margin-top, 170px);
    background:rgba($blue, 0.1);
    &:after {
      position: absolute;
      bottom:0;
      @include vw-convert(right, 270px);
      @include vw-convert(width, 160px);
      @include vw-convert(height, 143px);
      background: url('./따미.svg') no-repeat center;
      @include vw-convert(background-size, 160px 143px);
      content:'';
    }
  }
}