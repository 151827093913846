@import '../../style/helpers/index';

.review-layout {
  max-width:1200px;
  margin:0px auto 0px auto;
  padding-top:128px;
  padding-bottom:80px;
}
.review-layout-button{
  background-color: rgba(255,255,255,1);
  width : 138px;
  margin-right: 2px;
  border : 2px solid #55A3FF;
  border-radius : 4px;
  box-sizing : border-box;
}
.review-layout-font{
  margin : auto;
  width : 90px;
  display : flex;
  flex : 1;
  text-align: center;
  color : #55A3FF;
  font-size: 16px;
  line-height : 120%;
  letter-spacing : -0.03em;
  border-radius : 50px;
}
.review-layout-wrap {
  //margin-top:40px;
  margin-top:40px;

  .date-tag {
    color:#333a53;
    &:before {
      background:url('./img/clock.svg') no-repeat center;
    }
  }

  .ht-queture-list {
    .state-tag {
      display: none;
    }
  }
}
