@import '../../../style/helpers/index';

.summary-layout {
  margin: auto;
  background: white;
  color: #333A53;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 60px 80px 40px 80px;

  &.layer {
    box-shadow: none;
    background: transparent;
  }

  p {
    @include vw-convert-ta(font-size, 80px);
    color: #ef6b69;
    @include font;
    background: url('./img/box.svg') no-repeat center;
    background-size: cover;
    @include vw-convert-ta(width, 1074px);
    @include vw-convert-ta(height, 293px);
    text-align: center;
    font-weight: 500;
    @include vw-convert-ta(line-height, 260px);
    // span {
    //   display: inline-block;
    // }
  }

  .btnwrap {
    text-align: right;

    button {
      @include vw-convert-ta(margin-top, 24px);
      display: inline-block;
      background: #333a53;
      color: white;
      border-radius: 10vw;
      @include vw-convert-ta(font-size, 24px);
      @include vw-convert-ta(padding, 10px 34px);
    }
  }

  .fail {
    position: relative;
    margin: 0 auto;
    @include vw-convert-ta(width, 324px);
    @include vw-convert-ta(height, 329px);

    >div {
      position: relative;
      z-index: 2;
    }

    &:after {
      position: absolute;
      right: 0;
      left: 0;
      margin: auto;
      border-radius: 80%;
      @include vw-convert-ta(bottom, -10px);
      @include vw-convert-ta(width, 165px);
      @include vw-convert-ta(height, 24px);
      background: #d7e0e7;
      z-index: 1;
      content: '';
    }
  }
}

.summary-layout-title {
  @include vw-convert-ta(font-size, 32px);
  color: #333A53;
  font-weight: 600;
  border-bottom: 2px solid #333A53;
  @include font;

  &:before {
    display: inline-block;
    @include vw-convert-ta(width, 26px);
    @include vw-convert-ta(height, 26px);
    @include vw-convert-ta(margin-right, 14px);
    vertical-align: middle;
    background: url('./img/pencil.svg') no-repeat center;
    background-size: cover;
    content: '';
  }
}

.edit-tab {
  &__nav {
    position: relative;
    display: inline-block;
    @include vw-convert-ta(margin-top, 24px);
    @include vw-convert-ta(font-size, 24px);
    @include vw-convert-ta(height, 64px);
    @include vertical-center;
    font-weight: 500;
    cursor: pointer;

    &:first-child {
      @include vw-convert-ta(width, 367px);
      @include vw-convert-ta(margin-left, -3px);
      @include vw-convert-ta(padding-left, 24px);
      background: url('./img/step1.png') no-repeat center;
      background-size: cover;
      z-index: 3;

      &.is-active {
        background: url('./img/step1-active.png') no-repeat center;
        background-size: cover;
      }
    }

    &:nth-child(2) {
      @include vw-convert-ta(padding-left, 68px);
      @include vw-convert-ta(width, 393px);
      @include vw-convert-ta(margin-left, -34px);
      z-index: 2;
      background: url('./img/step2.png') no-repeat center;
      background-size: cover;

      &.is-active {
        background: url('./img/step2-active.png') no-repeat center;
        background-size: cover;
      }
    }

    &:nth-child(3) {
      @include vw-convert-ta(padding-left, 57px);
      @include vw-convert-ta(width, 352px);
      // width:33.8%;
      @include vw-convert-ta(margin-left, -35px);
      z-index: 1;
      background: url('./img/step3.png') no-repeat center;
      background-size: cover;

      &.is-active {
        background: url('./img/step3-active.png') no-repeat center;
        background-size: cover;
      }
    }

    &.is-active {
      color: white;
      position: relative;

      &:after {
        position: absolute;
        @include vw-convert-ta(right, 36px);
        top: 0;
        bottom: 0;
        margin: auto;
        @include vw-convert-ta(width, 40px);
        @include vw-convert-ta(height, 40px);
        background: url('./img/chk-white.svg') no-repeat center;
        background-size: cover;
        content: '';
      }
    }

    &.is-done {
      &:after {
        position: absolute;
        @include vw-convert-ta(right, 40px);
        top: 0;
        bottom: 0;
        margin: auto;
        @include vw-convert-ta(width, 40px);
        @include vw-convert-ta(height, 40px);
        background: url('./img/chk-black.svg') no-repeat center;
        background-size: cover;
        content: '';
      }
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.edit-wrap {
  @include vw-convert-ta(margin-top, 24px);
  background: #eef0f5;
  @include vw-convert-ta(padding, 30px 32px 32px 32px);
  @include vw-convert-ta(height, 376px);
  @include vw-convert-ta(border-radius, 8px);
}

.edit-area {
  @include vw-convert-ta(font-size, 24px);
  font-weight: 500;

  h5 {
    @include vw-convert-ta(margin-bottom, 30px);
  }

  .input-text {
    @include vw-convert-ta(width, 320px);
    @include vw-convert-ta(margin-right, 8px);
    border: 2px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    @include vw-convert-ta(line-height, 50px);
    @include vw-convert-ta(height, 50px);
    @include vw-convert-ta(font-size, 20px);
    @include vw-convert-ta(padding-left, 16px);
    color: #4C4C4C;
    font-weight: 500;

    &::placeholder {
      color: #e5e5e5;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  textarea {
    width: 100%;
    @include vw-convert-ta(height, 254px);
    @include vw-convert-ta(padding, 16px);
    @include vw-convert-ta(font-size, 20px);
    color: #333A53;
    border: 1px solid #B2B2B2;
    box-sizing: border-box;
    border-radius: 4px;
    resize: none;
  }
}

.summary-card .ant-card-head-title {
  padding: 8px 0;
}
