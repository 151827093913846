$navy:#333a53;
$blue:#55a3ff;
$deep-blue:#397dce;
$green:#1cc4cc;
$deep-green:#048aa8;
$yellow:#ffd542;
$deep-yellow:#ffa842;
$red:#ef6b69;
$deep-red:#bf413f;
$gray90:#536476;
$gray70:#7f92a3;
$gray50:#abb9c5;
$gray30:#d7e0e7;
$gray20:#e7eaf0;
$gray10:#f7f9fb;

$false:#ef6b69;
$true:#1cc4cc;
$chk:#55A3FF;
$unchk:#536476;
$purple:purple;
$unimportant-color:gray;
$unimportant-bg:gray;
$alert:orange;

.u-color-red {
  color:$red;
}
.u-color-yellow {
  color:$yellow;
}
.u-color-deepyellow {
  color:$deep-yellow;
}
.u-color-blue {
  color:$blue;
}