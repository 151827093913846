.ant-page-header {
  box-sizing: border-box !important;
  margin: 0 !important;
  color: rgba(0, 0, 0, .65) !important;
  font-size: 14px !important;
  font-variant: tabular-nums !important;
  line-height: 1.5 !important;
  list-style: none !important;
  font-feature-settings: "tnum" !important;
  position: relative !important;
  padding: 16px 24px !important;
  background: unset !important;

}

.admin-page-content {
  position: relative;
  margin: 0 24px !important;
  background: #fff !important;
  padding: 16px !important;
}

.admin-page-print-content {
  position: relative;
  margin: 0 24px;
  background: #fff;
  padding: 0px;
  width: 252mm; // scaled size; see src/pages/admin/classroom/workbook/index.scss
}

.student-stat-calendar .ant-fullcalendar-header .ant-radio-group {
  display: none !important
}

.admin-game-preview {
  $ratio: 0.8;
  padding: 60px;
  text-align: center;

  // .page-content {
  //   text-align: center;
  // }
  .gameexplain-layout {
    .img {
      width: 536px*$ratio;
      height: 404.5px*$ratio;
    }

    .content-text-wrap {
      max-width: 736px*$ratio;
      margin-left: 130px*$ratio;

      .page-content-title {
        width: auto;

        &:after {
          // width:199px*$ratio;
          // height:135px*$ratio;
          // background-size:159.2px*$ratio 108px*$ratio;
          right: -20px;
          bottom: 0;
        }
      }
    }
  }
}

.admin-activity-preview {
  padding: 60px;

  .page-content-title,
  .page-content-ment {
    max-width: 1280px;
    width: auto;
    max-width: 1280px;
  }
}

.anticon {
  // width: 14px;
  max-width: 14px;
}

.ck-editor__editable_inline {
  height: 360px;
}
