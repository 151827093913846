@import '../../../style/helpers/index';

.teamselectchk-layout {
  width:100%;
  height:100%;
  background:rgba(#222943, 0.7);
  text-align: center;
  &:after {
    @include absolute-full;
    width:1242px;
    height:1243px;
    background:url('./mvp-bg.svg') no-repeat center;
    background-size:contain;
    z-index: 0;
    animation: rotate 15s infinite linear;
    content:'';
  }
  &:before {
    display: inline-block;
    vertical-align: middle;
    height:100%;
    content:'';
  }
  .teamselectchk-wrap {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    z-index: 1;
  }
  .big-title, .small-title {
    color:white;
  }
}