@import '../../../style/helpers/index';
.basic-layout {
  &.is-active &{
    overflow: hidden;
  }
}

.randomteam-layout {
  &.is-active {
    position: relative;
    width:100%;
    height:100%;
    text-align: center;
    overflow: hidden;
    @include vertical-center;
    .randomteam-layout-wrap {
      display: inline-block;
      vertical-align: middle;
      &:before {
        @include absolute-full;
        width: 1242px;
        height: 1243px;
        // background: url('components/img/mvp-bg.svg') no-repeat center;
        background: url('../../../pages/classMode/mvp/img/mvp-bg.svg') no-repeat center;
        background-size: contain;
        animation: rotate 15s infinite linear;
        z-index: 1;
        content: '';
      }
  
      &:after {
        @include absolute-full;
        background: rgba(#222943, 0.5);
        z-index: 0;
        content: '';
      }
    }

    .big-title {
      position: relative;
      color: white;
      z-index: 1;
      font-size: 64px;
    }

    .small-title {
      position: relative;
      color: white;
      z-index: 1;
      font-size: 32px;
    }
    .attendancelist-wrap {
      position: relative;
      color: white;
      margin-top:0;
      z-index: 1;

      .attendancelist {
        width: 217px;
        height: 305px;
        &.is-pick {
          .attendancelist__name {
            margin-top:12px;
            font-size:32px;
          }
          .teamleader {
            display: inline-block;
            width:144px;
            height:34px;
            margin-top:5px;
            font-size:24px;
            background:#abb9c5;
            line-height:34px;
            border-radius: 10vw;
          }
        }
        .img {
          width: 100%;
          height: 176px;
          margin-top: 0;
          background: none;
        }
      }

      .attendancelist__name {
        margin-top: 32px;
        font-size: 32px;
      }
    }
  }

  .big-title {
    color: #ef6b69;
    font-size: 48px;
  }

  .attendancelist-wrap {
    max-width: 1020px;
    &.is-bigcard {
      .attendancelist {
        width:190px;
        height:260px;
        .img {
          width:120px;
          height:120px;
          background-size:120px 120px;
        }
        .attendancelist__name {
          font-size:32px;
        }
      }
    }
    .attendancelist {
      width:149px;
      height:205px;
      margin:32px 24px 0px 0px;
      background: white;
      box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.1);

      .attendancelist__name {
        color: #536476;
        font-size:24px;
        font-weight:600;
        margin-top:0px;
      }

      &.is-pick {
        .attendancelist__name {
          color: #333a53;
          margin-top:30px;
        }

        .img {
          background: none;
          width:100%;
          margin:0;
        }

        img {
          display: block;
        }
        .teamleader {
          display: none;
        }
      }

      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          .attendancelist__name {
            color: #536476;
          }
        }
      }

      &:before {
        content: none;
      }

      img {
        display: none;
      }

      .img {
        width: 100px;
        height: 100px;
        background: url('./radom-card.svg') no-repeat center;
        background-size: 100px 100px;
        margin: 16px auto 0;
      }

      &__time {
        display: none;
      }
    }
  }

  .randomteam-content {}
}