@import '../../../style/helpers/_index';

.playerWrap {
  position: relative;
  @include vw-convert(width, 1536px);
  height:auto;
  @include vw-convert(border-radius, 16px);
  box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.2);

  &.play {
    .Amediaplayer__title {
      display: none;
    }

    &:hover {
      .Amediaplayer__title {
        display: block;
      }
    }
  }
  &.zoomin {
    .controls {
      display: none;
      .progressbar {
        width:50%;
      }
      .speaker, .volume, .zoom {
        // position: absolute;
        // top:0;
        // bottom:0;
        // margin:auto;
      }
      .speed {
        width:50px;
      }
      .speaker {
        right:260px;
      }
      .volume {
        // right:100px;
      }
      .zoom {
        right:30px;
      }
    }
  }
  &__wrap {
    position: relative;
    @include vw-convert(border-radius, 16px 16px 16px 16px);
    background: #eee;
    width: 100%;
    height: 100%;
  }

  &__title {
    display: none;
    position: absolute;
    right: 0;
    @include vw-convert(width, 301px);
    @include vw-convert(height, 83px);
    background: url('./img/player-tag.svg') no-repeat center;
    @include vw-convert(background-size, 301px 83px);
    @include vw-convert(font-size, 32px);
    color: white;

    span {
      display: block;
      @include vw-convert(margin-left, 50px);
      @include font;

      &:before {
        display: inline-block;
        @include vw-convert(margin-right, 8px);
        vertical-align: middle;
        border-radius: 50%;
        @include vw-convert(width, 50px);
        @include vw-convert(height, 50px);
        background: #f38d8b url('./img/queture-pre.svg') no-repeat center;
        @include vw-convert(background-size, 50px 50px);
        content: '';
      }
    }
  }
  #queture-player{
    width:100%;
    @include vw-convert(border-radius, 0px 0px 16px 16px);
  }
  .play-state-wrap {
    .play-btn-t {
      @include absolute-full;
      @include vw-convert(width, 128px);
      @include vw-convert(height, 128px);
      cursor: pointer;
    }
  }

  .controls {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    @include vw-convert(padding, 0px 24px 0px 36px);
    @include vw-convert(height, 106px);
    @include vw-convert(border-radius, 0px 0px 16px 16px);
      background: rgba(51, 58, 83, 0.8);
    // @include vertical-center;
    // &:before {
    //   width:100%;
    //   height:100%;
    //   content:'';
    // }
    &.on {
      display: block;
    }
    .play-btn-t,
    .progressbar,
    .timer,
    .volume,
    .zoom {
      display: inline-block;
      vertical-align: middle;
    }

    .play-btn-t,
    .volume,
    .zoom {
      cursor: pointer;
    }

    .play-btn-t {
      position: relative;
      z-index: 1;
      @include vw-convert(width, 45px);
      @include vw-convert(height, 45px);
      cursor: pointer;
      pointer-events: auto;
      background:none;
    }

    .progressbar {
      position: relative;
      @include vw-convert(margin-left, 18px);
      @include vw-convert(width, 768px);
      @include vw-convert(height, 18px);
      background: rgba(#fff, 0.3);
      @include vw-convert(border-radius, 4px);
      cursor: pointer;

      &:hover {
        .tooltip {
          display: block;
        }
      }

      .tooltip {
        display: none;
        position: absolute;
        @include vw-convert(top, -70px);
        @include vw-convert(left, -50px);
        text-align: center;
        @include vw-convert(width, 104px);
        @include vw-convert(height, 54px);
        background: url('./img/tooltip.svg') no-repeat center;
        @include vw-convert(background-size, 104px 54px);
        @include vw-convert(font-size, 24px);
        color: white;
        @include vertical-center;

        span {
          display: inline-block;
          vertical-align: baseline;
        }
      }

      .cover {
        background: $blue;
        height: 100%;
        width: 3%;
        @include vw-convert(border-radius, 4px 0px 0px 4px);
      }
    }

    .timer {
      @include vw-convert(margin-left, 24px);
      @include vw-convert(font-size, 20px);
      color: white;
      @include vw-convert(letter-spacing, 0.7px);
      .full-time {
        opacity: 0.5;
      }
    }
    .quality, .speed {
      color:white;
      @include vw-convert(line-height, 106px);
      span {
        cursor: pointer;
      }
      &:hover {
        ul {
          display: block;
        }
      }
      ul {
        display: none;
        position: absolute;
        background: rgba(#333a53, 0.8);
        border-radius: 8px;
        @include vw-convert(left, 0px);
        @include vw-convert(bottom, 110px);
        @include vw-convert(padding, 15px 15px 15px 20px);
        @include vw-convert(line-height, 45px);
        &:after {
          position: absolute;
          display: block;
          width:100%;
          height:30px;
          background:transparent;
          left:0;
          bottom:-20px;
          z-index: 0;
          content:'';
        }
      }
      li {
        position: relative;
        @include vw-convert(width, 93px);
        cursor: pointer;
        &.is-chk {
          &:after {
            position: absolute;
            right:0;
            top:0;
            bottom:0;
            margin:auto;
            @include vw-convert(width, 12px);
            @include vw-convert(height, 9px);
            background:url('./img/chk.svg') no-repeat center;
            @include vw-convert(background-size, 12px 9px);
            content:'';
          }
        }
      }
    }
    .quality {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      @include vw-convert(font-size, 20px);
      @include vw-convert(width, 66px);
      @include vw-convert(margin-left, 44px);
      color:white;
      ul {
        position: absolute;
      }
    }
    .speed {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      @include vw-convert(font-size, 20px);
      @include vw-convert(width, 66px);
      @include vw-convert(margin-left, 16px);
      color:white;
      ul {
        position: absolute;
      }
    }
    .speaker {
      display: inline-block;
      vertical-align: middle;
      @include vw-convert(width, 60px);
      @include vw-convert(height, 60px);
      background: url('./img/Amediaplayer-volume.svg');
      @include vw-convert(background-size, 60px 60px);
      cursor: pointer;
    }
    .volume {
      position: relative;
      @include vw-convert(width, 144px);
      @include vw-convert(height, 16px);
      @include vw-convert(border-radius, 4px);

      &:before {
        position: absolute;
        @include vw-convert(left, -70px);
        top: 0;
        bottom: 0;
        margin: auto;
        @include vw-convert(width, 60px);
        @include vw-convert(height, 60px);
        background: url('./img/Amediaplayer-volume.svg');
        @include vw-convert(background-size, 60px 60px);
        // content: '';
      }

      .ant-slider {
        @include absolute-full;
        width: 100%;
        height:100%;
        background: rgba(#fff, 0.3);
        cursor: pointer;
      }

      .cover {
        height: 100%;
        background: $blue;
        width: 10%;
        @include vw-convert(border-radius, 4px 0px 0px 4px);
      }
    }

    .zoom {
      @include vw-convert(margin-left, 35px);
      @include vw-convert(width, 50px);
      @include vw-convert(height, 50px);
      background: url('./img/Amediaplayer-zoomout.svg');
      @include vw-convert(background-size, 50px 50px);

      &.zoomin {
        background: url('./img/Amediaplayer-zoomin.svg');
        @include vw-convert(background-size, 50px 50px);
      }
    }
  }
}
