#mvpAnimation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background: url(./img/bg-mvp.svg) no-repeat center;
  background-attachment: fixed;
  background-size: auto 100%;
  overflow: hidden;
  background-color: #000;

  .img-title {
    width: 700px;
    height: 164px;
    background: url(./img/title-mvp.svg) no-repeat center;
    background-size: 100%;
    position: absolute;
    top: 80px;
  }
  .banner-anim-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 984px;
    .student-list {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      // height: 100%;
      width: 100%;
      padding: 100px;
      padding-bottom: 40px;
      // gap: 80PX;
      li {
        // max-width: 33.3%;
        text-align: center;
        font-size: 64px;
        color: #541F1F;
        // width: 500px;
        min-width: 200px;
        text-align: center;
        // font-weight: 700;
        font-family: "Jua";
      }
    }
  }
  .curtain-anim {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    width: 100%;
    height: 100vh;
  }
  .speech-anim {
    position: absolute;
    z-index: 3;
    left: 400px;
    bottom: 540px;
  }
  .character-anim {
    width: 340px;
    height: 390px;
    position: absolute;
    bottom: 160px;
    z-index: 3;
    &.ddami {
      left: 320px;
    }
    &.queda {
      right: 150px;
      z-index: 1;
    }
    &.chaeli {
      right: 360px;
      z-index: 1;
    }
  }
}
