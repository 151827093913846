@import '../../style/helpers/index';

.ht-queture-list {
  position: relative;
  //margin-top:16px;
  margin-top:8px;
  margin-left:80px;
  //padding:40px;
  padding:20px;
  background:white ;
  //border-radius: 16px;
  border-radius: 22px;
  z-index: 0;
  &.is-cover {
    &:before {
      @include absolute-full;
      background:rgba(#fff, 0.7);
      z-index: 1;
      content:'';
    }
    .state-tag {
      background:#ABB9C5!important;
      &:before {
        background: url('./img/dis-icon_close.svg') no-repeat center;
        content:'';
      }
    }
    .textwrap {
      > strong, .title, .tag {
        color:#ABB9C5!important;
      }
    }
    .btnwrap button {
      background:#ABB9C5!important;
    }
  }
  .state-tag {
    position: absolute;
    background:#ef6b69;
    top:0;
    right:20px;
    //padding:10px 36px;
    padding:8px 25px;
    color:white;
    border-radius: 0px 0px 8px 8px;
    &:before {
      display: inline-block;
      vertical-align: middle;
      width:34px;
      height:34px;
      background:url('./img/icon_close.svg') no-repeat center;
      content:'';
    }
    &.is-complete {
      background:#1cc4cc;
      &:before {
        background:url('./img/icon_check.svg') no-repeat center;
      }
    }
    span {
      display: inline-block;
      vertical-align: middle;
      //font-size:20px;
      font-size:18px;
    }
  }
  .img {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    //width:320px;
    //height:203px;
    width:240px;
    height:135px;
    margin-right:30px;
    border-radius: 16px;
    overflow: hidden;
    img {
      //width:110%;
      //max-width:110%;
      @include absolute-full(img);
    }
  }
  .textwrap {
    display: inline-block;
    vertical-align: top;
    //width: calc(100% - 762px);
    width: calc(100% - 646px);
    > strong {
      //font-size:24px;
      font-size:20px;
      color:#536476;
      @include font;
    }
    .title {
      margin-top:5px;
      //font-size:32px;
      font-size:28px;
      color:#333a53;
      @include font;
      font-weight: 600;
    }
    .tag {
      //margin-top:18px;
      margin-top:16px;
      color:#7f92a3;
      strong {
        //font-size:20px;
        font-size:18px;
      }
      p{
        max-width:530px;
        //font-size:20px;
        font-size:18px;
      }
    }
  }
  .btnwrap {
    // position: absolute;
    // right:40px;
    // bottom:40px;
    display: inline-block;
    vertical-align:bottom;

      button {
    &.is-chk {
        background:white;
        color:#333a53;
        border:1px solid #abb9c5;
        &:after {
          background: url('./img/right-arrow-navy.svg') no-repeat center;
        }
        &:hover {
          background:#333a53;
          color:#55a3ff;
          border:1px solid #333a53;
        }
        &:active {
          background:#55a3ff;
          color:white;
          border-color:#55a3ff;
          &:after {
            background: url('./img/right-arrow.svg') no-repeat center;
          }
        }
      }
    }
    button {
      position: relative;
      //border-radius: 4px;
      border-radius: 8px;
      height:50px;
      //font-size:16px;
      font-size:18px;
      margin-left:8px;
      padding:0px 0px;
      text-align: left;
      min-width:10px;
    }
  }
}
