#activityPreviewAnimation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background: url(./img/bg-activity.svg) no-repeat center;
  background-attachment: fixed;
  background-size: auto 100%;
  overflow: hidden;
  background-color: #000;

  .img-title {
    width: 800px;
    height: 164px;
    background: url(./img/title-activity.svg) no-repeat center;
    background-size: 100%;
    position: absolute;
    top: 80px;
  }
  .charactor-anim-wrap {
    position: absolute;
    bottom: -6px;
    // height: 100%;
    width: 100%;
    // width: 1920px;
    // height: 1080px;
    .charactor-anim {
      z-index: 1;
      position: relative;
    }
    .speech-anim {
      width: 430px;
      position: absolute;
      bottom: 470px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
    }
  }
}
