@import '../../../style/helpers/index';

.gameexplain-layout {
  .img-wrap {
    display: inline-block;
    position: relative;
    &:after {
      position: absolute;
      @include vw-convert(right, -60px);
      @include vw-convert(bottom, -5px);
      @include vw-convert(width, 101px);
      @include vw-convert(height, 391px);
      background: url('./shadow.png') no-repeat center;
      @include vw-convert(background-size, 101px 391px);
      z-index: 0;
      content: '';
    }
  }
  .img {
    position: relative;
    display: inline-block;
    @include vw-convert(width, 536px);
    @include vw-convert(height, 404.5px);
    @include vw-convert(border-radius, 8px);
    border: 4px solid #e5e5e5;
    overflow: hidden;

    img {
      position: absolute;
      right:-100%;
      top:-100%;
      left:-100%;
      bottom:-100%;
      margin:auto;
      z-index: 1;
    }
  }

  .page-content {
    @include vw-convert(margin-top, 80px);
    // margin-top:vw-convert-pcfull(100px)!important;
    background:none!important;
  }

  .content-text-wrap {
    display: inline-block;
    @include vw-convert(max-width, 736px);
    vertical-align: top;
    @include vw-convert(margin-left, 130px);
  }

  .page-content-title {
    @include vw-convert(max-width, 736px);
    background: rgba($deep-yellow, 0.1);
    color: $deep-yellow;
    text-align: left;

    &:after {
      position: absolute;
      @include vw-convert(right, 40px);
      bottom: 0;
      @include vw-convert(width, 199px);
      @include vw-convert(height, 135px);
      background: url('./img/챌리.png') no-repeat center;
      @include vw-convert(background-size, 199px 135px);
      content: '';
    }
  }

  .page-content-ment {
    text-align: left;
  }
}

.gameranking-layout {
  .big-title {
    @include vw-convert(font-size, 64px);
  }

  .gameranking-wrap {
    @include vw-convert(margin-top, 60px);
    ul {
      @include vw-convert(min-width, 600px);
    }
  }
  .rankinglist {
    background:white;
  }
}

@keyframes ro {
  0% {
    // transform: rotatex(0deg);
  }

  100% {
    transform: rotatex(-180deg);
  }
}
@keyframes ro1 {
  0% {
    // transform: rotatex(0deg);
    transform: rotatex(-180deg);
  }

  100% {
    transform: rotatex(0deg);
  }
}