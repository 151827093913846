#quebonSongAnimation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background: url(./img/quebon-song-bg.svg) no-repeat center;
  background-attachment: fixed;
  background-size: auto 100%;
  overflow: hidden;
  background-color: #000;

  .img-title {
    width: 400px;
    height: 164px;
    background: url(./img/quebon-song-title.svg) no-repeat center;
    background-size: 100%;
    position: absolute;
    top: 100px;
  }
  .song-title {
    font-size: 56px;
    line-height: 94px;
    color: #fff;
    width: 530px;
    height: 110px;
    text-align: center;
    // font-weight: 700;
    font-family: "Jua";
    position: absolute;
    top: 310px;
    left: 50%;
    transform: translateX(-50%);
    background: url(./img/quebon-song-text-bg.svg) no-repeat center;
    background-size: 100%;
  }

  .obj-anim {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 170px;
    width: 1650px;
    height: 500px;
  }
  .character-anim-wrap {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 130px;
    height: 510px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .character-anim {
    width: 340px;
    height: 390px;
    // position: absolute;
    // bottom: 120px;
    // z-index: 3;
    position: relative;
    &.queda {
      width: 560px;
      height: 450px;
      margin-right: -100px;
      > div {
        width: 100%;
        height: 100%;
      }
      .speech-anim {
        width: 400px;
        height: 280px;
        position: absolute;
        z-index: 3;
        left: 50%;
        top: -240px;
        transform: translateX(-50%);
      }
    }
    &.ddami {
      width: 720px;
      height: 510px;
      // left: 200px;
    }
    &.chaeli {
      width: 560px;
      height: 450px;
      margin-left: -100px;
      // right: 400px;
    }
  }
}
