@import '../../../style/helpers/_index';

$path : './img/';

@mixin img-local ($path, $file-name, $extension: 'png') {
  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      .img {
        background:url('#{$path}#{$file-name}#{$i}.#{$extension}');
        @content;
      }
    }
  }
}

.t-sidemenu {
  position: absolute;
  display: table;
  height: 100%;
  top: 0;
  bottom: 0;
  @include vw-convert(left, 48px);
  z-index: 2;
  margin: auto;

  ul {
    display: table-cell;
    vertical-align: middle;
    margin: 0;
  }

  li {
    position: relative;
    @include vw-convert(width, 80px);
    @include vw-convert(height, 80px);
    background: $navy;
    @include vw-convert(border-radius, 16px);
    @include vw-convert(margin-bottom, 16px);
    @include vertical-center;
    color: $blue;
    cursor: pointer;

    &.is-active {
      background: #55A3FF;

      &:hover {
        color: white;
      }
    }

    &.standby {
      &.is-active {
        .img {
          background: url('./img/t-menu1.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }

        &:hover {
          .img {
            background: url('./img/t-menu1.svg') no-repeat center;
            @include vw-convert(background-size, 64px 64px);
          }
        }
      }

      .img {
        background: url('./img/t-menu1.svg') no-repeat center;
        @include vw-convert(background-size, 64px 64px);
      }

      &:hover {
        .img {
          background: url('./img/t-menu-hover1.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }
      }
    }

    &.studentlist {
      &.is-active {
        .img {
          background: url('./img/t-menu2.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }

        &:hover {
          .img {
            background: url('./img/t-menu2.svg') no-repeat center;
            @include vw-convert(background-size, 64px 64px);
          }
        }
      }

      .img {
        background: url('./img/t-menu2.svg') no-repeat center;
        @include vw-convert(background-size, 64px 64px);
      }

      &:hover {
        .img {
          background: url('./img/t-menu-hover2.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }
      }
    }

    &.guide {
      position: relative;

      &.is-active {
        &:hover {
          .img {
            background: url('./img/t-menu3.svg') no-repeat center;
            @include vw-convert(background-size, 64px 64px);
          }
        }

        .img {
          background: url('./img/t-menu3.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }

        .popup-guide {
          display: block;
        }
      }

      .img {
        background: url('./img/t-menu3.svg') no-repeat center;
        @include vw-convert(background-size, 64px 64px);
      }

      &:hover {
        .img {
          background: url('./img/t-menu-hover3.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }
      }
    }

    &.make-team {
      &.is-active {
        .img {
          background: url('./img/make-team.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }

        &:hover {
          .img {
            background: url('./img/make-team.svg') no-repeat center;
            @include vw-convert(background-size, 64px 64px);
          }
        }
      }

      .img {
        background: url('./img/make-team.svg') no-repeat center;
        @include vw-convert(background-size, 64px 64px);
      }

      &:hover {
        .img {
          background: url('./img/make-team-hover.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }
      }
    }

    &.team-info {
      &.is-active {
        .img {
          background: url('./img/team-info.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }

        &:hover {
          .img {
            background: url('./img/team-info.svg') no-repeat center;
            @include vw-convert(background-size, 64px 64px);
          }
        }
      }

      .img {
        background: url('./img/team-info.svg') no-repeat center;
        @include vw-convert(background-size, 64px 64px);
      }

      &:hover {
        .img {
          background: url('./img/team-info-hover.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }
      }
    }

    &.quture-view {
      &.is-active {
        .img {
          background: url('./img/queture-view.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }

        &:hover {
          .img {
            background: url('./img/queture-view.svg') no-repeat center;
            @include vw-convert(background-size, 64px 64px);
          }
        }
      }

      .img {
        background: url('./img/queture-view.svg') no-repeat center;
        @include vw-convert(background-size, 64px 64px);
      }

      &:hover {
        .img {
          background: url('./img/queture-view-hover.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }
      }
    }

    &.remote-queture {
      &.is-active {
        .img {
          background: url('./img/remote-queture.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }

        &:hover {
          .img {
            background: url('./img/remote-queture.svg') no-repeat center;
            @include vw-convert(background-size, 64px 64px);
          }
        }
      }

      .img {
        background: url('./img/remote-queture.svg') no-repeat center;
        @include vw-convert(background-size, 64px 64px);
      }

      &:hover {
        .img {
          background: url('./img/remote-queture-hover.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }
      }
    }

    &.game-start {
      &.is-active {
        .img {
          background: url('./img/game-start.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }

        &:hover {
          .img {
            background: url('./img/game-start.svg') no-repeat center;
            @include vw-convert(background-size, 64px 64px);
          }
        }
      }

      .img {
        background: url('./img/game-start.svg') no-repeat center;
        @include vw-convert(background-size, 64px 64px);
      }

      &:hover {
        .img {
          background: url('./img/game-start-hover.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }
      }
    }

    &.ps-start {
      &.is-active {
        .img {
          background: url('./img/ps-start.svg') no-repeat center;
          @include vw-convert(background-size, 50px 50px);
        }

        &:hover {
          .img {
            background: url('./img/ps-start.svg') no-repeat center;
            @include vw-convert(background-size, 50px 50px);
          }
        }
      }

      .img {
        background: url('./img/ps-start.svg') no-repeat center;
        @include vw-convert(background-size, 50px 50px);
      }

      &:hover {
        .img {
          background: url('./img/ps-start-hover.svg') no-repeat center;
          @include vw-convert(background-size, 50px 50px);
        }
      }
    }

    &.previous-review {
      &.is-active {
        .img {
          background: url('./img/previous-review.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }

        &:hover {
          .img {
            background: url('./img/previous-review.svg') no-repeat center;
            @include vw-convert(background-size, 64px 64px);
          }
        }
      }

      .img {
        background: url('./img/previous-review.svg') no-repeat center;
        @include vw-convert(background-size, 64px 64px);
      }

      &:hover {
        .img {
          background: url('./img/previous-review-hover.svg') no-repeat center;
          @include vw-convert(background-size, 64px 64px);
        }
      }
    }

    .img {
      position: absolute;
      top: 0;
      bottom: 0;
      @include vw-convert(left, 8px);
      margin: auto;
      @include vw-convert(width, 64px);
      @include vw-convert(height, 64px);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      @include vw-convert(width, 240px);

      .img {
        right: auto;
        @include vw-convert(left, 8px);
      }

      span {
        display: inline-block;
        vertical-align: middle;

        font-family: 'HGSoftGGothicssi';
        @include vw-convert(font-size, 24px);
        @include vw-convert(margin-left, 88px);
      }
    }

    span {
      display: none;

    }

    // @include img-local($path, 't-menu', 'svg')
  }
}

.viewtoggle-btn {
  position: absolute;
  @include vw-convert(bottom, 96px);
  @include vw-convert(right, 48px);
  background: $navy;
  @include vw-convert(width, 80px);
  @include vw-convert(height, 80px);
  @include vw-convert(border-radius, 16px);
  cursor: pointer;
  z-index: 150;

  &:before {
    @include absolute-full;
    @include vw-convert(width, 64px);
    @include vw-convert(height, 64px);
    background: url('./img/viewtoggle-btn.svg') no-repeat center;
    @include vw-convert(background-size, 64px 64px);
    content: '';
  }

  &:hover {
    &:before {
      background: url('./img/viewtoggle-btn-hover.svg') no-repeat center;
      @include vw-convert(background-size, 64px 64px);
    }
  }

  &.is-on {
    &:hover {
      &:before {
        background: url('./img/viewtoggle-btn-on-hover.svg') no-repeat center;
        @include vw-convert(background-size, 64px 64px);
      }
    }

    &:before {
      background: url('./img/viewtoggle-btn-on.svg') no-repeat center;
      @include vw-convert(background-size, 64px 64px);
    }
  }
}

.bottom-toolbar {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  @include vw-convert(border-radius, 16px 16px 0px 0px);
  background: $navy;
  @include clearfix;
  z-index: 150;

  .attend-chk {
    position: relative;
    z-index: 1;
    float: left;

    &__list {
      display: inline-block;
      vertical-align: middle;
      @include vw-convert(height, 54px);
      @include vw-convert(width, 473px);
      overflow: hidden;
      background: white;
      @include vw-convert(border-radius, 8px);
      @include vw-convert(margin, 8px);

      .lecture-route {
        text-align: left;
        @include vw-convert(padding, 11px 0px 12px 42px);

        >div {
          @mixin tauto-count($number, $start-number:1) {
            li {
              &:before {
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                @include vw-convert(width, 24px);
                @include vw-convert(height, 24px);
              }
            }

            @for $i from $start-number through $number {
              &:nth-child(#{$i}) {
                li {
                  &:before {
                    content:'#{$i}'
                  }
                }
              }
            }
          }

          @include tauto-count(30);
        }

        li {
          position: relative;
          // @include auto-count(10);
          @include vw-convert(margin-top, 38px);


          .queture-name {
            &:after {
              display: inline-block;
              width: 1px;
              @include vw-convert(height, 16px);
              @include vw-convert(margin, 0px 10px);
              background: rgba($navy, 0.5);
              vertical-align: baseline;
              content: '';
            }
          }

          .point {
            display: inline-block;

            &:before,
            &:after {
              display: inline-block;
              vertical-align: middle;
              @include vw-convert(width, 4px);
              @include vw-convert(height, 8px);
              @include vw-convert(margin, 0px 8px 4px 8px);
              // background: url('#{$path}/lecture-route.svg') no-repeat center;
              @include vw-convert(background-size, 4px 8px);
              content: '';
            }
          }

          &.is-gone-view {
            &:before {
              background: $navy;
            }

            &:after {
              background: $navy;
            }
          }

          &.is-view {
            &:before {
              background: $blue;
            }

            .queture-name {
              font-weight: 600;
            }

            .point {
              color: $blue;
              font-weight: 600;

              &:before {
                // background: url('#{$path}/lecture-route-select.svg') no-repeat center;
              }
            }
          }

          &:before {
            @include vw-convert(width, 24px);
            @include vw-convert(height, 24px);
            background: #999;
            border-radius: 50%;
            @include vw-convert(line-height, 22px);
            color: white;
            @include vw-convert(font-size, 20px);
            font-weight: 500;
            outline: 1px solid transparent;
          }

          &:after {
            position: absolute;
            top: 100%;
            @include vw-convert(left, 10px);
            @include vw-convert(width, 3px);
            @include vw-convert(height, 35px);
            background: #999;
            content: '';
          }

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            &:after {
              content: none;
            }
          }

          p {
            display: inline-block;
            vertical-align: middle;
            @include vw-convert(margin-left, 15px);
            @include vw-convert(font-size, 20px);
            color: $navy;
            // span {
            //   display: inline-block;
            //   vertical-align: middle;
            // }
          }
        }
      }
    }

    &__btn {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      @include vw-convert(width, 54px);
      @include vw-convert(height, 54px);
      background: #474e64;
      @include vw-convert(border-radius, 8px);
      cursor: pointer;

      &:after {
        @include absolute-full;
        @include vw-convert(width, 40px);
        @include vw-convert(height, 40px);
        background: url('./img/up-arrow.svg') no-repeat center;
        @include vw-convert(background-size, 40px 40px);
        content: '';
      }

      &.is-on {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  .numbered-list {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    color: white;
    z-index: 0;
    @include vertical-center;
    @include vw-convert(font-size, 24px);

    span {
      display: inline-block;
      vertical-align: middle;
    }

    .left,
    .right {
      @include vw-convert(width, 40px);
      @include vw-convert(height, 40px);
      cursor: pointer;
    }

    .left {
      background: url('./img/left-arrow.svg') no-repeat center;
      @include vw-convert(background-size, 40px 40px);
    }

    .right {
      background: url('./img/right-arrow.svg') no-repeat center;
      @include vw-convert(background-size, 40px 40px);
    }
  }

  .type {
    position: relative;
    color: white;
    float: right;
    z-index: 1;
    @include vw-convert(font-size, 20px);

    li {
      display: inline-block;
      vertical-align: middle;
      @include vw-convert(line-height, 68px);
      @include vw-convert(margin-right, 80px);
      cursor: pointer;

      &:last-child {
        @include vw-convert(margin-right, 48px);
      }
    }
  }
}

.classmode-btn {
  background: $navy;
  color: white;
  min-width: 124px;
  height: 42px;
  font-size: 24px;
  border-radius: 50px;

  &:hover {
    color: $blue;
  }

  &:active {
    color: white;
    background: $blue;
  }

  &:disabled {
    color: $gray70;
    background: $gray30;
    cursor: default;
  }
}

.classmode-btn2 {
  font-weight: 500;
  margin-top: 13px;
  font-size: 20px;
  padding: 12px 20px;
  background: rgba(#55a3ff, 0.2);
  color: #536476;
  border-radius: 10vw;
}
