@import "../../../style/helpers/index";

// (구) notepad
.c-notepad {
  @include vw-convert-mo {
    display: none;
  }

  &.is-open {
    .c-notepad-canvas {
      display: block;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 100;
      left: 0;
      top: 0;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.5);
    }

    .c-notepad-new {
      strong {
        background: rgba(#fff, 0.2);
      }

      ul {
        display: block;
      }

      z-index: 110;
    }

    .c-notepad {
      &__wrap {
        opacity: 1;
        width: auto;
      }

      &__btn {
        transform: rotate(180deg);
      }

      &__list {
        display: inline-block;

        &-pen {
          &.is-select {
            .c-notepad__color {
              display: block;
              top: -27px;
              left: 45px;
            }
          }
        }
      }
    }

    .c-notepad-canvas {
      width: 100%;
      height: 100%;
      float: left;
      display: block;

      canvas {
        width: 100%;
        height: 100%;
        background: transparent;
      }
    }

    .c-notepad-close {
      // display: block;
    }
  }

  &__wrap {
    position: fixed;
    top: 49px;
    right: 0;
    width: 51px;
    height: 48px;
    background: #7273ff;
    opacity: 0.65;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    z-index: 100;

    &:before {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      content: '';
    }
  }

  &__btn {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin-left: 10px;
    border-radius: 50%;
    background: url('./img/allow_icon.svg') no-repeat center;
    background-size: contain;
    cursor: pointer;
  }

  &__list,
  &__color {
    display: none;
  }

  &__list {
    margin-left: 16px;

    li {
      display: inline-block;
      vertical-align: middle;
      width: 32px;
      height: 32px;
      margin-right: 8px;
      cursor: pointer;

      &:last-child {
        margin-right: 28px;
      }
    }

    &-calc {
      background: url('./img/calculator_icon.svg') no-repeat center;
      background-size: contain;
    }

    &-pen {
      background: url('./img/pen_icon.svg') no-repeat center;
      background-size: contain;

      &.is-black {
        background: url('./img/pen_icon-semi-black.svg') no-repeat center;
        background-size: contain;
      }

      &.is-white {
        background: url('./img/pen_icon-white.svg') no-repeat center;
        background-size: contain;
      }

      &.is-alert {
        background: url('./img/pen_icon-alert.svg') no-repeat center;
        background-size: contain;
      }
    }

    &-page {
      background: url('./img/delete_icon.svg') no-repeat center;
      background-size: contain;
    }

    &-erase {
      background: url('./img/eraser_icon.svg') no-repeat center;
      background-size: contain;

      &.is-on {
        background: url('./img/eraserOn_icon.svg') no-repeat center;
        background-size: contain;
      }
    }
  }

  &__color {
    position: absolute;
    top: 0;

    span {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 4px;
      border-radius: 50%;
    }

    .semi-black {
      background: #7f7f7f;
    }

    .white {
      background: white;
    }

    .false {
      background: $false;
    }
  }
}

.c-notepad-canvas {
  display: none;

  &.is-erase {
    &:hover {
      cursor: url('./img/eraserPoint_icon@2x.ico') -300 50, auto;
    }

    ~.c-notepad-new {
      .do-render {
        color: #333A53;

        &:before {
          background: url('./img/pencil-black.svg') no-repeat center;
          background-size: contain;
        }
      }

      .do-erase {
        color: $purple;

        &.is-on {
          &:before {
            background: url('./img/eraser-purple.svg') no-repeat center;
            background-size: contain;
          }
        }
      }
    }
  }

  &.is-redpen {
    &:hover {
      cursor: url('./img/penRPoint_icon@2x.ico') -300 50, auto;
    }

    ~.c-notepad-new {
      .red-color {
        svg {
          display: block;
        }
      }
    }
  }

  &.is-blackpen {
    &:hover {
      cursor: url('./img/penBPoint_icon@2x.ico') -300 50, auto;
    }

    ~.c-notepad-new {
      .black-color {
        svg {
          display: block;
        }
      }
    }
  }

  &.is-whitepen {
    &:hover {
      cursor: url('./img/penWPoint_icon@2x.ico') -300 50, auto;
    }

    ~.c-notepad-new {
      .white-color {
        svg {
          display: block;
        }
      }
    }
  }
}

// (신) notepad
.c-notepad-new {
  float: right;
  position: relative;
  margin-right: 12px;
  text-align: left;
  line-height: 40px;
  z-index: 3;

  &:not(.challenge) {
    @include vw-convert-mo {
      display: none;
    }
  }

  &.challenge {
    @include vw-convert-mo {
      position: absolute;
      float: unset;
      right: 10px;
      top: 10px
    }
  }

  strong {
    display: inline-block;
    height: 40px;
    min-width: 100px;
    padding-left: 14px;
    @include font;
    box-sizing: border-box;
    color: white;
    background: rgba(#fff, 0.1);
    border-radius: 10vw;
    cursor: pointer;
    font-size: 16px;

    &:before {
      @include self-vertical-center;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      background: url('./img/pencil-white.svg') no-repeat center;
      content: '';
    }

    &.no-before {
      text-align: center;
      padding-left: 0;

      &:before {
        display: none;
      }
    }
  }

  ul {
    display: none;
    position: absolute;
    width: 100%;
    margin-top: 8px;
    padding: 8px 0px;
    font-size: 14px;
    background: white;
    border: 2px solid rgba(30, 27, 109, 0.2);
    border-radius: 16px;
    box-sizing: border-box;
    z-index: 3;

    li {
      padding-left: 6px;
      @include font;
      cursor: pointer;

      &.is-select,
      &:hover {
        color: $purple;
      }
    }

    .do-render,
    .do-erase,
    .do-delete {
      &:before {
        @include self-vertical-center;
        width: 30px;
        height: 30px;
        content: '';
      }
    }

    .do-render {
      // &:before {
      //   background:url('./img/pencil-black.svg') no-repeat center;
      // }
      // &.is-select {
      color: $purple;

      &:before {
        background: url('./img/pencil-purple.svg') no-repeat center;
        background-size: contain;
      }

      // }
      &:hover {
        ~.palette-wrap {
          display: list-item;
        }
      }
    }

    .do-erase {
      &:before {
        background: url('./img/eraser-black.svg') no-repeat center;
        background-size: contain;
      }

      &:hover {
        &:before {
          background: url('./img/eraser-purple.svg') no-repeat center;
          background-size: contain;
        }
      }
    }

    .do-delete {
      &:before {
        background: url('./img/delete-black.svg') no-repeat center;
        background-size: contain;
      }

      &:hover {
        &:before {
          background: url('./img/delete-purple.svg') no-repeat center;
          background-size: contain;
        }
      }
    }

    .palette-wrap {
      display: none;
      padding: 4px;
      background: rgba(#d2d1e2, 0.5);
      box-sizing: border-box;

      &:hover {
        display: list-item;
      }
    }

    .palette {
      position: relative;
      display: block;
      height: 29px;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0px;
      }

      &.black-color {
        border: 2px solid rgba(255, 255, 255, 0.5);
        background: black;
      }

      &.white-color {
        border: 2px solid #CCCCCC;
        background: #fff;
      }

      &.red-color {
        border: 2px solid rgba(255, 255, 255, 0.5);
        background: #ff5a5a;
      }

      svg {
        display: none;
        @include absolute-full;
      }
    }
  }
}

.c-notepad-close {
  display: none;
  position: fixed;
  min-width: 153px;
  height: 40px;
  background: rgba(#fff, 0.2);
  right: 60px;
  bottom: 40px;
  text-align: left;
  padding-left: 16px;
  border-radius: 10vw;
  box-sizing: border-box;
  z-index: 110;
  color: white;
  cursor: pointer;

  span {
    display: inline-block;
    vertical-align: top;
    line-height: 41px;
    @include font;
  }

  .close {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 32px;
    height: 32px;
    background: url('./img/close_btn-white.svg');
    background-size: contain;
  }
}
